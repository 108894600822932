import "./styles.scss";
import ClickImg from "../../../assets/images/home/click.gif";
export const NewsMarquee = () => {
  return (
    <div className="marquee-news" style={{backgroundColor:"white"}}>
      <ul>
        <li>
          <strong className="text-warning blinking-text"> University GOLD MEDALIST & Rank Holders</strong>
   &nbsp;|&nbsp; 
  
   <span className="text-success"> J.SHIFANA PARVEEN </span>(8.348)  RANK-I (GOLD MEDALIST) B.Com.,BM &nbsp;|&nbsp;
   <span className="text-success"> H.MUBEENA</span> (8.303) B.Com.,BM RANK-II&nbsp;|&nbsp; 
   <span className="text-success"> A. ARTHI</span> (9.545) B.Sc.,Maths RANK-II&nbsp;|&nbsp;
   <span className="text-success"> M.KIRUBA </span>(8.300) B.Com.,BM RANK-III&nbsp;|&nbsp;
   <span className="text-success"> V.SINDHU</span> (9.403) B.Sc.,Chemistry RANK-III&nbsp;|&nbsp;
   <span className="text-success"> E.VIYAYA </span>(8.077) B.Com.,BM RANK-IV&nbsp;|&nbsp;
   <span className="text-success"> S. HARINI</span> (9.534) B.Sc.,Maths RANK-V&nbsp;|&nbsp;
   <span className="text-success"> M R JEMIMA PARVEEN</span>  (8.076) B.Com.,BM RANK-V&nbsp;|&nbsp;
   <span className="text-success"> A. SHIFANA</span> (8.042) B.Com.,BM RANK-VI&nbsp;|&nbsp;
   <span className="text-success"> A. THAMARAIVALLI</span>  (8.385) B.Com.,FA RANK-VIII&nbsp;|&nbsp;
   <span className="text-success"> E. SABITHA </span>(9.226) B.Sc.,Chemistry RANK-X&nbsp;|&nbsp;
        </li>
      </ul>
    </div>
  );
};
