import React from "react";
import "./style.css";

function Chemistry() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Chemistry
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The Department of Chemistry was established in 2015 and initiating
            the Undergraduate course in Chemistry. The Department of Chemistry
            houses 1 Laboratory, 1 Store stacked with Lab-wares, 3 Classrooms
            and 1 Staff-room. The Department has a plan of starting a new Master
            Degree Course. Formal lectures will be made more effective and
            useful by providing students with synopses of lectures and
            bibliography. Besides lectures, there will be assignments, guided
            library work, seminars, etc. Audio-visual aids, models, laboratory
            work, workshop and field experience will also form part of the
            teaching process. To make teaching more effective and intensive,
            tutorials will be arranged. These tutorials will also serve as a
            feed-back for the teachers, enable him to evaluate the student’s
            analytical ability and progress and promote staff student
            interaction. The chemistry Department was established in the year
            2015 to provide education, to impart knowledge and to promote
            research and extension activities. The department has well equipped
            laboratories with well equipped apparatus and instruments. In our
            department basic chemistry foundation was provided through lectures
            and laboratory courses to the students of the B.Sc chemistry. This
            program trains future chemists with hands-on experience on pure
            sciences and skill based knowledge.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                Scope of B.Sc Chemistry
              </h5>
            </div>
            <ul>
              {" "}
              <li>
                The employment areas for the B. Sc Chemistry graduates include
                pharmaceutical industries, chemical manufacturers, Forensic
                Science Department, plastic industries, agrochemical industries,
                etc. Apart from these, they are also recruited in other fields
                such as oil, gas and power sectors and even in defense services.
              </li>
              <li>
                Chemistry is the study of matter and the changes it undergoes
                and considers both macroscopic and microscopic information.
                Matter is anything that has mass and occupies space. The five
                main disciplines of chemistry are physical chemistry, organic
                chemistry, Inorganic chemistry, analytical chemistry and
                biochemistry.
              </li>
              <li>
                Chemistry is very important because it helps us to know the
                composition, structure& changes of matter. All the matters are
                made up of chemistry. In our every day like various chemical are
                being used in various from, some of those are being used as
                food, some of those used clanging etc.
              </li>
              <li>
                B. Sc Chemistry is the under graduate degree awarded by many
                universities in India for the students after the successful
                completion of their graduation in chemistry. The course in
                chemistry is useful for the students in various aspects and
                offers them with bright career. The course helps the students in
                improving their diverse skills in various areas such as
                laboratory skills, numerical and computing skills, ability to
                approach to the problems both analytically and logically, time
                management skills, etc. The B. Sc chemistry graduates have many
                options for their higher studies. Majority of these graduates
                opt for master’s degree in the same. But they can also choose
                various specialized areas in this field for the post graduation
                courses. Many universities offer the study of chemistry at post
                graduate and at doctoral levels.
              </li>
              <li>
                Some of the higher study options after B. Sc Chemistry are:-M.
                Sc. Chemistry, M. Sc. Analytical Chemistry, M. Sc. Drug
                Chemistry, M. Sc. Organic Pharmaceutical Chemistry, M. Sc.
                Physical & Materials Chemistry and One of the major
                certification courses for the B. Sc Chemistry graduates is
                Post-Graduate Diploma in Analytical Chemistry (PGDAC).
              </li>
              <li>
                Career Opportunities after B. Sc Chemistry:- The chemicals are
                used in almost every field such as medicines, food products, and
                electronics and even in construction activities. This creates
                many opportunities for the chemistry graduates in diverse
                fields. The industries, specially chemical and pharmaceutical,
                the universities and government laboratories are the three major
                employers who recruit Chemistry graduates. The entry level jobs
                in this field usually require at least the bachelor’s degree in
                chemistry, but majority of the jobs like research needs
                candidates with PhD in the same.The chemical industries are also
                doing well in India and there is huge demand for chemists for
                various positions. Some of the job types for these graduates
                include Analytical Chemist, Biomedical Chemist, Chemical
                Engineering Associate, Industrial Research Scientist, Lab
                Chemist, Materials Technologist, Production Chemist, Production
                Officer, Quality Controller, R&D Director, Research &
                Development Manager, Safety Health And Environment Specialist
                and Teacher.
              </li>
              <li>
                The employment areas for the B. Sc Chemistry graduates include
                pharmaceutical industries, chemical manufacturers, Forensic
                Science Department, plastic industries, agrochemical industries,
                etc. Apart from these, they are also recruited in other fields
                such as oil, gas and power sectors and even in defense services.
                Wide options exist for those who have complete B. Sc Chemistry
                (hons) study. Candidates can opt for higher studies by joining
                PG course. B. Sc is the common choice for most of the graduates.
                M.Tech is a good option too from any of the prominent technical
                institutes. After PG, if interested join for PhD course.
                Candidates who want to pursue a career in teaching filed can
                attend UGC NET exam and join as lectures in colleges or
                universities. Candidates can attempt government competitive
                examinations namely UPSC, SSC to get jobs in these departments.
                Candidates can apply for technical jobs in forensic departments,
                research firms, Lab Chemist, Quality Control, health And
                Environment, agrochemical industries etc.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            Our Department aspires to provide the best scientific methods in
            teaching the basic principles of chemistry, both theoretical and
            experimental and puts most of time to keep the education level,
            scientific research and community service.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            Impart quality education and promote scientific temper. Promoting
            academic excellence with discipline. Blend theoretical knowledge
            with practical skills. To provide a comprehensive undergraduate
            chemistry degree programme. To encourage inter-disciplinary thinking
            by providing students with educational and research opportunities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Chemistry;
