import React from 'react';
import './footer.scss';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="container-fluid footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <Container>
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="mb-5 text-light">Contact Us</h1>
                </div>
                <Row className="g-5">
                    <Col lg={3} md={6} className="mb-4 mb-md-0">
                        <h4 className="text-white mb-3">Quick Links</h4>
                        <a  variant="link" href="/courses" style={{ textDecoration: 'none', color:'white' }}>Department </a>
                        <br/>
                        <a  href="/gallery/2024" style={{ textDecoration: 'none' , color:'white' }}>Gallery</a>
                        <br/>
                        <a  variant="link" href="https://docs.google.com/forms/d/e/1FAIpQLSdd8pOpDYoSrlfvTbL-y8x7swoVqKoIZ4xTiz-hTlOmjxvTZA/viewform" style={{ textDecoration: 'none', color:'white' }}> Admissions</a>
                    </Col>
                    <Col lg={3} md={6} className="mb-4 mb-md-0">
                        <h4 className="text-white mb-3">Reach Us</h4>
                        <p className="mb-2 text-light">Jawaharlal Nehru College for Women (Arts & Science), Pali, Ulundurpet</p>
                        <p className="mb-2 text-light">Kallakurichi - 606 104</p>
                    </Col>
                    <Col lg={3} md={6} className="mb-4 mb-md-0">
                        <h4 className="text-white mb-3">Connect with Us</h4>
                        <div className="d-flex flex-wrap">
                            <a href='https://www.facebook.com/jncwomen/' variant="outline-warning" className="btn-social me-2 mb-2"><FontAwesomeIcon icon={faFacebook} style={{ color: 'white' }} /></a>
                            <a href='https://www.youtube.com/@jawaharlalnehrucollegeforwomen'  variant="outline-warning" className="btn-social me-2 mb-2"><FontAwesomeIcon icon={faYoutube} style={{ color: 'white' }} /></a>
                            <a href='https://www.instagram.com/jncw.in_/' variant='outline-warning' className='btn-social me-2 mb-2'> <FontAwesomeIcon icon={faInstagram} style={{ color: 'white' }} /></a>
                        </div>
                        <Dropdown className="mt-2">
                            <Dropdown.Toggle variant="outline-warning" id="dropdown-basic">
                                Our Groups
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="https://jsasiddha.in" target="_blank" rel="noopener noreferrer">JSA MEDICAL COLLEGE FOR SIDDHA AND RESEARCH CENTRE</Dropdown.Item>
                                <Dropdown.Item href="https://jsaagri.in" target="_blank" rel="noopener noreferrer">JSA College of Agriculture and Technology</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col lg={3} md={6}>
                        <h4 className="text-white mb-3">Contact Details</h4>
                        <p className="mb-2 text-light">Email: <a href="mailto:jcwomen@gmail.com" className="text-light" style={{ textDecoration: 'none' }}>jcwomen@gmail.com</a></p>
                        <p className="mb-2 text-light">Telephone:<br />
                            <a href="tel:+919361909060" className="text-light" style={{ textDecoration: 'none' }}>+91 93619 09060</a>,<br />
                            <a href="tel:+919361909070" className="text-light" style={{ textDecoration: 'none' }}>+91 93619 09070</a>,<br />
                            <a href="tel:+917305057756" className="text-light" style={{ textDecoration: 'none' }}>+91 73050 57756</a>,<br />
                            <a href="tel:+919786646795" className="text-light" style={{ textDecoration: 'none' }}>+91 97866 46795</a>,<br />
                            <a href="tel:+919566223456" className="text-light" style={{ textDecoration: 'none' }}>+91 95662 23456</a>
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={6} className="text-center text-md-start mb-3 mb-md-0 text-light">
                        &copy; All Rights Reserved 2024., Designed By Shri Venkateswara Trust
                    </Col>
                    {/* <Col md={6} className="text-center text-md-end">
                        <div className="footer-menu">
                            <a href="#">Home</a>
                            <a href="#">Cookies</a>
                            <a href="#">Help</a>
                            <a href="#">FAQs</a>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
