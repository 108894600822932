import React from 'react'
import transport_img1 from "../../../assets/images/Infrastrutre/Tranport/trans-1.jpg";
import transport_img2 from "../../../assets/images/Infrastrutre/Tranport/trans-2.jpg";
import transport_img3 from "../../../assets/images/Infrastrutre/Tranport/trans-3.jpg";
import { Helmet } from "react-helmet-async";


function transport() {
  return (
    <>
     <Helmet>
        <title>Amenities- JNCW</title>
      </Helmet>
    <div
        className="container mt-4 text-justify"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
                <h4 class="mb-5"> Our College facilities</h4>
            </div>
             <div className="container">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
   
            <div class="card mt-2">
              <div
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-parent="#accordion"
                href="#collapse1st"
                aria-expanded="false"
                aria-controls="collapse1st"
              >
                <div
                  class="card-header header-green "
                  role="tab"
                  id="headingOne"
                >
                  <h4 className='title'>
           Transport 
                  </h4>
                </div>
              </div>
              <div
                id="collapse1st"
                class="card-collapse collapse in show"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <p>
                      Jawaharlal Nehru College for Women own the best fleet of comfortable buses to different routes that provides direct connectivity from Ulunderpet. The buses are of latest models well equipped with all the facilities to offer a comfortable travelling. In order to ensure a happy travelling, ratio of students and seats are kept intact without overloading the buses. The engines and other machinery are regularly serviced and follows all the rules and standards laid by the traffic department. Hundreds of students, faculty and staff members enjoy calm and happy travelling from numerous cities and villages on daily basis. The impressive transportation service is offered at a nominal cost. Thus, the transportation facilities have served as a crucial part of the service backbone of the college.
                      </p>

                      <p>
                      Effective transport facility covering every nook and corner of Puducherry,Kanagachettikulam,Cuddalore,Villupuram,Neyveli, Tindivanam. Because of the best Transport service – More than 80% of our students are availing this facility.
                      </p>
                    </div>
                  
                 <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={transport_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> <img
                        src={transport_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  <img
                        src={transport_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 {/* <div className="col">  <img
                        src={English_lab_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div> */}
                      </div>  
                  </div>
                </div>
              </div>
            </div>
              </div>
        </div>
      </div>
      </>
  )
}

export default transport