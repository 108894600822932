import React from 'react';
import "./sports.css";
import Campus1 from "../../../assets/images/Sports/Img (1).jpg";
import Campus2 from "../../../assets/images/Sports/Img (2).jpg";
import Campus3 from "../../../assets/images/Sports/Img (3).jpg";
import Campus4 from "../../../assets/images/Sports/Img (4).jpg";
import Campus5 from "../../../assets/images/Sports/Img (5).jpg";
import Campus6 from "../../../assets/images/Sports/Img (6).jpg";
import Campus7 from "../../../assets/images/Sports/Img (7).jpg";
import Campus8 from "../../../assets/images/Sports/Img (8).jpg";
import Campus9 from "../../../assets/images/Sports/Img (9).jpg";
import Campus10 from "../../../assets/images/Sports/Img (10).jpg";
import Campus12 from "../../../assets/images/Sports/Img (12).jpg";
import Campus11 from "../../../assets/images/Sports/Img (13).jpg";
import Campus13 from "../../../assets/images/Sports/Img (14).jpg";
import Campus14 from "../../../assets/images/Sports/Img (15).jpg";
import Campus15 from "../../../assets/images/Sports/Img (16).jpg";
import Campus16 from "../../../assets/images/Sports/Img (17).jpg";
import Campus17 from "../../../assets/images/Sports/Img (18).jpg";
import Campus18 from "../../../assets/images/Sports/Img (19).jpg";
import Campus19 from "../../../assets/images/Sports/Img (20).jpg";
import Campus20 from "../../../assets/images/Sports/Img (11).jpg";
import Campus21 from "../../../assets/images/Sports/Img (21).jpg";
import Campus22 from "../../../assets/images/Sports/Img (22).jpg";
import Campus23 from "../../../assets/images/Sports/Img (23).jpg";
import Campus24 from "../../../assets/images/Sports/Img (24).jpg";
import Campus25 from "../../../assets/images/Sports/Img (25).jpg";
import Campus26 from "../../../assets/images/Sports/Img (26).jpg";
import Campus27 from "../../../assets/images/Sports/Img (27).jpg";
import Campus28 from "../../../assets/images/Sports/Img (28).jpg";
import Campus29 from "../../../assets/images/Sports/Img (29).jpg";
import Campus30 from "../../../assets/images/Sports/Img (30).jpg";
import Campus31 from "../../../assets/images/Sports/Img (31).jpg";
import Campus32 from "../../../assets/images/Sports/Img (32).jpg";


function sports() {
  return (
  <>
    <div className='container'>
    <div class="gallery__page">
	<div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Gallery</h4>
                <h4 class="mb-5">Sports & Event </h4>
            </div>
		<div class="gallery__warp">
        			<div class="row">
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus1} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus2} alt="" style={{width:"100%",height:"100%"}}  />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus3} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus4} alt=""  style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus5} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus6} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus7} alt=""  style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus8} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus9} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus10} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus11} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus12} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
                <div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus13} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
                <div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus14} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
                <div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus16} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
                <div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus15} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus17} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus18} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus19} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus20} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus21} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus22} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus23} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus24} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus25} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus26} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus27} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus28} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus29} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus30} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus31} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div><div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus32} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>


			</div>
		</div>
	</div>
</div>
  </>
  )
}

export default sports