import React from "react";

const OnlinePayment = () => {
  return (
    <div class="container mt-4 text-justify lh-lg">
      <div class="row" style={{ padding: "5px" }}>
        <div class="col-md-12">
          <h5 class="heading-text "> Online Payments </h5>
          <p>
            This online payment system is provided by Jawaharlal Nehru College For Women.
            Jawaharlal Nehru College For Women may update
            these terms from time to time and any changes will be effective
            immediately on being set out here. Please ensure you are aware of
            the current terms. The country of domicile for Jawaharlal Nehru College For Women is India.
          </p>
        </div>

        <div class="col-md-12">
          <h5 class="heading-text">Terms & Conditions </h5>
          <p>
            Please read these terms carefully before using the online payment
            facility. Using the online payment facility on this website
            indicates that you accept these terms. If you do not accept these
            terms do not use this facility.{" "}
          </p>
        </div>

        <div class="col-md-12 ">
          <h6 class="heading" style={{ color: "#c8102e" }}>
            All payments are subject to the following conditions:-
          </h6>
          <p>
            The description of services of match making are specific to your
            need, when you log in with your unique password. Normally payment is
            required in advance (i.e. before you commence your activity).{" "}
          </p>
          <p>
            All Fees quoted are in Indian Rupees. The Jawaharlal Nehru College For Women reserves the right to change the fees at any time.
          </p>
          <div>
            <ul>
              <li>
                {" "}
                Your payment will normally reach the Jawaharlal Nehru College For Women 
                account to which you are making a payment within
                two working days.{" "}
              </li>
              <li>
                {" "}
                We cannot accept liability for a payment not reaching the
                correct Jawaharlal Nehru College For Women account due to
                you quoting an incorrect account number or incorrect personal
                details. Neither can we accept liability if payment is refused
                or declined by the credit/debit card supplier for any reason.{" "}
              </li>
              <li>
                {" "}
                If the card supplier declines payment the Jawaharlal Nehru College For Women
                 is under no obligation to bring this
                fact to your attention. You should check with your
                bank/credit/debit card supplier that payment has been deducted
                from your account.{" "}
              </li>
              <li>
                {" "}
                In no event will the Jawaharlal Nehru College For Women
                be liable for any damages whatsoever arising out of the use,
                inability to use, or the results of use of this site, any
                websites linked to this site, or the materials or information
                contained at any or all such sites, whether based on warranty,
                contract, tort or any other legal theory and whether or not
                advised of the possibility of such damages.{" "}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-12">
          <h5 class="heading-text">Refund Policy </h5>
          <ul>
            <li>
              {" "}
              If the Customer leaves the Jawaharlal Nehru College For Women before they complete their service period, there shall
              be no entitlement to a refund of paid service fees.{" "}
            </li>
            <li>
              {" "}
              Refunds, if applicable, at the discretion of the Management, will
              only be made to the debit/credit card used for the original
              transaction. For the avoidance of doubt nothing in this Policy
              shall require the Jawaharlal Nehru College For Women to
              refund the Fees (or part thereof) unless such Fees (or part
              thereof) have previously been paid.{" "}
            </li>
          </ul>
        </div>
        <div class="col-md-12">
          <h5 class="heading-text">Privacy Policy </h5>
          <p>
            {" "}
            his Privacy Policy applies to all of the products, services and
            websites offered by Jawaharlal Nehru College For Women .
            Sometimes, we may post product specific privacy notices or Help
            Centre materials to explain our products in more detail.{" "}
          </p>
          <p>
            If you have any questions about this Privacy Policy, please feel
            free to contact us through our website or write to us{" "}
          </p>
          <p>
            Information we collect and how we use it for our Jawaharlal Nehru College For Women.
          </p>
        </div>
        <div class="col-md-12">
          <h5 class="heading-text">Changes to our Privacy Policy </h5>
          <p>
            {" "}
            Jawaharlal Nehru College For Women reserves the entire right
            to modify/amend/remove this privacy statement anytime and without
            any reason. Nothing contained herein creates or is intended to
            create a contract/agreement between Jawaharlal Nehru College For Women and any user visiting the Jawaharlal Nehru College For Women website or providing identifying information of any kind.
          </p>
        </div>

        <div class="col-md-12">
          <h5 class="heading-text">DND Policy </h5>
          <p>
            {" "}
            If you wish to stop any further sms/email alerts/contacts from our
            side, all you need to do is send an email to{" "}
            <strong>svtrust.educ@gmail.com</strong> with your mobile numbers and
            you will be excluded from the alerts list.
          </p>
        </div>
        <div class="col-md-12">
          <h5 class="heading-text">Contact </h5>
          <p>
            Email :{" "}
            <a href="mailto:svtrust.educ@gmail.com"> svtrust.educ@gmail.com</a>
          </p>
          <p>
            <a
              href=" "
              class="btn btn-success"
              role="button"
            >
              Online Fee Payment
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnlinePayment;
