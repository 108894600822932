// NewsList.js
import React from 'react';
import NewsItem from './NewsItem';

const NewsList = ({ news }) => {
  return (
    <div className="news-list">
      {news.map((item, index) => (
        <NewsItem key={index} {...item} />
      ))}
    </div>
  );
};

export default NewsList;
