import React from "react";
import "./about.scss";
import logo from '../../../assets/images/logo/jncwlogo.png';
import  Principal_Img from "../../../assets/images/about/principal/Principal.jpg";
import { Helmet } from "react-helmet-async";

function principal() {
  return (
    
      
//     <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
//     <img src={logo} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
//  </div>
<div>
 <Helmet>
        <title>About Us- JNCW</title>
      </Helmet>
      <div className="container">
        {/* <h5 className="text-uppercase mt-5 bg-heading"> Our Principal</h5> */}
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Our Principal</h4>
                {/* <h4 class="mb-5">JNCW-2024 Moments </h4> */}
            </div>
        <img
          src={ Principal_Img}
          alt="principal of  Jawaharlal Nehru College for Women"
          className=" left-img mb-0 rounded"
          data-aos="fade-in"
          data-aos-duration="2000"
        />
        <div data-aos="fade-left" data-aos-duration="2000">
          <p style={{ color: "#BF40BF", fontSize: "14px", fontWeight: "bold" }}>
            {" "}
            <span style={{ fontSize: "18px", color: "#191970" }}>
            Dr. S. PRABHA M.Sc., M.C.A., M.Phil., B.Ed., Ph.D.,
            </span>
            <br />
             Principal
          </p>
        </div>
        <div data-aos="fade-in" data-aos-duration="3000">
          <p>
        <b>Welcome to Jawaharlal Nehru College for Women</b></p>
          <p>
          Our JSA group of institutions is dedicated to empowering women through quality education and modern facilities. With a mission to facilitate students in developing professional and life skills to enhance their employability, The institution aims to shape confident and financially empowered individuals for their future endeavours.The Jawaharlal Nehru College for women tradition combines academic excellence with a vibrant co-curricular programmes like sports, leadership training, and placement activities. We provide a holistic learning experience for the students and, the institution places genuine concern on the emotional growth of its students alongside intellectual excellence. Our focus is on empowering students to develop self-esteem, self-awareness, and emotional resilience. Discipline, values, and integrity form the foundation of the institution, nurturing a supportive and inclusive environment for all students.By providing modern educational facilities and a well-rounded education, we committed to prepare women for successful and fulfilling futures in the professional world.
          </p>
          <p>
         
          </p>
        </div>
      </div>
    </div>
  
  
  );
}

export default principal;
