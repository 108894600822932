import React from 'react'
import { Helmet } from "react-helmet-async";
import "./style.css";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import Culture2020_1 from "../../../assets/images/gallery/2020/culture/Img (2).jpg";
import Culture2020_2 from "../../../assets/images/gallery/2020/culture/Img (3).jpg";
import Culture2020_3 from "../../../assets/images/gallery/2020/culture/Img (4).jpg";
import Culture2020_4 from "../../../assets/images/gallery/2020/culture/Img (5).jpg";
import Culture2020_5 from "../../../assets/images/gallery/2020/culture/Img (6).jpg";
import Culture2020_7 from "../../../assets/images/gallery/2020/culture/Img (7).jpg";
import Culture2020_6 from "../../../assets/images/gallery/2020/culture/Img (8).jpg";
import Culture2020_8 from "../../../assets/images/gallery/2020/culture/Img (9).jpg";
import Culture2020_9 from "../../../assets/images/gallery/2020/culture/Img (10).jpg";
import Culture2020_10 from "../../../assets/images/gallery/2020/culture/Img (12).jpg";
import Culture2020_11 from "../../../assets/images/gallery/2020/culture/Img (13).jpg";
import Culture2020_12 from "../../../assets/images/gallery/2020/culture/Img (14).jpg";
import Culture2020_13 from "../../../assets/images/gallery/2020/culture/Img (15).jpg";
import Culture2020_14 from "../../../assets/images/gallery/2020/culture/Img (16).jpg";
import Culture2020_15 from "../../../assets/images/gallery/2020/culture/Img (17).jpg";
import Culture2020_16 from "../../../assets/images/gallery/2020/culture/Img (18).jpg";
import Culture2020_17 from "../../../assets/images/gallery/2020/culture/Img (19).jpg";
import Culture2020_18 from "../../../assets/images/gallery/2020/culture/Img (20).jpg";
import Culture2020_19 from "../../../assets/images/gallery/2020/culture/Img (21).jpg";
import Culture2020_20 from "../../../assets/images/gallery/2020/culture/Img (23).jpg";
import Culture2020_21 from "../../../assets/images/gallery/2020/culture/Img (24).jpg";
import Culture2020_22 from "../../../assets/images/gallery/2020/culture/Img (22).jpg";
import Culture2020_23 from "../../../assets/images/gallery/2020/culture/Img (25).jpg";
import Culture2020_24 from "../../../assets/images/gallery/2020/culture/Img (26).jpg";
import Culture2020_25 from "../../../assets/images/gallery/2020/culture/Img (27).jpg";
import Culture2020_26 from "../../../assets/images/gallery/2020/culture/Img (28).jpg";
import Culture2020_27 from "../../../assets/images/gallery/2020/culture/Img (29).jpg";
import Culture2020_28 from "../../../assets/images/gallery/2020/culture/Img (30).jpg";
import Culture2020_29 from "../../../assets/images/gallery/2020/culture/Img (31).jpg";
import Culture2020_30 from "../../../assets/images/gallery/2020/culture/Img (32).jpg";
import Culture2020_31 from "../../../assets/images/gallery/2020/culture/Img (33).jpg";
import Culture2020_32 from "../../../assets/images/gallery/2020/culture/Img (34).jpg";
import Culture2020_33 from "../../../assets/images/gallery/2020/culture/Img (35).jpg";
import Culture2020_34 from "../../../assets/images/gallery/2020/culture/Img (36).jpg";
import Culture2020_35 from "../../../assets/images/gallery/2020/culture/Img (37).jpg";
import Culture2020_36 from "../../../assets/images/gallery/2020/culture/Img (38).jpg";
import Culture2020_37 from "../../../assets/images/gallery/2020/culture/Img (40).jpg";
import Culture2020_38 from "../../../assets/images/gallery/2020/culture/Img (39).jpg";
import Culture2020_39 from "../../../assets/images/gallery/2020/culture/Img (41).jpg";
import Culture2020_40 from "../../../assets/images/gallery/2020/culture/Img (42).jpg";
import Culture2020_41 from "../../../assets/images/gallery/2020/culture/Img (43).jpg";
import Culture2020_42 from "../../../assets/images/gallery/2020/culture/Img (44).jpg";
import Culture2020_43 from "../../../assets/images/gallery/2020/culture/Img (45).jpg";
import Culture2020_44 from "../../../assets/images/gallery/2020/culture/Img (46).jpg";
import Culture2020_45 from "../../../assets/images/gallery/2020/culture/Img (47).jpg";
import Culture2020_46 from "../../../assets/images/gallery/2020/culture/Img (48).jpg";
import Culture2020_47 from "../../../assets/images/gallery/2020/culture/Img (49).jpg";
import Culture2020_48 from "../../../assets/images/gallery/2020/culture/Img (50).jpg";
import Culture2020_49 from "../../../assets/images/gallery/2020/culture/Img (51).jpg";
import Culture2020_50 from "../../../assets/images/gallery/2020/culture/Img (52).jpg";
import Culture2020_51 from "../../../assets/images/gallery/2020/culture/Img (53).jpg";
import Culture2020_52 from "../../../assets/images/gallery/2020/culture/Img (54).jpg";
import Culture2020_53 from "../../../assets/images/gallery/2020/culture/Img (55).jpg";
import Culture2020_54 from "../../../assets/images/gallery/2020/culture/Img (56).jpg";
import Culture2020_55 from "../../../assets/images/gallery/2020/culture/Img (57).jpg";
import Culture2020_56 from "../../../assets/images/gallery/2020/culture/Img (58).jpg";
import Culture2020_57 from "../../../assets/images/gallery/2020/culture/Img (59).jpg";
import Culture2020_58 from "../../../assets/images/gallery/2020/culture/Img (60).jpg";
import Culture2020_59 from "../../../assets/images/gallery/2020/culture/Img (61).jpg";
import Culture2020_60 from "../../../assets/images/gallery/2020/culture/Img (62).jpg";


import Entreprenurship_1 from '../../../assets/images/gallery/2020/edp/Img (1).JPG';
import Entreprenurship_2 from '../../../assets/images/gallery/2020/edp/Img (2).jpg';
import Entreprenurship_3 from '../../../assets/images/gallery/2020/edp/Img (3).jpg';
import Entreprenurship_4 from '../../../assets/images/gallery/2020/edp/Img (4).jpg';
import Entreprenurship_5 from '../../../assets/images/gallery/2020/edp/Img (5).jpg';
import Entreprenurship_6 from '../../../assets/images/gallery/2020/edp/Img (6).jpg';
import Entreprenurship_7 from '../../../assets/images/gallery/2020/edp/Img (7).jpg';
import Entreprenurship_8 from '../../../assets/images/gallery/2020/edp/Img (8).jpg';
import Entreprenurship_9 from '../../../assets/images/gallery/2020/edp/Img (9).jpg';
import Entreprenurship_10 from '../../../assets/images/gallery/2020/edp/Img (10).jpg';
import Entreprenurship_11 from '../../../assets/images/gallery/2020/edp/Img (11).jpg';
import Entreprenurship_12 from '../../../assets/images/gallery/2020/edp/Img (12).jpg';
import Entreprenurship_13 from '../../../assets/images/gallery/2020/edp/Img (13).jpg';
import Entreprenurship_14 from '../../../assets/images/gallery/2020/edp/Img (14).jpg';
import Entreprenurship_15 from '../../../assets/images/gallery/2020/edp/Img (15).jpg';
import Entreprenurship_16 from '../../../assets/images/gallery/2020/edp/Img (16).jpg';
import Entreprenurship_17 from '../../../assets/images/gallery/2020/edp/Img (17).jpg';
import Entreprenurship_18 from '../../../assets/images/gallery/2020/edp/Img (18).jpg';
import Entreprenurship_19 from '../../../assets/images/gallery/2020/edp/Img (19).jpg';
import Entreprenurship_20 from '../../../assets/images/gallery/2020/edp/Img (20).jpg';
import Entreprenurship_21 from '../../../assets/images/gallery/2020/edp/Img (21).jpg';
import Entreprenurship_23 from '../../../assets/images/gallery/2020/edp/Img (22).jpg';
import Entreprenurship_22 from '../../../assets/images/gallery/2020/edp/Img (23).jpg';
import Entreprenurship_24 from '../../../assets/images/gallery/2020/edp/Img (24).jpg';
import Entreprenurship_25 from '../../../assets/images/gallery/2020/edp/Img (25).jpg';
import Entreprenurship_26 from '../../../assets/images/gallery/2020/edp/Img (26).jpg';
import Entreprenurship_27 from '../../../assets/images/gallery/2020/edp/Img (27).jpg';
import Entreprenurship_28 from '../../../assets/images/gallery/2020/edp/Img (28).jpg';
import Entreprenurship_29 from '../../../assets/images/gallery/2020/edp/Img (29).jpg';
import Entreprenurship_30 from '../../../assets/images/gallery/2020/edp/Img (30).jpg';
import Entreprenurship_31 from '../../../assets/images/gallery/2020/edp/Img (31).jpg';
import Entreprenurship_32 from '../../../assets/images/gallery/2020/edp/Img (32).jpg';
import Entreprenurship_33 from '../../../assets/images/gallery/2020/edp/Img (33).jpg';
import Entreprenurship_34 from '../../../assets/images/gallery/2020/edp/Img (34).jpg';
import Entreprenurship_35 from '../../../assets/images/gallery/2020/edp/Img (35).jpg';
import Entreprenurship_36 from '../../../assets/images/gallery/2020/edp/Img (36).jpg';
import Entreprenurship_37 from '../../../assets/images/gallery/2020/edp/Img (37).jpg';
import Entreprenurship_38 from '../../../assets/images/gallery/2020/edp/Img (38).jpg';
import Entreprenurship_39 from '../../../assets/images/gallery/2020/edp/Img (39).jpg';
import Entreprenurship_40 from '../../../assets/images/gallery/2020/edp/Img (40).jpg';

import Pongal_1 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (1).jpg";
import Pongal_2 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (2).jpg";
import Pongal_3 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (3).jpg";
import Pongal_4 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (4).jpg";
import Pongal_5 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (5).jpg";
import Pongal_6 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (6).jpg";
import Pongal_7 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (7).jpg";
import Pongal_8 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (8).jpg";
import Pongal_9 from "../../../assets/images/gallery/2020/Pongal celebrations/Img (9).jpg";


const Moments2020 = () => {
       
    const Culture2020=[
    {
    image:Culture2020_1,
    altText: "",
    caption: "",
    id:"culture",
        },
        {
    image:Culture2020_2,
    altText: "",
    caption: "",
    id:"culture",
},
        {
            image:Culture2020_3,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_4,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_5,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_6,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_7,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_8,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_9,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_10,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_11,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_12,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_13,
            altText: "",
            caption: "",
            id:"culture",
        },

         {
            image:Culture2020_14,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_15,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_16,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_17,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_18,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_19,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_20,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_21,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_22,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_23,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_24,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_25,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_26,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_27,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_28,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_29,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_30,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_31,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_32,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_33,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_34,
            altText: "",
            caption: "",
            id:"culture",
        },

         {
            image:Culture2020_35,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_36,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_37,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_38,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_39,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_40,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_41,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_42,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_43,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_44,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_45,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_46,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_47,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_48,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_49,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_50,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_51,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_52,
            altText: "",
            caption: "",
            id:"culture",
        },

         {
            image:Culture2020_53,
            altText: "",
            caption: "",
            id:"culture",
        },

        {
            image:Culture2020_54,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_55,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_56,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_57,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_58,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_59,
            altText: "",
            caption: "",
            id:"culture",
        },
        {
            image:Culture2020_60,
            altText: "",
            caption: "",
            id:"culture",
        },
    ];
     const Entreprenurship=[
        {
            image:Entreprenurship_1,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_2,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_3,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_4,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_5,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_6,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_7,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_8,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_9,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_10,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_11,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_12,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_13,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_14,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_15,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_16,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_17,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_18,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_19,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_20,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_21,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_22,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_23,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_24,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_25,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_26,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_27,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_28,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_29,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_30,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_31,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_32,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_33,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_34,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_35,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_36,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_37,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_38,
            altText: "",
            caption: "",
            id:"edp",
        },
        {
            image:Entreprenurship_39,
            altText: "",
            caption: "",
            id:"edp",
        },

        {
            image:Entreprenurship_40,
            altText: "",
            caption: "",
            id:"edp",
        },
         
     ];
      
     const Pongal =[
       {
        image:Pongal_1,
        id : "pongal",
       },
       {
        image:Pongal_2,
        id : "pongal",
       },
       {
        image:Pongal_3,
        id : "pongal",
       },
       {
        image:Pongal_4,
        id : "pongal",
       },
       {
        image:Pongal_6,
        id : "pongal",
       },
       {
        image:Pongal_5,
        id : "pongal",
       },
       {
        image:Pongal_7,
        id : "pongal",
       },
       {
        image:Pongal_8,
        id : "pongal",
       },
       {
        image:Pongal_9,
        id : "pongal",
       },
     ];
  return (
   <>
    <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

    <div className="container">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5">JNCW-2020 Moments</h4>
            </div>
      <EventContainer
            data={Culture2020}
            header="Fresher day,Culture,Sprots day  & Annual Day "
            id="culture"
            />
               <EventContainer
            data={Entreprenurship}
            header="Entreprenurship Development Cell "
            id="edp"
            />
                <EventContainer
            data={Pongal}
            header="Pongal Celebrations-2020 "
            id="pongal"
            />
         
          
          </div>

            
   </>


  );
};

export default Moments2020;