import React from 'react';
import Campus8 from "../../../assets/images/Infrastrutre/Img  (1).JPG";
import Campus9 from "../../../assets/images/Infrastrutre/Img  (2).JPG";
import Campus10 from "../../../assets/images/Infrastrutre/Img  (3).JPG";
import Campus11 from "../../../assets/images/Infrastrutre/Img  (4).JPG";
import Campus12 from "../../../assets/images/Infrastrutre/Img  (5).JPG";
import Campus6 from "../../../assets/images/Infrastrutre/Img  (6).JPG";
import Campus7 from "../../../assets/images/Infrastrutre/Img  (7).JPG";
import Campus1 from "../../../assets/images/Infrastrutre/Img  (8).png";
import Campus2 from "../../../assets/images/Infrastrutre/Img  (9).png";
import Campus3 from "../../../assets/images/Infrastrutre/Img  (11).png";
import Campus4 from "../../../assets/images/Infrastrutre/Img  (10).png";
import Campus5 from "../../../assets/images/Infrastrutre/Img  (12).png";
import "./facility.css";
import { Helmet } from "react-helmet-async";

function Facility() {
	
  return (
<>
<Helmet>
        <title>Infrastructure- JNCW</title>
      </Helmet>
<div className='container'>
<div class="gallery__page">
<div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Our Campus</h4>
                {/* <h4 class="mb-5">JNCW-2024 Moments </h4> */}
            </div>
		<div class="gallery__warp">
			<div class="row">
			<p>It has an Extensive Campus With All Modern Infrastructure in an Extent of 15 acres. It is a Self Financing Institutions Affilated to Annamalai University, Chidambaram. The College Offer 13 Under Graduate and 4 Post Graduate Courses in Arts and Science Streams. The College has a Team of Qualified and well Expericenced Faculty Members.Academics are supplemented with Vocational Skill Development ,Co-Curricular and Extra Curricular Activities in which College has Excellent Track records.The College Provides Various Facilities Such as Smart Classrooms,Digitalized library,Language Lab, Well Equipped Laboratories, Technically Advanced Auditorium, Easy Accessible Transport,Extensive Playground , Safe and Secured In-Campus Hostel.It also Provides additional Entrepreneurship Programs. The College takes Pride in the Commitment of Providing 100% Placement assistance, Ensuring that every Student has the opportunity to embark on a successful Career journey.

The college Strives for Inculcating Discipline and Values and imparting regular Training among students for their future success.</p>
				
				
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus1} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus2} alt="" style={{width:"100%",height:"100%"}}  />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus3} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus4} alt=""  style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus5} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus6} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus7} alt=""  style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus8} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus9} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus10} alt="" style={{width:"100%",height:"100%"}}/>
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus11} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<a class="gallery__item fresco" href=" " data-fresco-group="gallery">
						<img src={Campus12} alt="" style={{width:"100%",height:"100%"}} />
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
</>
  )
}

export default Facility