import React from "react";
import "./style.css";

function ComputerApplication() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        DEPARTMENT OF COMPUTER APPLICATION
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The Department of B.C.A was established in the year 2014 with a view
            to fulfill the dynamic needs of IT sector all over the world The
            activities conducted in the department offers good resource of
            knowledge regarding the current trends in IT sectors and global
            application of computers The department is well equipped with all
            the basic and latest resources. The department comprises of well
            qualified and dedicated faculty team.
          </p>
          <p>
            The academic performance of the department has been excellent and
            having percentages for university rank. Our ambition is to make the
            students use their intellectual caliber for effective and quick
            acquisition. The Management has well designed policy to employ
            modern teaching methods and placement team. The students are given
            Excellent Training for placement in IT sectors and MNC sectors.
          </p>
          <p>
            The activities conducted in the Department like Industrial visit,
            Guest Lectures, Seminars and Workshops by Resource persons, Chart
            Exhibition and Intra departmental competitions like Computer Quiz,
            Paper presentation and Debugging gives good source of Knowledge and
            wisdom in their area.
          </p>

          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            <ul>
              To be a center of excellence for development and dissemination of
              knowledge in computer applications for the nation and beyond.
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                Engrave the students into competent and creative technocrats and
                give them a competitive advantage in the ever-changing and
                challenging global work environment
              </li>
              <li>
                Import value-based education and integrate research results and
                innovations into other scientific disciplines.
              </li>
              <li>
                Educate students to be successful, ethical and effective
                problem-solvers and life-long learners who will contribute to
                the well-being of our nation.
              </li>
              <li>
                Prosper in academic activities by continual improvement in
                teaching methods, laboratory facilities and research activities.
              </li>
              <li>Develop consultancy for various industries.</li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">OBJECTIVES</h5>
            </div>
            <ul>
              <li>
                Apply computing and mathematical knowlodge to identify,
                formulate, design, implement and develop software solutions for
                problems across a broad range of application domains.
              </li>
              <li>
                Communicate effectively as an individual and also as a leader in
                diverse groups and in multi-disciplinary setting.
              </li>
              <li>
                Develop the professional ethics in work and realize the role of
                self in the development of society with a strive for independent
                and life- long learning in the broadest context ever flourishing
                technological changes.
              </li>
            </ul>
          </p>

          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">PROGRAM OUTCOMES</h5>
              <b>Computational Knowledge : </b>
            </div>
            Apply knowledge of computing fundamentals, computing specialization,
            mathematics, and domain knowledge appropriate for the computing
            specialization to the abstraction and conceptualization of computing
            models from defined problems and requirements.
            <div>
              <b>Design/Development of Solutions : </b>
            </div>
            Design and evaluate solutions for complex computing problems, and
            design and evaluate systems, components, or processes that meet
            specified needs with appropriate consideration for public health and
            safety, cultural, societal, and environmental considerations.
            <div>
              <b>Individual and Team Work : </b>
            </div>
            Function effectively as an individual and as a member or leader in
            diverse teams and in multidisciplinary environments.
            <div>
              <b>Communication Efficacy: </b>
            </div>
            Communicate effectively with the computing community, and with
            society at large, about complex computing activities by being able
            to comprehend and write effective reports, design documentation,
            make effective presentations, and give and understand clear
            instructions.
            <div>
              <b>Higher Studies Opportunities </b>
            </div>
            <ul>
              <li>Internship Abroad & MS</li>
              <li>
                Final Semester can be aboard and pursue MS after internship /
                project.
              </li>
              <li>
                MCA: Join MCA lateral entry scheme directly to second year.
              </li>
              <li> MBA: Join MBA Systems / Marketing. IT exposure is good</li>
              <li>
                M.Sc.CS/IT: Join M.Sc. / IT and get job in teaching / research
                projects
              </li>
            </ul>
            Fresh graduates can start their career with titles such as "Software
            Publishers", "Information System Managers", Database
            Administrators", and more. All the latest fields cannot flourish
            without the help of Information Technology. This includes
            <ul>
              <li> Artificial Intelligence</li>
              <li>Machine Learning</li>
              <li> Internet of Things</li>
              <li> Nano Technology</li>
              <li> Bio-Technology</li>
              <li>Research Industries</li>
              <li>Government Sectors</li>
              <li> Private Sectors</li>
              <li> Educational Institutions</li>
              <li> I.T. Companies</li>
              <li> Mobile Companies and many more</li>
            </ul>
            <div>
              <b>Areas of Recruitment for BCA Graduates</b>
            </div>
            <li> Banks</li>
          </p>

          <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">TUTORIAL SYSTEM</h5>
              We follow the tutorial system as per the guidance of the principal
              and management. Through this system. Students meet their tutors
              and discuss their academic, Extra-Curricular, and co-curricular
              activities. It helps the students to develop their studies and
              Problem Solving capacity.
            </div>
          </p>

          <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">
                SCOPE FOR ENGLISH DEPARTMENT
              </h5>
              <ul>
                <li>
                  Wide range of opportunities in the teaching field for Teachers
                  and Lecturers. (Both in India and Abroad)
                </li>
                <li>In Multi-National Companies – HR Managers </li>
                <li>
                  Entertainment field – Anchors, Editors, RJs, Sports Reporters,
                  Cricket Commenters
                </li>
                <li>
                  Journal and News stations – Reporters, News readers, and
                  Editors
                </li>
                <li>
                  Publication jobs – publishers of books, articles, and literary
                  works
                </li>
                <li>
                  As Translators – In many fields. Translation of books and
                  languages Writers – In producing literary works such as Poets,
                  novelists, dramatists, and also creative writers in TV films,
                  videos, and movies.
                </li>
                <li>Lawyers As Scriptwriters and Copywriters</li>
                <li>
                  Institutions for Spoken English and other Coaching
                  Institutions for TOFEL, IELTS. Abundant opportunities and
                  demand for Spoken English and Communication Trainers. TOEFL,
                  IELTS
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ComputerApplication;
