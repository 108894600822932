import React from "react";
import { Helmet } from "react-helmet-async";
const ContactUs = () => {
  return (
    <>
     <Helmet>
        <title>contactus- JNCW</title>
      </Helmet>
       <div className="container mt-4">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Contact Us</h5>
            
            </div>
      <div className="row">
      <div className="col-lg-12 mb-4">
          <div className="card">
            <div className="card-header">
              <h4 className="mb-0">Location Map</h4>
            </div>
            <div className="card-body">
              <iframe
                title="map"
                style={{ border: "0", width: "100%", height: "300px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15630.484725509568!2d79.232299!3d11.65032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab4ec555555557%3A0xb4398f3d7a9188f3!2sJawaharlal%20Nehru%20College%20for%20Women!5e0!3m2!1sen!2sin!4v1710393557337!5m2!1sen!2sin"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card">
            <div className="card-header">
              <h4 className="mb-0">College Address</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <p className="mb-1">
                    <i className="fa fa-map-marker me-2 text-secondary"></i>
                    Jawaharlal Nehru College For Women,
                    <br />
                    (Arts & Science), Pali, Ulundurpet,
                    <br />
                    Tamilnadu - 606104.
                  </p>
                  <p className="mb-1">
                    <i className="fa fa-envelope me-2 text-secondary"></i>
                    <a href="mailto:jcwomen@gmail.com" style={{ textDecoration: 'none' }}>jcwomen@gmail.com</a>
                  </p>
                  <p className="mb-0">
                    <i className="fa fa-phone me-2 text-secondary"></i>
                    <a href="tel:+919361909060" style={{ textDecoration: 'none' }}>+91 9361909060</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card">
            <div className="card-header">
              <h4 className="mb-0">Administrative Office</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <p className="mb-1">
                    <i className="fa fa-map-marker me-2 text-secondary"></i>
                    Shri Venkateshwara Educational Trust,
                    <br />
                    No.30/1, Jayam Arun Plaza,
                    <br />
                    Advaith Ashram Road, New Fairlands,
                    <br />
                    Salem - 636016.
                  </p>
                  <p className="mb-0">
                    <i className="fa fa-phone me-2 text-secondary"></i>
                    <a href="tel:+919361909070" style={{ textDecoration: 'none' }}>+91 93619 09070</a> |{" "}
                    <a href="tel:+917305057756"style={{ textDecoration: 'none' }}>+91 73050 57756</a> |{" "}
                    <a href="tel:+919786646795"style={{ textDecoration: 'none' }}>+91 97866 46795</a> |{" "}
                    <a href="tel:+919566223456"style={{ textDecoration: 'none' }}>+91 95662 23456</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
    </>
   
  );
};

export default ContactUs;
