import React from 'react'
import "./style.css";

function BussinessAdministration() {
  return (
   <>
  <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Business Administration (BBA)
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
          The department was started in the academic year 2013-2014.Bachelor of Business Administration (BBA) is a 3 years graduation course. The course teaches the students about the higher level concepts in the domain of Business Administration in multi fold specializations of Financial Management, Marketing Management, HRM AND Production Management. Apart from the academic curriculum the department organizes industrial visits for the students in order give them practical exposures to the real life industrial climate and practices followed.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
              Scope of BBA
              </h5>
            </div>
            <ul>
              {" "}
              <li>
              The BBA course offers knowledge and training in management and leadership skills to prepare them for managerial roles and entrepreneurship.
              </li>
              <li>
              It will provide a student with conceptual, theoretical and practical knowledge in various aspects of business like finance, HRM, economics, operations, marketing, basic accounting.
              </li>
              <li>
              Students acquire management skills at very early stage. It helps them to learn the knowledge of business world and act as a leader to take decisions in business world.
              </li>
              <li>
              In today’s competitive world where everybody wishes to become an entrepreneur, this course helps you think more precisely on different aspects of running a business. It helps in building tactical skills.
              </li>
             </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
              Why BBA
              </h5>
            </div>
            <ul>
              {" "}
              <li>
              Larger Perspective of Business World is another advantage of the course. Students acquire management skills at very early stage .It helps student learn the knowledge of business world and act as a leader to take decisions in business world.
              </li>
              <li>
              In today's competitive world where everybody wishes to become an entrepreneur, this course helps you think more precisely on different aspects of running a business. It helps in building tactical skills.
              </li>
              <li>
              Better pay packages at early stage of life. Person becomes financial independent just after schooling which gives confidence and lets the person develop strategic planning.
              </li>
          
             </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            To help students achieve pinnacle of success and groom them to become successful management professionals and entrepreneurs through imparting continuous learning and attitude development.
          </p>

          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              {" "}
              To impart quality education in diverse management domain, reinforce business ethics and social values among students, fine tune the students to the dynamic to the changing world and provide the platform to have smooth take off to the corporate world.
            </ul>
          </p>
        </div>
      </div>
    </div>

   </>
  )
}

export default BussinessAdministration;