import React from "react";
import "./style.css";

function Maths() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Mathematics
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The Department of Mathematics was originally established in the year
            2011 to offer Mathematics course for undergraduate (B. Sc.,)and
            post-graduate was established in the year 2015 (M. Sc.,).we have
            send six batches from B.Sc., mathematics and three batches M.Sc.,
            mathematics from our department with good results. And the faculty
            members are 10 members and we have qualified staff members.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                Scope of B.Sc Mathematics
              </h5>
            </div>
            <ul>
              {" "}
              <li>
                Mathematics has always been one of the popular subjects among
                students fraternity. Mathematics offers job opportunity in
                statistics, teaching, cryptography, actuarial science and
                mathematical modeling.
              </li>
              <li>
                There is a wide array of career prospects in mathematics field.
                There is a demand of mathematics students in fields such as
                statistics, engineering, physical science, computer science,
                insurance, economics, astronomy, banking and accountancy. There
                is also a wide scope of mathematics in teaching and research
                fields.
              </li>
              <li>
                All the highest paying jobs are directly or indirectly related
                with mathematics. Mathematics jobs are available in both
                government as well as private organizations. In the government
                sector, mathematics graduates may consumed in government
                departments, semi-governments.
              </li>
              <li>
                Besides academics jobs, trained mathematicians are also engaged
                at good remuneration and package in Indian Space Research
                Organization (ISRO), Defense Research and Development
                Organization (DRDO), National Aeronautic Limited (NAL) and
                Society for Electronic Transaction and Security (SETS).
              </li>
              <li>
                Teaching is one of the most sought after profession after Ph.D.
                One may appointed as Assistant Professor, Associate Professor,
                Reader and Professor in colleges and universities. Schools also
                offer good jobs for mathematics graduate both in the government
                as well as in the private sector.
              </li>
              <li>
                Mathematics and statistics teach a person to be a good problem
                solver, and that is the key of reaching the pinnacle of success
                in any career. Finding the efficient ways to solve problems
                inherent in your job is also a skill developed through the study
                of mathematics.
              </li>
              <li>
                It is used to crack these competitive exams IBPS, NET, SET,
                TNPSC, NBHM, JRF, TET, TRB, RRB, RBI,UPSC
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            To empower students with sound knowledge and investigate new
            methodologies for future applications. The department aims to be a
            center of excellence in mathematics and computing and is vigorously
            engaged in both research and teaching. Our goal at Castlewood is to
            provide mathematics instruction that will encourage students to
            become accurate, efficient, and flexible problem solvers.
          </p>

          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              {" "}
              To encourage students to conduct student projects to develop their
              analytical and logical thinking. The students are groomed
              academically, under careful personal monitoring by the faculty
              members. To inculcate in students the ability to apply
              mathematical and computational skills to model, Formulate and
              solve real life applications. To Produce Post –Graduate students
              will strong foundation to join research or serve in industry.
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Maths;
