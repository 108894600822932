import React from "react";
import "./style.css";

function BscComputer() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Computer Science
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The department of Computer Science commenced from the year 2011.
            Computer science is one of the vital and rapidly developing
            disciplines. Computer Science has been designed to teach a range of
            advanced topics and a carefully-selected core of foundational
            subjects to graduates of Computer Science. This programme is
            designed in such a way so as to impart training in the recent
            advancements and modern trends in the field of Computer Science.
          </p>
          <p>
            In JNCW M.sc Computer Science programme has been offered since the
            academic year 2015-16. We are providing many Seminars, Workshops and
            Guest Lectures in the latest fields of computer science for
            enhancing the knowledge of our UG & PG students.
          </p>
          <p>
            We are having 24/7 Internet facility with 100 high configuration
            computers for providing depth practical knowledge to our students.
            Moreover we are providing special practical workshops on computers
            other than the curriculum of Computer Science course.
          </p>
          <p>
            After the graduation of both UG & PG, the students can go for higher
            studies or for a job. Placement opportunities are good. Many of our
            students have got good placements through on-campus and off-campus
            interviews.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                Scope of B.Sc Computer Science
              </h5>
            </div>
            <ul>
              {" "}
              <li>
                BCA graduates have good scope in jobs as a Web Developer, Web
                Designer, Network Administrator, System Manager, Computer
                Programmer, Software Developer, Software Tester, etc depending
                on the skills you acquire during your BCA.
              </li>
              <li>
                Self employment option is also available. You can do freelancing
                or develop your own software if you have that much skill.
              </li>
              <li>
                A person who has completed BCA from any reputed institution will
                very easily get a job as a " Chief Information Officer, Computer
                Support Service Specialist, Computer Scientist, Computer Systems
                Analyst. Database Administration, Information Systems Manager,
                Software Developer, Software Publisher, Systems" Administrator,
                Teacher or Lecturer in any organisation. However, if someone is
                willing to work in Government organisation there is a vacancy
                there as well. After BCA one can join government banks as
                Clerks, Probationary Officer, Short Service commission for Navy
                or Army, IAS, IPS, CBI, Group examinations, RRB or UPSC.
              </li>
              <li>
                Self employment option is also available. You can do freelancing
                or develop your own software if you have that much skill.
              </li>
              <li>
                System engineer in the top companies like Infosys, Wipro, hp,
                Google. A system engineer develop, test and evaluate software,
                circuits and personal computer.
              </li>
              <li>
                The duty of programmer is to write code for software. A
                programmer is primarily works in the computer language such as
                Assembly, COBOL, C, C++, C#, Java, Lisp, Python, etc.
              </li>
              <li>
                A web developer is a programmer who specializes in the
                development of world wide web applications. The role of web
                developer is to build and maintain websites. A web developer
                must have skills in HTML/XHTML, CSS, PHP, JavaScript , etc.
              </li>
              <li>
                System Administrator in an organization including a bank, school
                or college. A System administrator is responsible for setting up
                and maintaining the system or server.
              </li>
              <li>
                The sole responsibility of software developer is to develop
                software that ease the tasks of the people and enable to perform
                work efficiently. A software developer also installs, test and
                maintains the software.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            <ul>
              <li>To give comprehensive and flexible technical education.</li>
              <li>To equip the students for future global competition.</li>
              <li>
                To impart entrepreneurship skills in our students making them
                capable of self employment and also to employ others.
              </li>
              <li>
                To create centre of excellence in forthcoming technologies to
                keep our students as well as faculty, loaded with vital skills
                as per the latest industry standards.
              </li>
              <li>
                To empower the students ethically as well as technically so as
                to present themselves as an indispensable choice to the
                prospective employer.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                To furnish late bloomers with additional educational support.
              </li>
              <li>To augment the skills of students as well as faculty.</li>
              <li>
                To establish an environment which is appealing for practical
                education and learning.
              </li>
              <li>
                To prioritize on uplifting the professional and communication
                skills of the students.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default BscComputer;
