import "./styles.scss";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import Slide_1 from "../../../../assets/images/banner/jpg/banner-1.jpg";
import Slide_2 from "../../../../assets/images/banner/jpg/banner-2.jpg";
import Slide_3 from "../../../../assets/images/banner/jpg/banner-3.jpg";
import Slide_4 from "../../../../assets/images/banner/jpg/banner-4.jpg";
import Slide_5 from "../../../../assets/images/banner/jpg/banner-5.jpg";
import Slide_6 from "../../../../assets/images/banner/jpg/banner-6.jpg";
import Slide_7 from "../../../../assets/images/banner/jpg/banner-7.jpg";
import Slide_8 from "../../../../assets/images/banner/jpg/banner-8.jpg";
import Slide_9 from "../../../../assets/images/banner/jpg/banner-9.jpg";


import SlideWebp_1 from "../../../../assets/images/banner/webp/banner-1.jpg";
import SlideWebp_2 from "../../../../assets/images/banner/webp/banner-2.jpg";
import SlideWebp_3 from "../../../../assets/images/banner/webp/banner-3.jpg";
import SlideWebp_4 from "../../../../assets/images/banner/webp/banner-4.jpg";
import SlideWebp_5 from "../../../../assets/images/banner/webp/banner-5.jpg";
import SlideWebp_6 from "../../../../assets/images/banner/webp/banner-6.jpg";
import SlideWebp_7 from "../../../../assets/images/banner/webp/banner-7.jpg";
import SlideWebp_8 from "../../../../assets/images/banner/webp/banner-8.jpg";
import SlideWebp_9 from "../../../../assets/images/banner/webp/banner-9.jpg";

import Image from "react-image-webp";

const HomeBanner = () => {
  
  // const BannerContent = [
  //   {
  //     id: 1,
  //     image: Slide_1,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'The Best Campus of',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 2,
  //     image: Slide_2,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'Hello ',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 3,
  //     image: Slide_3,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'everyone',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 4,
  //     image: Slide_4,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'Jncw2',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 5,
  //     image: Slide_5,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'The Best Campus of',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 6,
  //     image: Slide_6,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'The Best Campus of',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  //   {
  //     id: 7,
  //     image: Slide_7,
  //     title: 'Welcome to JNCW',
  //     subtitle: 'The Best Campus of',
  //     description: 'Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no...',
  //     button1: 'Read More',
  //     button2: 'Join Now',
  //   },
  // ];
  const BannerContent = [
        {
      jpg: Slide_1,
      webp: SlideWebp_1,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_2,
      webp: SlideWebp_2,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_3,
      webp: SlideWebp_3,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_4,
      webp: SlideWebp_4,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_5,
      webp: SlideWebp_5,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_6,
      webp: SlideWebp_6,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_7,
      webp: SlideWebp_7,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_8,
      webp: SlideWebp_8,
      content: "JSACAT-Banner-image",
    },
    {
      jpg: Slide_9,
      webp: SlideWebp_9,
      content: "JSACAT-Banner-image",
    },
  ];
  
  return (
    <>
      <div
        id="banner_carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators ">
          {BannerContent.map((item, index) => (
            <button
              type="button"
              data-bs-target="#banner_carousel"
              data-bs-slide-to={index}
              className={index === 0 && "active"}
              aria-label="indicator"
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {BannerContent.map((item, index) => (
            <div
              className={index === 0 ? "carousel-item active" : "carousel-item"}
            >
              <Image
                className="d-block w-100 h-auto"
                src={item.jpg}
                webp={item.webp}
                alt={item.content}
                // height="422"
              />
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#banner_carousel"
          data-bs-slide="prev"
          aria-label="Control_btn"
          style={{ zIndex: "0" }}
        >
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#banner_carousel"
          data-bs-slide="next"
          aria-label="Control_btn"
          style={{ zIndex: "0" }}
        >
          <span className="carousel-control-next-icon"></span>
        </button>
      </div>
    </>
  );
};
export default HomeBanner;
