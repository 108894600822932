import React from 'react'
import smartclass_img1 from "../../../assets/images/Infrastrutre/smart class/Img  (2).JPG";
import smartclass_img2 from "../../../assets/images/Infrastrutre/smart class/Img  (3).JPG";
import { Helmet } from "react-helmet-async";




function smartclass() {
  return (
    <> <Helmet>
    <title>smartclass- JNCW</title>
  </Helmet>
            <div
    className="container mt-4 text-justify"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
            <h4 class="mb-5"> Smart class</h4>
        </div>
         <div className="container">
      <div
        class="panel-group"
        id="accordion"
        role="tablist"
        aria-multiselectable="true"
      >

        <div class="card mt-2">
          <div
            class="collapsed"
            data-bs-toggle="collapse"
            data-bs-parent="#accordion"
            href="#collapse1st"
            aria-expanded="false"
            aria-controls="collapse1st"
          >
            <div
              class="card-header header-green "
              role="tab"
              id="headingOne"
            >
              <h4 className='title'>
        Smart Class
              </h4>
            </div>
          </div>
          <div
            id="collapse1st"
            class="card-collapse collapse in show"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div class="card-body">
              <div class="row">
                {/* <div class="col">
                  <p>
                    Agronomy is one of the prime disciplines of agriculture
                    which deals with cultivation of crops. The land is
                    classified as wet land, garden land and dry land and
                    crops are cultivated in accordance to lands. The
                    department is involved in various practices like
                    optimizing the seed rate, spacing to be adopted,
                    fertilizer schedules and herbicide applications .
                  </p>

                  <p>
                    Irrigation scheduling is another important segment
                    called irrigation agronomy where different irrigation
                    systems like basin, flood irrigation drip irrigation,
                    sprinkler, and sub surface irrigation are practiced.
                    Fodder cultivation is taken up in the department by
                    raising different fodder crops. The cultivable lands
                    come under the Central farm maintained by the agronomy
                    department. All tools, implements and tractors are
                    maintained in the central farm.
                  </p>
                </div> */}
              
             <div className="row row-cols-1 row-cols-md-2 g-4">
             <div className="col">
              <img
                    src={smartclass_img1}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                       <div className="col"> <img
                    src={smartclass_img2}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                      {/* <div className="col">  <img
                    src={Microbiology_lab_img3}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div> */}
                             {/* <div className="col">  <img
                    src={Microbiology_lab_img4}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div> */}
                  </div>  
              </div>
            </div>
          </div>
        </div>
          </div>
    </div>
  </div>
    </>
    

  )
}

export default smartclass