import React from 'react';
import "./style.css";

function BCA() {
  return (
    <div className="container about" data-aos="fade-up" data-aos-duration="1000">
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Computer Application (B.Com CA)
      </h5>
      <div className="container row m-0" data-aos="fade-up" data-aos-duration="2000">
        <div className="col-lg-12">
          <p>
            The B.Com (C.A) course was started in the year 2011 – 2012 to fulfill the needs of rural students in and
            around Ulundurpet town. The department has highly energetic and vibrant staff members with good teaching
            experience. The department aims at developing knowledge, skill, attitude, and values through dynamic and
            interactive methods of learning. We conduct various career development program to encourage our students.
            Some of our students now enjoy positions as proprietors in their own companies, Assistant professors,
            managers, clerks, etc. Many of our students are now working in colleges, BPOs, and various private
            organizations. The highlights of the department are as follows,
          </p>
          <SectionHeader title="Scope of BBA">
            <ul>
              <li>
                The BBA course offers knowledge and training in management and leadership skills to prepare them for
                managerial roles and entrepreneurship.
              </li>
              <li>
                It will provide students with conceptual, theoretical, and practical knowledge in various aspects of
                business like finance, HRM, economics, operations, marketing, basic accounting.
              </li>
              <li>
                Students acquire management skills at a very early stage. It helps them to learn the knowledge of the
                business world and act as a leader to make decisions in the business world.
              </li>
              <li>
                In today’s competitive world where everybody wishes to become an entrepreneur, this course helps you
                think more precisely on different aspects of running a business. It helps in building tactical skills.
              </li>
            </ul>
          </SectionHeader>
          <SectionHeader title="Why BBA">
            <ul>
              <li>
                Larger Perspective of Business World is another advantage of the course. Students acquire management
                skills at a very early stage. It helps students learn the knowledge of the business world and act as a
                leader to make decisions in the business world.
              </li>
              <li>
                In today's competitive world where everybody wishes to become an entrepreneur, this course helps you
                think more precisely on different aspects of running a business. It helps in building tactical skills.
              </li>
              <li>
                Better pay packages at an early stage of life. A person becomes financially independent just after
                schooling which gives confidence and lets the person develop strategic planning.
              </li>
            </ul>
          </SectionHeader>
          <SectionHeader title="VISION">
            <p>
              To help students achieve the pinnacle of success and groom them to become successful management
              professionals and entrepreneurs through imparting continuous learning and attitude development.
            </p>
          </SectionHeader>
          <SectionHeader title="MISSION">
            <ul>
              <li>
                To impart quality education in diverse management domain, reinforce business ethics and social values
                among students, fine-tune the students to the dynamic to the changing world and provide the platform to
                have a smooth take-off to the corporate world.
              </li>
            </ul>
          </SectionHeader>
        </div>
      </div>
    </div>
  );
}

function SectionHeader({ title, children }) {
  return (
    <div className="page-header">
      <h5 className="bg-heading text-uppercase">{title}</h5>
      {children}
    </div>
  );
}

export default BCA;
