import React from "react";
import ChairmanImg from "../../../assets/images/about/chairman/chairman.jpg";
import "./about.scss";
import Secretary from "./secretary";
import { Helmet } from "react-helmet-async";

function about() {
  const customStyle = {
    display: "flex",
    justifycontent: "center" /* Center horizontally */,
    alignitems: "center",
  };
  return (
    <>
      <Helmet>
        <title>About Us- JNCW</title>
      </Helmet>
      <div className="container text-justify">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Our Chairman</h4>
                {/* <h4 class="mb-5">Sports & Event </h4> */}
            </div>
        <div className="chairman_data">
          <img
            src={ChairmanImg}
            alt="chairman of jsa college of agriculture and technology"
            className=" left-img mb-0 rounded"
            data-aos="fade-in"
            data-aos-duration="2000"
          />
          <br />
          <div data-aos="fade-left" data-aos-duration="2000">
            <p
              style={{ color: "#BF40BF", fontSize: "14px", fontWeight: "bold" }}
            >
              {" "}
              <span style={{ fontSize: "18px", color: "#191970" }}>
                Dr. K. Jayaraman B.E, M.B.A, FIE,Ph.D
              </span>
              <br />
              Chairman of Emirates &amp; Managing Director
            </p>
            <p style={{ color: "#4B0082", fontWeight: "bold" }}>
              Shri Venkateswara Educational Trust &amp; JSA Group of Educational
              Institutions
            </p>
          </div>
        </div>
        <div data-aos="fade-in" data-aos-duration="3000">
          <p>
            Graduated in Bachelor of Engineering (Civil) in 1981 from Coimbatore
            Institute of Technology with distinction and got selected as Junior
            Engineer in Public Works Department, Government of Tamil Nadu on
            merit basis. After moving to Highways Department, Government of
            TamilNadu,served in various capacities and reached Super intending
            Engineer position. Finally elevated as{" "}
            <span style={{ color: "#ff1493", fontWeight: "bold" }}>
              Chief Engineer
            </span>{" "}
            (Highways Department) for Construction and Maintenance of Roads and
            Bridges in the entire state and headed the Highways Department in
            Tamil Nadu.
          </p>
          <p>
            Served as a{" "}
            <span style={{ color: "#ff1493", fontWeight: "bold" }}>
              Managing Director
            </span>{" "}
            of Tamil Nadu Construction Corporation, (TNGON) Chennai and served
            as{" "}
            <span style={{ color: "#ff1493", fontWeight: "bold" }}>
              Executive Director
            </span>{" "}
            of Tamil Nadu Road Infrastructure Development Corporation(TNRIDC).
            During his tenure in Highways Department maximum number of Bye
            Passes and Flyover’s were constructed in Tamil Nadu.{" "}
          </p>
          <p>
            <span style={{ color: "#4B0082", fontWeight: "bold" }}>
              His contribution in the dual level elevated Flyover’s for 7.6 KM
              length in Salem Corporation, opened on 11.06.2020 by the Hon’ble
              Chief Minister, Government of Tamil Nadu, is worth mentioning.
            </span>{" "}
            He has been awarded with 12 merit appreciations as medals and
            rewards from the Government of Tamil Nadu.
          </p>
          <p>
            In 2009 started Shri Venkateswara Trust as Charitable Educational
            Trust to uplift the educational levels of poor and to empower women
            through education in socio - economically most backward district of
            TamilNadu. With this aim, Jawaharlal Nehru Arts and Science College
            for Women was started in Ulundurpet, Kallakurichi district.{" "}
          </p>
          {/* <p>
            Through the trust he also runs a{" "}
            <span style={{ color: "#e67b02", fontWeight: "bold" }}>
              Free Treatment Siddha Hospital
            </span>{" "}
            namely{" "}
            <span style={{ color: "#e67b02", fontWeight: "bold" }}>
              JSA Medical College for Siddha and Research Centre
            </span>{" "}
            in Ulundurpet, Kallakurichi District.
          </p> */}
          <p>
            A renowned philanthropist and Chief Engineer (Rtd.) Highways Dept.
            Govt. of Tamil Nadu., an exemplary leader with more than three
            decades of rich experience in the field of engineering and
            Administration has been the driving force behind developing world
            class Infrastructure initiatives and Development in Tamil Nadu. With
            his Interest in the field of education, Culture and to motivate
            Socio-economic and backward regions in the state, he started Shri
            Venkateswara Trust, a charitable and Non-profit organization, aimed
            towards promoting quality education, Women empowerment and to give
            free education to the poor in Rural areas. Under is able guidance,
            Trust established Arts & Science and Agricultural College in Tamil
            Nadu. The trust also established a Medical College/Hospital in
            Siddha and serving the society by providing free treatment to poor.
          </p>
          <p>
            , Trust established Arts & Science and Agricultural College in Tamil
            Nadu. The trust also established a Medical College/Hospital in
            Siddha and serving the society by providing free treatment to poor.
            Education is the foundation of all progress. It is for this reason
            that we made inroads into education, about 9 years ago. This
            experience has shown us that progress will progress only with
            quality education to women. The ultimate purpose of education is not
            to instill textual knowledge only but to seed humanitarian values
            like wisdom, compassion, courage, humility, integrity and
            reliability in a student. We, just like you, believe in holistic
            education for your child, including – academics, co-curricular
            activities, sports education and life-skills learning. Our effort is
            to strike a balance between state-of-the-art infrastructure and a
            globally acceptable education. At Jawaharlal College for Women we
            provide education that is based on conscience and we culture
            specimens of young minds that are robust with self confidence,
            motivation and ever ready to rise up to challenges. The campus,
            sports and academic facilities are all the standing evidences to
            this effort. In order to produce an internationally acceptable
            education, our priority has been on faculty enrichment – supplying
            continuous teacher training in order to give valuable education and
            brilliance in academics. Teachers are trained not only to teach well
            but are also trained to boost confidence and trust in their students
            and become their idols. Further, the College brews in the students a
            respect for tradition and ensures discipline and good manners.
            Jawaharlal Nehru College for Women pushes students to learn about
            themselves and their ever altering surroundings, while at the same
            time lending assistance and guidance as they practice
            decision-making and social skills. We are confident that this
            college is the place for your child. We welcome your enthusiasm and
            dedication in the progress and activities of your child. We look
            forth for your everlasting support.
          </p>{" "}
        </div>
        {/* <Secretary /> */}
      </div>
    </>
  );
}

export default about;
