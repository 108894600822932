import React from 'react';
import EventContainer from "../../../layouts/eventcontainer/eventContainer";
import EvenSemster2023 from "../../../../assets/pdf/university rankers.pdf";
import Ranklist from "./ranklist"; 

function acdemicCalendar() {

    const EvenSemster=[
        {
          file:EvenSemster2023 , description:" "
        }
       ];
    //    const OddSemster=[
    //     {
    //       file:OddSemster2024, description:" "
    //     }
    //    ];

    

  return (
    <div className="container">
      
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title bg-white text-center text-primary px-3">University Rankers </h5>
        {/* <h3 className="mb-5"> 2023-2024 </h3> */}
      </div>
      <Ranklist/>
      <EventContainer
        isFile
        data={EvenSemster}
        header="University Rankers"
        id="oddsemester"
      />

      {/* <EventContainer
        isFile
        data={EvenSemster}
        header="ACADEMIC CALENDAR 2023-2024 (Even Semester )"
        id="evensemester"
      /> */}




    </div>
  )
}

export default acdemicCalendar