import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter } from "react-router-dom";
import AOS from "aos";
import Routers from "./routers";
import WhatsAppStickyButton from "./components/social media/WhatsButton";
import Main from "./components/main/index";
import { useEffect, useState } from "react";
import NewsPage from "./components/pages/News&Announcements/NewsPage";
import { HelmetProvider } from "react-helmet-async";




function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      <HelmetProvider>
       <Main>
      <Routers/>
        <WhatsAppStickyButton/>
      </Main>
      {/* <NewsPage/> */}
      </HelmetProvider>
       </BrowserRouter>
  );
}

export default App;
