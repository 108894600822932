import React from 'react';
import "./styles.css";
import   Cs_lab_img6 from "../../../assets/images/Infrastrutre/Computer lab/Img  (12).png";
import   Cs_lab_img2 from "../../../assets/images/Infrastrutre/Computer lab/cl-1.jpg";
import   Cs_lab_img3 from "../../../assets/images/Infrastrutre/Computer lab/cl-2.jpg";
import   Cs_lab_img4 from "../../../assets/images/Infrastrutre/Computer lab/cl-3.jpg";
import   Cs_lab_img5 from "../../../assets/images/Infrastrutre/Computer lab/cl-4.jpg";
import   Cs_lab_img1 from "../../../assets/images/Infrastrutre/Computer lab/cl-5.jpg";

import English_lab_img1 from "../../../assets/images/Infrastrutre/English lab/eng-lab-1.jpg";
import English_lab_img2 from "../../../assets/images/Infrastrutre/English lab/eng-lab-2.jpg";
import English_lab_img3 from "../../../assets/images/Infrastrutre/English lab/eng-lab-3.jpg";
import English_lab_img4 from "../../../assets/images/Infrastrutre/English lab/eng-lab-4.jpg";

import Chemistry_lab_img1 from "../../../assets/images/Infrastrutre/Chemistry lab/Img  (5).JPG";
import Chemistry_lab_img2 from "../../../assets/images/Infrastrutre/Chemistry lab/Img  (6).JPG";

import Physics_lab_img1 from "../../../assets/images/Infrastrutre/Physics lab/Img  (4).JPG";
import Physics_lab_img2 from "../../../assets/images/Infrastrutre/Physics lab/phy-lab-1.jpg";
import Physics_lab_img3 from "../../../assets/images/Infrastrutre/Physics lab/phy-lab-2.jpg";
import Physics_lab_img4 from "../../../assets/images/Infrastrutre/Physics lab/phy-lab-3.jpg";
import Physics_lab_img5 from "../../../assets/images/Infrastrutre/Physics lab/phy-lab-4.jpg";
import Physics_lab_img6 from "../../../assets/images/Infrastrutre/Physics lab/phy-lab-5.jpg";

import Microbiology_lab_img1 from "../../../assets/images/Infrastrutre/microbiolozy lab/Img (1).JPG";
import Microbiology_lab_img2 from "../../../assets/images/Infrastrutre/microbiolozy lab/Img (2).JPG";
import Microbiology_lab_img3 from "../../../assets/images/Infrastrutre/microbiolozy lab/Img (3).JPG";
import Microbiology_lab_img4 from "../../../assets/images/Infrastrutre/microbiolozy lab/Img (4).JPG";

import { Helmet } from "react-helmet-async";


function Laboratorys() {
  return (
   <>
    <Helmet>
        <title> Laboratories- JNCW</title>
      </Helmet>
  <div class="container mt-4 text-justify" data-aos="fade-up" data-aos-duration="2000">
  <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
    <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
    <h4 class="mb-5">Laboratories</h4>
  </div>

  <div class="container">
    <div class="panel-group" id="accordionComputerLab" role="tablist" aria-multiselectable="true">
      <div class="card mt-2">
        <div class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionComputerLab" href="#collapseComputerLab" aria-expanded="false" aria-controls="collapseComputerLab">
          <div class="card-header header-green" role="tab" id="headingComputerLab">
            <h4 class="title style text-center">Computer Lab</h4>
          </div>
        </div>
        <div id="collapseComputerLab" class="card-collapse collapse" role="tabpanel" aria-labelledby="headingComputerLab">
          <div class="card-body">
            {/* <!-- Content for Computer Lab --> */}
            <div className="row row-cols-1 row-cols-md-2 g-4">
             <div className="col">
              <img
                    src={Cs_lab_img1}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                       <div className="col"> <img
                    src={Cs_lab_img2}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                      <div className="col">  <img
                    src={Cs_lab_img3}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                             <div className="col">  <img
                    src={Cs_lab_img4}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                              <div className="col">  <img
                    src={Cs_lab_img5}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                              <div className="col">  <img
                    src={Cs_lab_img6}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                
                  </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="panel-group" id="accordionCommunicationLab" role="tablist" aria-multiselectable="true">
      <div class="card mt-2">
        <div class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionCommunicationLab" href="#collapseCommunicationLab" aria-expanded="false" aria-controls="collapseCommunicationLab">
          <div class="card-header header-green" role="tab" id="headingCommunicationLab">
            <h4 class="title text-center">Communication Lab</h4>
          </div>
        </div>
        <div id="collapseCommunicationLab" class="card-collapse collapse" role="tabpanel" aria-labelledby="headingCommunicationLab">
          <div class="card-body">
            {/* <!-- Content for Communication Lab --> */}
            <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={English_lab_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> <img
                        src={English_lab_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  <img
                        src={English_lab_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 <div className="col">  <img
                        src={English_lab_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                      </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="panel-group" id="accordionChemistryLab" role="tablist" aria-multiselectable="true">
      <div class="card mt-2">
        <div class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionChemistryLab" href="#collapseChemistryLab" aria-expanded="false" aria-controls="collapseChemistryLab">
          <div class="card-header header-green" role="tab" id="headingChemistryLab">
            <h4 class="title text-center">Chemistry Lab</h4>
          </div>
        </div>
        <div id="collapseChemistryLab" class="card-collapse collapse" role="tabpanel" aria-labelledby="headingChemistryLab">
          <div class="card-body">
            {/* <!-- Content for Chemistry Lab --> */}
            <div className="row row-cols-1 row-cols-md-2 g-4">
                  <div className="col">
                    <img
                      src={Chemistry_lab_img1}
                      alt="agronomy-lab"
                      className="m-2 rounded img-fluid"
                      data-aos="fade-in"
                      data-aos-duration="2000"
                    />
                  </div>
                  <div className="col">
                    <img
                      src={Chemistry_lab_img2}
                      alt="agronomy-lab"
                      className="m-2 rounded img-fluid"
                      data-aos="fade-in"
                      data-aos-duration="2000"
                    />
                  </div>
                </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="panel-group" id="accordionPhysicsLab" role="tablist" aria-multiselectable="true">
      <div class="card mt-2">
        <div class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionPhysicsLab" href="#collapsePhysicsLab" aria-expanded="false" aria-controls="collapsePhysicsLab">
          <div class="card-header header-green" role="tab" id="headingPhysicsLab">
            <h4 class="title text-center">Physics Lab</h4>
          </div>
        </div>
        <div id="collapsePhysicsLab" class="card-collapse collapse" role="tabpanel" aria-labelledby="headingPhysicsLab">
          <div class="card-body">
            {/* <!-- Content for Physics Lab --> */}
                
            <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={Physics_lab_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> 
                           <img
                        src={Physics_lab_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  
                          <img
                        src={Physics_lab_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 <div className="col"> 
                                  <img
                        src={Physics_lab_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                      </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="panel-group" id="accordionMicrobiologyLab" role="tablist" aria-multiselectable="true">
      <div class="card mt-2">
        <div class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionMicrobiologyLab" href="#collapseMicrobiologyLab" aria-expanded="false" aria-controls="collapseMicrobiologyLab">
          <div class="card-header header-green" role="tab" id="headingMicrobiologyLab">
            <h4 class="title text-center">Microbiology Lab</h4>
          </div>
        </div>
        <div id="collapseMicrobiologyLab" class="card-collapse collapse" role="tabpanel" aria-labelledby="headingMicrobiologyLab">
          <div class="card-body">
            {/* <!-- Content for Microbiology Lab --> */}
            <div className="row row-cols-1 row-cols-md-2 g-4">
             <div className="col">
              <img
                    src={Microbiology_lab_img1}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                       <div className="col"> <img
                    src={Microbiology_lab_img2}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>  
                      <div className="col">  <img
                    src={Microbiology_lab_img3}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                             <div className="col">  <img
                    src={Microbiology_lab_img4}
                    alt="agronomy-lab"
                    class="m-2 rounded w-100"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  /></div>
                  </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   </>
  )
}

export default Laboratorys