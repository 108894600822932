import React from 'react'
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import "./style.css";
import  NationalMathsDay_Img_1 from "../../../assets/images/gallery/2023/Mathsday/img_1.JPG";
import  NationalMathsDay_Img_2 from "../../../assets/images/gallery/2023/Mathsday/img_2.JPG";
import  NationalMathsDay_Img_4 from "../../../assets/images/gallery/2023/Mathsday/img_3.JPG";
import  NationalMathsDay_Img_3 from "../../../assets/images/gallery/2023/Mathsday/img_4.JPG";
import  NationalMathsDay_Img_6 from "../../../assets/images/gallery/2023/Mathsday/img_5.JPG";
import  NationalMathsDay_Img_5 from "../../../assets/images/gallery/2023/Mathsday/img_6.JPG";
 
import NSSCampDec_Img_1 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_1.JPG";
import NSSCampDec_Img_2 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_2.JPG";
import NSSCampDec_Img_3 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_3.JPG";
import NSSCampDec_Img_4 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_4.JPG";
import NSSCampDec_Img_5 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_5.JPG";
import NSSCampDec_Img_6 from "../../../assets/images/gallery/2023/Siddha & jncw Camp/img_6.JPG";

import  BcomIv_Img_1 from "../../../assets/images/gallery/2023/Bcom IV/img_1.JPG";
import  BcomIv_Img_2 from "../../../assets/images/gallery/2023/Bcom IV/img_2 (1).JPG";
import  BcomIv_Img_3 from "../../../assets/images/gallery/2023/Bcom IV/img_3 (1).JPG";

import TamilIv_Img_1 from "../../../assets/images/gallery/2023/Tamil Iv/img.jpg";
import TamilIv_Img_2 from "../../../assets/images/gallery/2023/Tamil Iv/img1.jpg";

import ParentsDay_1 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (1).jpeg";
import ParentsDay_2 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (10).jpeg";
import ParentsDay_3 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (11).jpeg";
import ParentsDay_4 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (12).jpeg";
import ParentsDay_5 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (13).jpeg";
import ParentsDay_6 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (14).jpeg";
import ParentsDay_7 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (2).jpeg";
import ParentsDay_8 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (3).jpeg";
import ParentsDay_9 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (4).jpeg";
import ParentsDay_10 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (5).jpeg";
import ParentsDay_11 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (6).jpeg";
import ParentsDay_12 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (7).jpeg";
import ParentsDay_13 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (8).jpeg";
import ParentsDay_14 from "../../../assets/images/gallery/2023/parentsMeeting/img_ (9).jpeg";

import OzoneDayDay_1 from "../../../assets/images/gallery/2023/Ozoneday/img_1.JPG";
import OzoneDayDay_2 from "../../../assets/images/gallery/2023/Ozoneday/img_2.JPG";
import OzoneDayDay_3 from "../../../assets/images/gallery/2023/Ozoneday/img_3.JPG";
import OzoneDayDay_4 from "../../../assets/images/gallery/2023/Ozoneday/img_4.JPG";
import OzoneDayDay_5 from "../../../assets/images/gallery/2023/Ozoneday/img_5.JPG";
import OzoneDayDay_6 from "../../../assets/images/gallery/2023/Ozoneday/img_6.JPG";
import OzoneDayDay_7 from "../../../assets/images/gallery/2023/Ozoneday/img_7.JPG";

import TamilDepart_1 from "../../../assets/images/gallery/2023/Tamildepart/img_1.JPG";
import TamilDepart_2 from "../../../assets/images/gallery/2023/Tamildepart/img_2.JPG";
import TamilDepart_3 from "../../../assets/images/gallery/2023/Tamildepart/img_3.JPG";
import TamilDepart_4 from "../../../assets/images/gallery/2023/Tamildepart/img_4.JPG";
import TamilDepart_5 from "../../../assets/images/gallery/2023/Tamildepart/img_5.JPG";
import TamilDepart_6 from "../../../assets/images/gallery/2023/Tamildepart/img_6.JPG";

import FresherDay_1 from "../../../assets/images/gallery/2023/Freshersday/img_1.JPG";
import FresherDay_2 from "../../../assets/images/gallery/2023/Freshersday/img_2.JPG";
import FresherDay_3 from "../../../assets/images/gallery/2023/Freshersday/img_3.JPG";
import FresherDay_4 from "../../../assets/images/gallery/2023/Freshersday/img_4.JPG";
import FresherDay_5 from "../../../assets/images/gallery/2023/Freshersday/img_5.JPG";

import Foodfestival_1 from "../../../assets/images/gallery/2023/foodfesitval/img_1.JPG";
import Foodfestival_2 from "../../../assets/images/gallery/2023/foodfesitval/img_2.JPG";
import Foodfestival_3 from "../../../assets/images/gallery/2023/foodfesitval/img_3.JPG";
import Foodfestival_4 from "../../../assets/images/gallery/2023/foodfesitval/img_4.JPG";
import Foodfestival_5 from "../../../assets/images/gallery/2023/foodfesitval/img_5.JPG";

const Moments2023 = () => {
  
    const NationalMathsDay =[
              {
                image: NationalMathsDay_Img_1,
                altText: "",
                caption: "",
                id: "mathsday",
              },
              {
                image: NationalMathsDay_Img_2,
                altText: "",
                caption: "",
                id: "mathsday",
              },
              {
                image: NationalMathsDay_Img_3,
                altText: "",
                caption: "",
                id: "mathsday",
              },
              {
                image: NationalMathsDay_Img_4,
                altText: "",
                caption: "",
                id: "mathsday",
              },
              {
                image: NationalMathsDay_Img_5,
                altText: "",
                caption: "",
                id: "mathsday",
              },
              {
                image: NationalMathsDay_Img_6,
                altText: "",
                caption: "",
                id: "mathsday",
              },
        
    ];
    const NSSCampDec =[
        {
            image: NSSCampDec_Img_1,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
        {
            image: NSSCampDec_Img_2,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
        {
            image: NSSCampDec_Img_3,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
        {
            image: NSSCampDec_Img_4,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
        {
            image: NSSCampDec_Img_5,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
        {
            image: NSSCampDec_Img_6,
            altText: "",
            caption: "",
            id: "nsscampdec",  
        },
    ];
    const BcomIv = [
          {
                image: BcomIv_Img_1,
                altText: "",
                caption: "",
                id: "bcomiv",  
            },
            {
                image: BcomIv_Img_2,
                altText: "",
                caption: "",
                id: "bcomiv",  
            },
        
            {
                image: BcomIv_Img_3,
                altText: "",
                caption: "",
                id: "bcomiv",  
            },
        
        
    ];
    const TamilIv = [
        {
            image: TamilIv_Img_1,
            altText: "",
            caption: "",
            id: "tamiliv",  
        },
        {
            image: TamilIv_Img_2,
            altText: "",
            caption: "",
            id: "tamiliv",  
        },
    ];
    const ParentsMeeting = [
        {
          image: ParentsDay_1,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_2,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_3,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_4,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_5,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_6,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_7,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_8,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_9,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_10,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_11,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_12,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_13,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
        {
          image: ParentsDay_14,
          altText: "Parents Meeting For ALL Department",
          caption: "Parents Meeting For ALL Department",
          id:"ParentsMeeting"
        },
      ];
      const OzoneDay =[
        {
          image: OzoneDayDay_1,
          altText: "",
          caption: "",
          id:"ozoneday"
        },
        {
          image: OzoneDayDay_2,
          altText: "",
          caption: "",
          id:"ozoneday"
        }, {
          image: OzoneDayDay_3,
          altText: "",
          caption: "",
          id:"ozoneday"
        }, {
          image: OzoneDayDay_4,
          altText: "",
          caption: "",
          id:"ozoneday"
        }, {
          image: OzoneDayDay_5,
          altText: "",
          caption: "",
          id:"ozoneday"
        }, {
          image: OzoneDayDay_6,
          altText: "",
          caption: "",
          id:"ozoneday"
        }, {
          image: OzoneDayDay_7,
          altText: "",
          caption: "",
          id:"ozoneday"
        },
      ];
      const TamilDepart =[
         {
        image:TamilDepart_1,
        altText: "",
        caption: "",
        id:"tamildepart"
      },
      {
        image:TamilDepart_2,
        altText: "",
        caption: "",
        id:"tamildepart"
      },
      {
        image:TamilDepart_3,
        altText: "",
        caption: "",
        id:"tamildepart"
      },
      {
        image:TamilDepart_4,
        altText: "",
        caption: "",
        id:"tamildepart"
      },
      {
        image:TamilDepart_5,
        altText: "",
        caption: "",
        id:"tamildepart"
      },
      {
        image:TamilDepart_6,
        altText: "",
        caption: "",
        id:"tamildepart"
      },


      ];

      const FresherDay =[
        {
          image:FresherDay_1,
          altText: "",
          caption: "",
          id:"fresherday"
        },
        {
          image:FresherDay_2,
          altText: "",
          caption: "",
          id:"fresherday"
        },
        {
          image:FresherDay_3,
          altText: "",
          caption: "",
          id:"fresherday"
        },
        {
          image:FresherDay_4,
          altText: "",
          caption: "",
          id:"fresherday"
        },
        {
          image:FresherDay_5,
          altText: "",
          caption: "",
          id:"fresherday"
        },
      ];

      const Foodfestival =[
        {
          image:Foodfestival_1,
          altText: "",
          caption: "",
          id:"foodfestival"
        },
        {
          image:Foodfestival_2,
          altText: "",
          caption: "",
          id:"foodfestival"
        },
        {
          image:Foodfestival_3,
          altText: "",
          caption: "",
          id:"foodfestival"
        },
        {
          image:Foodfestival_4,
          altText: "",
          caption: "",
          id:"foodfestival"
        },
        {
          image:Foodfestival_5,
          altText: "",
          caption: "",
          id:"foodfestival"
        },
      ];
   
  return (
   <>
      <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>
    <div className="container">
      
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5"> JNCW - 2023 Moments </h4>
            </div>
      <EventContainer
            data={NationalMathsDay}
            header="National Mathematics Day 2023"
            id="mathsday"
            />
             <EventContainer
            data={NSSCampDec}
            header="NSS Camp - Dec 2023"
            id="nsscampdec"
            />
             <EventContainer 
             data={BcomIv}
             header="Industrial Visits  Department Of B.Com Vist to  Salem steel plant"
             id="bcomiv"
             />
                 <EventContainer
            data={TamilIv}
            header="Industrial Visits - தமிழ் துறை மாணவிகள் மாமல்லபுரம் வரலாற்று சிலை பார்க்கும் தருணம் (28.09.2023 ) "
            id="tamiliv"
            />
              <EventContainer
          data={ParentsMeeting}
          header="Parents Meeting -2023"
          id="ParentsMeeting"
        />
            
      <EventContainer
            data={OzoneDay}
            header="Ozone Day - 2023"
            id="ozoneday"
            />
            <EventContainer
            data={TamilDepart}
            header="Tamil Department Functions"
            id="tamildepart"
            />
            <EventContainer
            data={Foodfestival}
            header="Food Festival 2023"
            id="foodfestival"
            />

<EventContainer
            data={FresherDay}
            header="Freshers Day Celebrations"
            id="fresherday"
            />
          
          </div>

            
   </>


  );
};

export default Moments2023;