import React from 'react'
import StudentCardList from "./studentcardlist";
import   JSHIFANA from "../../../../assets/images/marquee image/J.SHIFANA.jpg"; 
import HMUBEENA from "../../../../assets/images/marquee image/mubeena.jpg";
import ARTHI  from "../../../../assets/images/marquee image/A. ARTHI.jpg";
import VSINDHU from "../../../../assets/images/marquee image/V.SINDHU.jpg";
import MKIRUBA from "../../../../assets/images/marquee image/M.KIRUBA.jpg";
import EVIYAYA from "../../../../assets/images/marquee image/E.VIYAYA.jpg";
import JEMIMAPARVEEN from "../../../../assets/images/marquee image/M R JEMIMA PARVEEN.jpg";
import HARINI from "../../../../assets/images/marquee image/S. HARINI.png";
import SHIFANA  from "../../../../assets/images/marquee image/A. SHIFANA.jpg";
import THAMARAIVALLI from "../../../../assets/images/marquee image/A. THAMARAI VALLI.jpg";
import SABITHA  from "../../../../assets/images/marquee image/E. SABITHA.jpg";

function ranklist() {
    const studentData = [
   
        {
          id: 1,
          name: 'J.SHIFANA PARVEEN  8.348  ',
          imageUrl: JSHIFANA,
          degree: "B.Com.,BM ",
          job: "RANK-I (GOLD MEDALIST) ",
        },
        {
          id: 2,
          name: 'H.MUBEENA  8.303 ',
          imageUrl:  HMUBEENA ,
          degree: "B.Com.,BM",
          job: " RANK-II",
        },
          
        {
          id: 3,
          name: 'A. ARTHI  9.545  ',
          imageUrl: ARTHI ,
          degree: "B.Sc.,Maths",
          job: " RANK-II",
        },

        {
            id: 4,
            name: 'M.KIRUBA  8.300  ',
            imageUrl:MKIRUBA,
            degree: "B.Com.,BM",
            job: "RANK-III",
          },
      
    
        {
          id: 5,
          name: 'V.SINDHU  9.403 ',
          imageUrl: VSINDHU,
          degree: "B.Sc.,Chemistry",
          job: "  RANK-III",
        },
    
        {
          id: 6,
          name: 'E.VIYAYA  8.077  ',
          imageUrl: EVIYAYA ,
          degree: "B.Com.,BM",
          job: " RANK-IV",
        },
    
        {
          id: 7,
          name: 'S. HARINI  9.534  ',
          imageUrl:HARINI,
          degree: "B.Sc.,Maths",
          job: "RANK-V",
        },
        {
            id: 8,
            name: 'M R JEMIMA PARVEEN  8.076    ',
            imageUrl: JEMIMAPARVEEN,
            degree: "B.Com.,BM",
            job: "RANK-V",
          },
          
        
        {
          id: 9,
          name: 'A. SHIFANA  8.042  ',
          imageUrl: SHIFANA,
          degree: "B.Com.,BM",
          job: " RANK-VI",
        },
    
        {
          id: 10,
          name: 'A. THAMARAI VALLI  8.385  ',
          imageUrl: THAMARAIVALLI,
          degree: "B.Com.,FA",
          job: " RANK-VIII",
        },
        {
          id: 11,
          name: 'E. SABITHA  9.226 ',
          imageUrl: SABITHA,
          degree: "B.Sc.,Chemistry",
          job: " RANK-X",
        },
    
    
       
    
      ];
  return (
   <>
    <div className='container'>
    <div className='row'><StudentCardList studentData={studentData} /></div>
    
    </div>
   </>
  )
}

export default ranklist;