import React from "react";
import "./style.css";

function Microbiology() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Microbiology
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            <ul>
              {" "}
              <li>
                Our vision is to produce highly qualified and competent students
                in all areas of the Microbiology.
              </li>
              <li>
                Providing the timely and accessible information’s on current and
                emerging trends in Microbiology
              </li>
              <li>
                The Department of Microbiology in an integrated team of
                educators providing leading education and innovative research
                expertise in all field of Microbiology.
              </li>
              <li>
                To recognizes the strength and ability of the students and work
                together to achieve our common goal.
              </li>
              <li>
                To be a world-class hub for interdisciplinary education and
                research programs in microbial pathogenesis, infectious
                diseases, and immunology to improve human health.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                The Mission of Department is to promote good quality education
                and inspiring training in different disciplines of Microbiology.
              </li>
              <li>
                To create an innovative, creative study environment to the
                students.
              </li>
              <li>
                To train the student excels in both practical and theoretical
                aspects of Microbiology.
              </li>
              <li>
                Motivate and guide the students to develop new techniques in
                Microbiology
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                Goal of Our Department
              </h5>
              <b>Education : </b>
            </div>
            To provide outstanding education in microbiology and immunology to
            undergraduate, graduate, and professional students in health
            sciences; To train a diverse group of scientists and clinicians to
            solve societal challenges in microbial infection and immunity by
            using cutting-edge approaches
            <div>
              <b>Research : </b>
            </div>
            To expand interdisciplinary and multidisciplinary collaborations
            that are recognized internationally for their innovative impacts on
            microbiology and immunology; To benefit the world by disseminating
            impactful knowledge and translating scientific discoveries into
            diagnostics, therapeutics, and vaccines against human diseases
            <div>
              <b>Service : </b>
            </div>
            To contribute to the mission of the University, the College, and
            professional organizations; To engage in outreach to enhance
            community understanding of the importance of microbiology and
            immunology
            <div>
              <b>Career : </b>
            </div>
            To attract and retain a diverse, talented and dedicated group of
            faculty and staff to advance their careers through collaborative.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Microbiology;
