// NewsItem.js

import React from 'react';
import './style.css'; // Import the CSS file for NewsItem

const NewsItem = ({ title, description, date }) => {
  return (
    <div className="news-item">
    
      <div className="marquee-container">
        <marquee direction="up">  <h3>{title}</h3>
        <br/>
        {description}
        <span>{date}</span>
        </marquee>
      </div>
      
    </div>
  );
};

export default NewsItem;
