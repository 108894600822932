import React from 'react';
import EventContainer from "../../../layouts/eventcontainer/eventContainer";
import EvenSemster2023 from "../../../../assets/pdf/ACADEMIC CALENDAR EVEN SEMESTER 2023-2024 (1).pdf";
import OddSemster2024  from "../../../../assets/pdf/ACADEMIC CALENDAR ODD SEMESTER 2023-2024.pdf";


function acdemicCalendar() {

    const EvenSemster=[
        {
          file:EvenSemster2023 , description:" "
        }
       ];
       const OddSemster=[
        {
          file:OddSemster2024, description:" "
        }
       ];

  return (
    <div className="container">
      {/* <div
        className="title-text text-center mt-5"
        style={{ transform: "translate(3px, 3px)", color: "#24c202" }}
      >
        <h4> Academic Calendar</h4>
      </div>
      <div
        className="title-text text-center mt-5"
        style={{ transform: "translate(3px, 3px)", color: "#24c202" }}
      >
        <h4>2023-2024</h4>
      </div> */}
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title bg-white text-center text-primary px-3">Academic Calendar </h5>
        <h3 className="mb-5"> 2023-2024 </h3>
      </div>

      <EventContainer
        isFile
        data={OddSemster}
        header="ACADEMIC CALENDAR 2023-2024 ( ODD Semester )"
        id="oddsemester"
      />

      <EventContainer
        isFile
        data={EvenSemster}
        header="ACADEMIC CALENDAR 2023-2024 (Even Semester )"
        id="evensemester"
      />




    </div>
  )
}

export default acdemicCalendar