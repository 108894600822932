import "./styles.scss";
import ClickImg from "../../../assets/images/home/click.gif";
export const AdmissionMarquee = () => {
  return (
    <div className="marquee">
      <ul>
        <li>
          <strong className="text-warning"></strong>
          &nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; JAWAHARLAL NEHRU
          COLLEGE FOR WOMEN, Ulundurpet, Kallakurichi (Dist.), TamilNadu –
          606104, For Addmission Open 2024-2025
          <a
            target="_blank"
            href=" https://docs.google.com/forms/d/e/1FAIpQLSdd8pOpDYoSrlfvTbL-y8x7swoVqKoIZ4xTiz-hTlOmjxvTZA/viewform"
            rel="noreferrer"
          >
            {" "}
            <img src={ClickImg} alt="jsaagri" />
          </a>{" "}
          &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; Contact –
          <a href="tel: +91 9361909060" className="contact">
            {" "}
            Tele : +91 9361909060
          </a>
          ,
          <a href="tel:+91 9361909070" className="contact">
            {" "}
            +91 9361909070
          </a>
          ,
          <a href="tel:+91 7305057756" className="contact">
            {" "}
            +91 7305057756
          </a>
          ,
          <a href="tel:+91 9786646795 " className="contact">
            {" "}
            +91 9786646795
          </a>
          ,
          <a href="tel:+91 9566223456 " className="contact">
            {" "}
            +91 9566223456
          </a>
          ,
          <a href="email :jcwomen@gmail.com" className="contact">
            Email : jcwomen@gmail.com
          </a>
        </li>
      </ul>
    </div>
  );
};
