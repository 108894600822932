import React from 'react'
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import "./style.css";
import  ScienceDay_Img_1 from "../../../assets/images/gallery/2022/Science/Img  (1).jpg";
import  ScienceDay_Img_2 from "../../../assets/images/gallery/2022/Science/Img  (2).jpg";
import  ScienceDay_Img_3 from "../../../assets/images/gallery/2022/Science/Img  (3).jpg";
import  ScienceDay_Img_4 from "../../../assets/images/gallery/2022/Science/Img  (4).jpg";
import  ScienceDay_Img_5 from "../../../assets/images/gallery/2022/Science/Img  (5).jpg";
import  ScienceDay_Img_6 from "../../../assets/images/gallery/2022/Science/Img  (6).jpg";

import  ConsumerRightsDay_Img_1 from "../../../assets/images/gallery/2022/Consumer day/Img (1).jpeg";
import  ConsumerRightsDay_Img_2 from "../../../assets/images/gallery/2022/Consumer day/Img (2).jpeg";
import  ConsumerRightsDay_Img_3 from "../../../assets/images/gallery/2022/Consumer day/Img (3).jpeg";
import  ConsumerRightsDay_Img_4 from "../../../assets/images/gallery/2022/Consumer day/Img (4).jpeg";
import  ConsumerRightsDay_Img_5 from "../../../assets/images/gallery/2022/Consumer day/Img (5).jpeg";
import  ConsumerRightsDay_Img_6 from "../../../assets/images/gallery/2022/Consumer day/Img (6).jpeg";
import  ConsumerRightsDay_Img_7 from "../../../assets/images/gallery/2022/Consumer day/Img (7).jpeg";
import  ConsumerRightsDay_Img_8 from "../../../assets/images/gallery/2022/Consumer day/Img (8).jpeg";
import  ConsumerRightsDay_Img_9 from "../../../assets/images/gallery/2022/Consumer day/Img (9).jpeg";
import  ConsumerRightsDay_Img_10 from "../../../assets/images/gallery/2022/Consumer day/Img (10).jpeg";
import  ConsumerRightsDay_Img_11 from "../../../assets/images/gallery/2022/Consumer day/Img (11).jpeg";
import  ConsumerRightsDay_Img_12 from "../../../assets/images/gallery/2022/Consumer day/Img (12).jpeg";
import  ConsumerRightsDay_Img_13 from "../../../assets/images/gallery/2022/Consumer day/Img (13).jpeg";
import  ConsumerRightsDay_Img_14 from "../../../assets/images/gallery/2022/Consumer day/Img (14).jpeg";

import WomensDay_Img_1 from "../../../assets/images/gallery/2022/Womensday/Img  (1).jpg";
import WomensDay_Img_2 from "../../../assets/images/gallery/2022/Womensday/Img  (2).jpg";
import WomensDay_Img_3 from "../../../assets/images/gallery/2022/Womensday/Img  (3).jpg";
import WomensDay_Img_4 from "../../../assets/images/gallery/2022/Womensday/Img  (4).jpg";
import WomensDay_Img_5 from "../../../assets/images/gallery/2022/Womensday/Img  (5).jpg";
import WomensDay_Img_6 from "../../../assets/images/gallery/2022/Womensday/Img  (6).jpg";
import WomensDay_Img_7 from "../../../assets/images/gallery/2022/Womensday/Img  (7).jpg";
import WomensDay_Img_8 from "../../../assets/images/gallery/2022/Womensday/Img  (8).jpg";
import WomensDay_Img_9 from "../../../assets/images/gallery/2022/Womensday/Img  (9).jpg";
import WomensDay_Img_10 from "../../../assets/images/gallery/2022/Womensday/Img  (10).jpg";
import WomensDay_Img_11 from "../../../assets/images/gallery/2022/Womensday/Img  (11).jpg";
import WomensDay_Img_12 from "../../../assets/images/gallery/2022/Womensday/Img  (12).jpg";
import WomensDay_Img_13 from "../../../assets/images/gallery/2022/Womensday/Img  (13).jpg";
import WomensDay_Img_14 from "../../../assets/images/gallery/2022/Womensday/Img  (14).jpg";

import BridalMakeup_1 from "../../../assets/images/gallery/2022/bridal makeup/Bridal_Makeup(1).jpg";
import BridalMakeup_2 from "../../../assets/images/gallery/2022/bridal makeup/Bridal_Makeup(2).jpg";
import BridalMakeup_3 from "../../../assets/images/gallery/2022/bridal makeup/Bridal_Makeup(4).jpg";
import BridalMakeup_4 from "../../../assets/images/gallery/2022/bridal makeup/Bridal_Makeup(5).jpg";
import BridalMakeup_5 from "../../../assets/images/gallery/2022/bridal makeup/Bridal_Makeup(7).jpg";

import CraftWork_1 from "../../../assets/images/gallery/2022/craft work/Img (1).jpg";
import CraftWork_2 from "../../../assets/images/gallery/2022/craft work/Img (2).jpg";
import CraftWork_3 from "../../../assets/images/gallery/2022/craft work/Img (3).jpg";
import CraftWork_4 from "../../../assets/images/gallery/2022/craft work/Img (4).jpg";
import CraftWork_5 from "../../../assets/images/gallery/2022/craft work/Img (5).jpg";
import CraftWork_6 from "../../../assets/images/gallery/2022/craft work/Img (6).jpg";
import CraftWork_7 from "../../../assets/images/gallery/2022/craft work/Img (7).jpg";
import CraftWork_8 from "../../../assets/images/gallery/2022/craft work/Img (8).jpg";
import CraftWork_9 from "../../../assets/images/gallery/2022/craft work/Img (9).jpg";
import CraftWork_10 from "../../../assets/images/gallery/2022/craft work/Img (10).jpg";
import CraftWork_11 from "../../../assets/images/gallery/2022/craft work/Img (11).jpg";

import DrawingContest_1 from "../../../assets/images/gallery/2022/drawing contest/Img (1).jpg";
import DrawingContest_2 from "../../../assets/images/gallery/2022/drawing contest/Img (2).jpg";
import DrawingContest_3 from "../../../assets/images/gallery/2022/drawing contest/Img (3).jpg";
import DrawingContest_4 from "../../../assets/images/gallery/2022/drawing contest/Img (4).jpg";
import DrawingContest_5 from "../../../assets/images/gallery/2022/drawing contest/Img (5).jpg";
import DrawingContest_6 from "../../../assets/images/gallery/2022/drawing contest/Img (6).jpg";
import DrawingContest_7 from "../../../assets/images/gallery/2022/drawing contest/Img (7).jpg";
import DrawingContest_8 from "../../../assets/images/gallery/2022/drawing contest/Img (8).jpg";
import DrawingContest_9 from "../../../assets/images/gallery/2022/drawing contest/Img (9).jpg";
import DrawingContest_10 from "../../../assets/images/gallery/2022/drawing contest/Img (10).jpg";
import DrawingContest_11 from "../../../assets/images/gallery/2022/drawing contest/Img (11).jpg";
import DrawingContest_12 from "../../../assets/images/gallery/2022/drawing contest/Img (12).jpg";
import DrawingContest_13 from "../../../assets/images/gallery/2022/drawing contest/Img (13).jpg";
import DrawingContest_14 from "../../../assets/images/gallery/2022/drawing contest/Img (15).jpg";
import DrawingContest_15 from "../../../assets/images/gallery/2022/drawing contest/Img (16).jpg";
import DrawingContest_16 from "../../../assets/images/gallery/2022/drawing contest/Img (17).jpg";
import DrawingContest_17 from "../../../assets/images/gallery/2022/drawing contest/Img (18).jpg";
import DrawingContest_18 from "../../../assets/images/gallery/2022/drawing contest/Img (19).jpg";
import DrawingContest_19 from "../../../assets/images/gallery/2022/drawing contest/Img (20).jpg";
import DrawingContest_20 from "../../../assets/images/gallery/2022/drawing contest/Img (21).jpg";
import DrawingContest_21 from "../../../assets/images/gallery/2022/drawing contest/Img (22).jpg";
import DrawingContest_22 from "../../../assets/images/gallery/2022/drawing contest/Img (23).jpg";
import DrawingContest_23 from "../../../assets/images/gallery/2022/drawing contest/Img (24).jpg";
import DrawingContest_24 from "../../../assets/images/gallery/2022/drawing contest/Img (25).jpg";
import DrawingContest_25 from "../../../assets/images/gallery/2022/drawing contest/Img (26).jpg";
import DrawingContest_26 from "../../../assets/images/gallery/2022/drawing contest/Img (27).jpg";
import DrawingContest_27 from "../../../assets/images/gallery/2022/drawing contest/Img (28).jpg";
import DrawingContest_28 from "../../../assets/images/gallery/2022/drawing contest/Img (29).jpg";
import DrawingContest_29 from "../../../assets/images/gallery/2022/drawing contest/Img (30).jpg";
import DrawingContest_30 from "../../../assets/images/gallery/2022/drawing contest/Img (31).jpg";
import DrawingContest_31 from "../../../assets/images/gallery/2022/drawing contest/Img (32).jpg";
import DrawingContest_32 from "../../../assets/images/gallery/2022/drawing contest/Img (33).jpg";
import DrawingContest_33 from "../../../assets/images/gallery/2022/drawing contest/Img (34).jpg";
import DrawingContest_34 from "../../../assets/images/gallery/2022/drawing contest/Img (35).jpg";
import DrawingContest_35 from "../../../assets/images/gallery/2022/drawing contest/Img (36).jpg";
import DrawingContest_36 from "../../../assets/images/gallery/2022/drawing contest/Img (37).jpg";
import DrawingContest_37 from "../../../assets/images/gallery/2022/drawing contest/Img (38).jpg";
import DrawingContest_38 from "../../../assets/images/gallery/2022/drawing contest/Img (39).jpg";
import DrawingContest_39 from "../../../assets/images/gallery/2022/drawing contest/Img (40).jpg";
import DrawingContest_40 from "../../../assets/images/gallery/2022/drawing contest/Img (41).jpg";
import DrawingContest_41 from "../../../assets/images/gallery/2022/drawing contest/Img (42).jpg";
import DrawingContest_42 from "../../../assets/images/gallery/2022/drawing contest/Img (14).jpg";

import RangoliContest_1 from "../../../assets/images/gallery/2022/Rongoli/img (1).jpg";
import RangoliContest_2 from "../../../assets/images/gallery/2022/Rongoli/img (2).jpg";
import RangoliContest_3 from "../../../assets/images/gallery/2022/Rongoli/img (3).jpg";
import RangoliContest_4 from "../../../assets/images/gallery/2022/Rongoli/img (4).jpg";
import RangoliContest_5 from "../../../assets/images/gallery/2022/Rongoli/img (5).jpg";
import RangoliContest_6 from "../../../assets/images/gallery/2022/Rongoli/img (6).jpg";
import RangoliContest_7 from "../../../assets/images/gallery/2022/Rongoli/img (7).jpg";
import RangoliContest_8 from "../../../assets/images/gallery/2022/Rongoli/img (8).jpg";
import RangoliContest_9 from "../../../assets/images/gallery/2022/Rongoli/img (8).jpg";
import RangoliContest_10 from "../../../assets/images/gallery/2022/Rongoli/img (10).jpg";
import RangoliContest_11 from "../../../assets/images/gallery/2022/Rongoli/img (11).jpg";
import RangoliContest_12 from "../../../assets/images/gallery/2022/Rongoli/img (12).jpg";
import RangoliContest_13 from "../../../assets/images/gallery/2022/Rongoli/img (13).jpg";
import RangoliContest_14 from "../../../assets/images/gallery/2022/Rongoli/img (14).jpg";
import RangoliContest_15 from "../../../assets/images/gallery/2022/Rongoli/img (15).jpg";
import RangoliContest_16 from "../../../assets/images/gallery/2022/Rongoli/img (16).jpg";
import RangoliContest_17 from "../../../assets/images/gallery/2022/Rongoli/img (17).jpg";
import RangoliContest_18 from "../../../assets/images/gallery/2022/Rongoli/img (18).jpg";
import RangoliContest_19 from "../../../assets/images/gallery/2022/Rongoli/img (19).jpg";
import RangoliContest_20 from "../../../assets/images/gallery/2022/Rongoli/img (20).jpg";
import RangoliContest_21 from "../../../assets/images/gallery/2022/Rongoli/img (21).jpg";
import RangoliContest_22 from "../../../assets/images/gallery/2022/Rongoli/img (22).jpg";
import RangoliContest_23 from "../../../assets/images/gallery/2022/Rongoli/img (23).jpg";
import RangoliContest_24 from "../../../assets/images/gallery/2022/Rongoli/img (24).jpg";
import RangoliContest_25 from "../../../assets/images/gallery/2022/Rongoli/img (25).jpg";
import RangoliContest_26 from "../../../assets/images/gallery/2022/Rongoli/img (26).jpg";
import RangoliContest_27 from "../../../assets/images/gallery/2022/Rongoli/img (27).jpg";
import RangoliContest_28 from "../../../assets/images/gallery/2022/Rongoli/img (28).jpg";




const Moments2022 = () => {
  
    
      const ScienceDay=[
        {
          image: ScienceDay_Img_1,
          altText: "",
          caption: "",
          id: "scienceday",
        },
        {
            image: ScienceDay_Img_3,
            altText: "",
            caption: "",
            id: "scienceday",
          },
          {
            image: ScienceDay_Img_2,
            altText: "",
            caption: "",
            id: "scienceday",
          },
          {
            image: ScienceDay_Img_4,
            altText: "",
            caption: "",
            id: "scienceday",
          },
          {
            image: ScienceDay_Img_5,
            altText: "",
            caption: "",
            id: "scienceday",
          },
          {
            image: ScienceDay_Img_6,
            altText: "",
            caption: "",
            id: "scienceday",
          },
      ];
      const ConsumerRightsDay=[
        {
            image:  ConsumerRightsDay_Img_1,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_2,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_3,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_4,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_6,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_5,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_7,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_8,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          {
            image:  ConsumerRightsDay_Img_9,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_10,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_11,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_12,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_13,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          {
            image:  ConsumerRightsDay_Img_14,
            altText: "",
            caption: "",
            id: "consumerrightsday",
          },
          
          
      ];
      const WomensDay =[
          {
            image:  WomensDay_Img_1,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_2,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_3,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_4,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_5,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_6,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_7,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_8,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_9,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_10,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_11,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_12,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_13,
            altText: "",
            caption: "",
            id: "womensday",
          },
          {
            image:  WomensDay_Img_14,
            altText: "",
            caption: "",
            id: "womensday",
          },

      ];
      const BridalMakeup =[
        {
          image:BridalMakeup_1,
          altText: "",
          caption: "",
          id:"bridalmakeup"
        },
        {
          image:BridalMakeup_2,
          altText: "",
          caption: "",
          id:"bridalmakeup"
        },
        {
          image:BridalMakeup_3,
          altText: "",
          caption: "",
          id:"bridalmakeup"
        },  {
          image:BridalMakeup_4,
          altText: "",
          caption: "",
          id:"bridalmakeup"
        },  {
          image:BridalMakeup_5,
          altText: "",
          caption: "",
          id:"bridalmakeup"
        },
      ];
      const CraftWork = [
          {
          image:CraftWork_1,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_2,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_3,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_4,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_5,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_6,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_7,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_8,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_9,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_10,
          altText: "",
          caption: "",
          id:"craftwork",
          },
            {
          image:CraftWork_11,
          altText: "",
          caption: "",
          id:"craftwork",
          },
      ];
      const DrawingContest =[
          {
          image:DrawingContest_1,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },
             {
          image:DrawingContest_2,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },
             {
          image:DrawingContest_3,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_4,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_5,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_6,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_7,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_8,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_9,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_10,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_12,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_11,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_13,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_14,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_15,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_16,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_17,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_18,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_19,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_20,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_21,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_22,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_23,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_24,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_25,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_26,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_27,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_28,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_29,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_30,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_31,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_32,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_33,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_34,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_35,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_36,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_37,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_38,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_39,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_40,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_41,
          altText: "",
          caption: "",
          id:"drawingcontest",
          },   {
          image:DrawingContest_42,
          altText: "",
          caption: "",
          id:"drawingcontest",
          }, 
      ];
      const RangoliContest =[
        {
          image:RangoliContest_1,
          altText: "",
          caption: "",
          id:"rangolicontest",
          },
          {
          image:RangoliContest_2,
          altText: "",
          caption: "",
          id:"rangolicontest",
          },
          {
            image:RangoliContest_3,
            altText: "",
            caption: "",
            id:"rangolicontest",
            },
            {
              image:RangoliContest_4,
              altText: "",
              caption: "",
              id:"rangolicontest",
              },
              {
                image:RangoliContest_5,
                altText: "",
                caption: "",
                id:"rangolicontest",
                },
                {
                  image:RangoliContest_6,
                  altText: "",
                  caption: "",
                  id:"rangolicontest",
                  },
                  {
                    image:RangoliContest_7,
                    altText: "",
                    caption: "",
                    id:"rangolicontest",
                    },
                    {
                      image:RangoliContest_8,
                      altText: "",
                      caption: "",
                      id:"rangolicontest",
                      },
                      {
                        image:RangoliContest_9,
                        altText: "",
                        caption: "",
                        id:"rangolicontest",
                        },
                        {
                          image:RangoliContest_10,
                          altText: "",
                          caption: "",
                          id:"rangolicontest",
                          },
                          {
                            image:RangoliContest_11,
                            altText: "",
                            caption: "",
                            id:"rangolicontest",
                            },
                           {
                              image:RangoliContest_12,
                              altText: "",
                              caption: "",
                              id:"rangolicontest",
                              },
                              {
                                image:RangoliContest_13,
                                altText: "",
                                caption: "",
                                id:"rangolicontest",
                                },
                                {
                                  image:RangoliContest_14,
                                  altText: "",
                                  caption: "",
                                  id:"rangolicontest",
                                  },
                                  {
                                    image:RangoliContest_15,
                                    altText: "",
                                    caption: "",
                                    id:"rangolicontest",
                                    },
                                    {
                                      image:RangoliContest_16,
                                      altText: "",
                                      caption: "",
                                      id:"rangolicontest",
                                      },

                                       {
          image:RangoliContest_17,
          altText: "",
          caption: "",
          id:"rangolicontest",
          },
         {
            image:RangoliContest_18,
            altText: "",
            caption: "",
            id:"rangolicontest",
            },
            {
              image:RangoliContest_19,
              altText: "",
              caption: "",
              id:"rangolicontest",
              },
              {
                image:RangoliContest_20,
                altText: "",
                caption: "",
                id:"rangolicontest",
                },
                {
                  image:RangoliContest_21,
                  altText: "",
                  caption: "",
                  id:"rangolicontest",
                  },
                  {
                    image:RangoliContest_22,
                    altText: "",
                    caption: "",
                    id:"rangolicontest",
                    },
                    {
                      image:RangoliContest_23,
                      altText: "",
                      caption: "",
                      id:"rangolicontest",
                      },
                    {
                        image:RangoliContest_24,
                        altText: "",
                        caption: "",
                        id:"rangolicontest",
                        },
                        {
                          image:RangoliContest_25,
                          altText: "",
                          caption: "",
                          id:"rangolicontest",
                          },
                          {
                            image:RangoliContest_26,
                            altText: "",
                            caption: "",
                            id:"rangolicontest",
                            },
                            {
                              image:RangoliContest_27,
                              altText: "",
                              caption: "",
                              id:"rangolicontest",
                              },
                              {
                                image:RangoliContest_28,
                                altText: "",
                                caption: "",
                                id:"rangolicontest",
                                },
          
      ];

  return (
   <>
     <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

    <div className="container">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5">JNCW-2022 Moments</h4>
            </div>
      <EventContainer
            data={ScienceDay}
            header="Science Day Celebrations-1"
            id="scienceday"
            />
             <EventContainer 
             data={ConsumerRightsDay}
             header='Consumer Rights Day Celebrations '
             id="consumerrightsday"
             />
                 <EventContainer
            data={WomensDay}
            header="Women's Day Celebrations"
            id="womensday"
            />
            
      <EventContainer
            data={BridalMakeup}
            header="Bridal Makeup"
            id="bridalmakeup"
            />
            <EventContainer
            data={CraftWork}
            header="Craft Work "
            id="craftwork"
            />
             <EventContainer
            data={DrawingContest}
            header="Drawing Contest"
            id="drawingcontest"
            />
             <EventContainer
            data={RangoliContest}
            header="Rangoli Contest"
            id="rangolicontest"
            />
          
          </div>

            
   </>


  );
};

export default Moments2022;