import React from 'react'
import  HomeBanner from "./banner/banner";
import Welcome from './welcome';
import Event from './welcome/event';
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <>
     <Helmet>
        <title>Home-JNCW </title>
      </Helmet>
         <div>
    
         <HomeBanner /> 
         <Welcome />
         <br/>
         <Event/>
         </div>
         
    </>
  )
}

export default Home;