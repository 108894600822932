import React from 'react'
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import "./style.css";
import Graduation_1 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (1).JPG";
import Graduation_2 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (2).jpg";
import Graduation_3 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (3).jpg";
import Graduation_4 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (4).jpg";
import Graduation_5 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (5).jpg";
import Graduation_6 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (6).jpg";
import Graduation_7 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (7).jpg";
import Graduation_8 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (8).jpg";
import Graduation_9 from "../../../assets/images/gallery/2019/2 nd inugrations/Img (9).jpg";



const Moments2019 = () => {
   const Graduation =[
    {
        
            image:Graduation_2,
            altText: "",
            caption: "",
            id:"graduation",
        
    },
    {
        
        image:Graduation_3,
        altText: "",
        caption: "",
        id:"graduation",
    
},
{
        
    image:Graduation_4,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_5,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_1,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_6,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_7,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_8,
    altText: "",
    caption: "",
    id:"graduation",

},
{
        
    image:Graduation_9,
    altText: "",
    caption: "",
    id:"graduation",

},
   ];
   
  return (
   <>
     <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

    <div className="container">
           <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5">JNCW-2019 Moments</h4>
            </div>
      <EventContainer
            data={Graduation}
            header="2'nd Graduation  Day"
            id="graduation"
            />
         
          
          </div>

            
   </>


  );
};

export default Moments2019;