import React from "react";
import Vision_img from "../../../../assets/images/about/ourvisiom/img (1).png";
import Mission_img from "../../../../assets/images/about/ourmision/img (2).png";
import { Helmet } from "react-helmet-async";
import "./styles.scss";

function index() {
  return (
    <>
      {/* <Helmet>
        <title>
          Vision Mission - JSA College of Agriculture and Technology
        </title>
      </Helmet> */}

     
      <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minheight: "400px"}}>
                    <div class="position-relative h-100">
                        <img class="img-fluid position-absolute w-100 h-100" src={Vision_img}alt="" style={{objectfit: 'cover'}}/>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h5 class="section-title bg-white text-start pe-3" style={{color :"#5f0f40"}}> Vision</h5>
                    <h1 class="mb-4 text-dark">Welcome to JNCW</h1>
                    <p class="mb-4"> To provide quality education that gives knowledge to serve the
                society. This education should be such that the student should
                be motivated to pursue higher studies. All such kinds of
                learning should be for the betterment of the students. Education
                is the only thing that makes the student a better humane in the
                society, who excels not only in academics but would understand
                the finer aspects of life. Such kind of teaching and learning
                community develops the education of rural students. Enrich to
                build confidence and enhance opportunities to succeed and should
                work together effectively to achieve the determined goals{" "}</p>
                  
                                </div>
            </div>
        </div>
    </div>
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minheight: "400px"}}>
                    <div class="position-relative h-100">
                        <img class="img-fluid position-absolute w-100 h-100" src={Mission_img}alt="" style={{objectfit: 'cover'}}/>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    {/* <h5 class="section-title bg-white text-start pe-3" style={{color :"#5f0f40"}}> V</h5> */}
                    <h1 class="mb-4 text-dark">Mission</h1>
                    <p class="mb-4">  Jawaharlal Nehru College for Women aims to develop every
                  individual to meet global challenges. And we mould the
                  students to go out as conscious citizens with concern. 
                  </p>
                  <p>To
                  provide skill based, technical, innovative educational
                  environment for creating global competencies. Facilitate
                  students to develop professional and life skills to enhance
                  the employability. Bestow leadership qualities to brave new
                  challenges. Practice the importance of human and ethical
                  values.</p>
                  
                                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default index;
