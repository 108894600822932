import "./eventContainer.scss";
const EventContainer = (props) => {
  return (
    <>
      <div className="card mt-4 rounded event-card" data-aos="fade-in" data-aos-duration="1000">
      <a
  className="collapsed"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  href={"#header_" + props.id}
  aria-expanded="false"
  aria-controls="collapse1st"
  style={{ textDecoration: 'none' }}
>
    <div className="card-header rounded" role="tab" id="headingOne">
      <h5
        className="card-title"
        style={{ transform: "translate(3px, 3px)", color: "white", textDecoration: "none" }}
      >
        {props.header}
      </h5>
    </div>
  </a>
  <div
    id={"header_" + props.id}
    className={
      props.isShow
        ? "card-collapse collapse show in"
        : "card-collapse collapse "
    }
    role="tabpanel"
    aria-labelledby="headingOne"
  >
    {/* If attach Videos */}
    {props.isVideo && (
      <>
        <div className="card-body justify-content-center">
          <div className="row text-center justify-content-center">
            {props.data.map((item, index) => (
              <>
                {item.description && (
                  <div className="container mt-4">
                    <h6 className="title-text text-center ">
                      {item.description}
                    </h6>
                  </div>
                )}
                <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                  <iframe
                    width="100%"
                    height="315"
                    src={item.url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    )}
    {/* If attach file */}
    {props.isFile ? (
      <>
        <div className="card-body justify-content-center">
          <div className="row text-center justify-content-center">
            {props.data.map((item, index) => (
              <>
                {item.description && (
                  <div className="container mt-4">
                    <h6 className="title-text text-center ">
                      {item.description}
                    </h6>
                  </div>
                )}
                <div
                  className="col-lg-10 col-md-10 col-sm-12"
                  key={index}
                >
                  <embed
                    src={item.file}
                    type="application/pdf"
                    width="100%"
                    height="700px"
                    // style={{maxHeight:"700px"}}
                  ></embed>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="card-footer border-0">
          {props.data.map((item, index) => (
            <>
              {item.description && (
                <p>{index === 0 ? item.description : null}</p>
              )}
            </>
          ))}
        </div>
      </>
    ) : (
      <>
        {/* If attach Images */}

        <div className="card-body justify-content-center">
          <div className="row text-center justify-content-center">
            {props.hasChildren
              ? props.data.map((item, index) => (
                  <EventContainer
                    data={item}
                    header={item[0].header}
                    id={item[0].id}
                    caption={item[0].caption}
                                     />
                ))
              : props.data.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-6 p-2"
                    key={index}
                  >
                    <a
                      href={item.image}
                      target="_blank"
                      rel="noreferrer"
                      className="MagicThumb"
                      id="hello"
                      data-options="expandSpeed:2000; gallerySpeed:2000"
                    >
                      <img
                        src={item.image}
                        loading="lazy"
                        className={
                          props.isLength
                            ? "img-length "
                            : "img-achiev-event "
                        }
                        alt={item.altText}
                      />
                    </a>
                  </div>
                ))}
              </div>
        </div>
        <div className="card-footer border-0">
          {props.data.map((item, index) => (
            <p>{index === 0 ? item.description : null}</p>
          ))}
        </div>
      </>
    )}
  </div>
</div>

    </>
  );
};

export default EventContainer;
