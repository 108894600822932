import React from 'react'
// import Hostel_img1 from "../../../assets/images/Infrastrutre/"
import { Helmet } from "react-helmet-async";




function hostel() {
  return (
 <>
      <>
      <Helmet>
        <title>Hostel- JNCW</title>
      </Helmet>
 <div
        className="container mt-4 text-justify"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
                <h4 class="mb-5"> </h4>
            </div>
             <div className="container">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
   
            <div class="card mt-2">
              <div
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-parent="#accordion"
                href="#collapse1st"
                aria-expanded="false"
                aria-controls="collapse1st"
              >
                <div
                  class="card-header header-green "
                  role="tab"
                  id="headingOne"
                >
                  <h4 className='title'>
       Hostel
                  </h4>
                </div>
              </div>
              <div
                id="collapse1st"
                class="card-collapse collapse in show"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <p>
                      Jawaharlal Nehru College for Women is committed to provide a highly innovative, healthy and cooperative learning environment to the students. The institute understands the importance of clean and healthy living for better learning. The hostel is being established for girls equipped with all facilities and amenities to provide comfortable living to the resident students. The room are big and airy with proper ventilation and cleaning. Separate beds and wardrobes are provided to each resident along with a dedicated study table. There is a provision of adequate lightning and cooling in living rooms along with regularly cleaned bed sheets. Every effort is made by the hostel staff to maintain a hygienic, calm and healthy living environment.
                   </p>

                      <p>
                      To encourage co-operation among students, a common room for discussions and television watching has been provided where students can share their ideas and also get involved in collaborative learning. In-house gymnasium has been set up with a good number of tools and machineries where students exercise to attain a better physical appearance and internal strength under the supervision of expert trainers. In support to the vision of Learning with Fun, a number of Indoor and Outdoor games are provisioned to keep students fresh and busy with their friends. Hostel nights and other events are organized on regular intervals as an add-on to the fun in living.
                  </p>
                    </div>
{/*                   
                 <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={Hostel_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> 
                           <img
                        src={Hostel_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  
                          <img
                        src={Hostel_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 <div className="col"> 
                                  <img
                        src={Hostel_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                      </div>   */}
                  </div>
                </div>
              </div>
            </div>
              </div>
        </div>
      </div>




  </>
 </>
  )
}

export default hostel