import React from "react";
import "./style.css";

function Tamil() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        தமிழ்த்துறை பற்றிய அறிமுகம்
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            2016 ஆம் ஆண்டு 30 மாணவிகளுடன் தொடங்கப்பட்ட இத்தமிழ்த்துறையில்
            தற்போது 100 மாணவிகள் பயின்று வருகின்றனர் 2016-2019ஆம் கல்வியாண்டில்
            பயின்ற 30 மாணவிகளும் 100 சதவீதம் தேர்ச்சி பெற்றனர் என்பது
            குறிப்பிடத்தக்கது. மேலும் இத்துறையில் பணிப்புரியும் அனைத்து உதவிப்
            பேராசிரியர்களும் பல்கலைக் கழகத் தகுதி பெற்ற பேராசிரியர்கள் ஆவர்.
          </p>
          <p>
            <ul>
              <div className="page-header ">
                <h5 className="bg-heading text-uppercase">
                  தமிழ்த் துறையின் சிறப்பம்சங்கள்
                </h5>
              </div>
              <li>
                தமிழக அரசுப்பணிகளில் தமிழ்வழிக் கற்றவருக்கு முன்னூரிமை
                வழங்கப்படுகிறது.
              </li>
              <li>
                இந்திய ஆட்சிப்பணி (ஐ ஏ எஸ் ) முன்மைத்தேர்வில் கட்டாய இந்திய
                மொழித்தாள் வரிசையில் தமிழ்மொழித்தாள் இடம்பெற்றுள்ளது. அதில்
                குறைந்தபட்ச மதிப்பெண் பெற்றால் மட்டுமே ஐஏஎஸ் தேர்வின் பிற
                தாள்கள் மதிக்கப்படும்.
              </li>
              <li>
                ஆசிரியர் பணிக்கான TET, TRB தேர்வுகளில் தமிழுக்கும் தமிழ்ப்
                பட்டதாரிகளுக்கும் வாய்ப்பு(TET,TRB) போன்ற அரசுத்தேர்வுகளில்
                தமிழுக்கான வாய்ப்பே அதிகமாக காணப்படுகிறது.
              </li>
              <li>
                TNPSC நடத்தும் தேர்வுகளில் தமிழிலிருந்தே செம்பாதி மதிப்பெண்கள்
                கேட்கப்படுகின்றன. அது மட்டுமன்றி தமிழ் வழியில் கற்றவருக்கே
                முன்னுரிமையும் அளிக்கப்படுகிறது.
              </li>
              <li>
                மொழிபெயர்ப்புத் துறையிலும் படைப்புத்துறையிலும் ஆர்வத்துடன்
                தமிழ்பயின்ற தகுதி மிக்கவர்களுக்கே வாய்ப்பு. சிறுகதை, கவிதை,
                நாவல் எழுதுவதற்கு தமிழ் பயின்றவர்களால் மட்டுமே சிறந்து விளங்க
                முடிகிறது.
              </li>
              <li>
                நல்ல உச்சரிப்புத்திறன் கொண்டவர்கள் வானொலி மற்றும்
                தொலைக்காட்சிகளில் செய்தி வாசிப்பாளராகலாம். தமிழ்க்கண்ணினியம்,
                கலைச்சொல் உருவாக்கம் இணையத்திலும், இணையகளஞ்சியங்களிலும் கட்டுரை
                எழுதுதல், பிழை திருத்துதல், பிழை திருத்த மென்பொருள் உருவாக்குதல்
                போன்ற பணி வாய்ப்புகளை பெறலாம்.
              </li>
              <li>
                தமிழ்க் கணினியம் , கலைச்சொல் உருவக்கம் இணையத்திலும் ,
                இணையக்களஞ்சியங்களிலும் கட்டுரை எழுதுதல், பிழை திருத்துதல் , பிழை
                திருத்த மென்பொருள் உருவாக்குதல் போன்ற பணி வாய்ப்புகள் .
              </li>
              <li>
                அரசுத்துறை நிறுவனங்கள் , நீதிமன்றங்கள் , பாடநூல் கழகம்
                முதலியவற்றில் மொழிபெயர்ப்பாளர் பிழைதிருத்துணர் முதலிய பணி
                வாய்ப்புகள் நிறைய உள்ளன.
              </li>
              <li>
                மலேஷியா , சிங்கப்பூர் , பிஜி மாலத்தீவு போன்ற வெளிநாடுகளில்
                தமிழாசிரியர்களுக்கான வேலைவாய்ப்பு.
              </li>
              <li>
                M.A, M.Phil போன்ற பட்ட மேற்படிப்புகளைத் தொடராலாம். SET, NET
                போன்ற தகுதித்தேர்வுகளில் தேர்ச்சிப்பெற்று கல்லூரி
                பேராசிரியராகலாம் .
              </li>
              <li>
                SET, NET போன்ற தகுதித்தேர்வுகளில் தேர்ச்சிப்பெற்று கல்லூரிப்
                பேராசிரியராகலாம்.
              </li>
              <li>
                திரைப்படத்துறையில் திரைக்கதை, இயக்கம் பாடல் எழுதுதல், வசனம்
                பேசுதல் போன்ற பல்வேறு துறைகளில் தமிழுக்கான வாய்ப்பு அதிகளவில்
                காணப்படுகிறது.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">நோக்கம்</h5>
            </div>
            ஜவஹர்லால்நேரு மகளிர் கல்லூரியில் தமிழ்த்துறையானது 2016 ஆம் ஆண்டு
            துவங்கப் பட்டது. நவீன உலகில் தமிழ்மொழி முக்கிய பங்கு வகிக்கின்றது.
            எனவே மாணவிகளுக்கு தமிழ்த்தொடர்பான படைப்பாற்றலை வளர்த்தல் மற்றும்
            அறிவை மேம்படுத்துதல் ஆகியவற்றை நோக்கமாகக்கொண்டு இத்துறை செயல்பட்டு
            வருகிறது.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">குறிக்கோள்</h5>
            </div>
            <ul>
              <li>
                அரசுப் போட்டித் தேர்வுகளை எதிர்கொள்ள மாணவிகளைத் தயார் செய்தல்.
              </li>
              <li>
                கணினி மயமாக்கப்பட்ட இக்கால கட்டத்திற்கு ஏற்றவாறு இதழியல்துறை,
                பதிப்புத்துறை, விளம்பரத்துறை, திரைத்துறை, போன்ற துறைகளில் உள்ள
                வாய்ப்புகளை எடுத்துக் கூறி மாணவிகளை ஊக்கப்படுத்துதல்.
              </li>
              <li>
                படைப்பாற்றலை வளர்க்கும் நோக்கில் கவிதை, கட்டுரை, பேச்சு, ஒவியம்
                போன்ற பல்வேறு போட்டிகளில் மாவட்ட மற்றும் மாநில அளவில் பங்குபெறச்
                செய்தல்.
              </li>
              <li>
                கலாச்சாரம் மற்றும் பண்பாட்டினை வளர்க்கும் நோக்கில் நிகழ்ச்சிகளை
                ஏற்பாடு செய்தல்.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                துறையின் செயல்பாடுகள்
              </h5>
              <ul>
                <li>
                  முத்திமிழ் இலக்கிய மன்றத்தின் சார்பில் மாணவிகளுக்கு பல்வேறு
                  வகையான போட்டிகள் நடத்தி பரிசுகளும் சானறிதழ்களும் வழங்கப்பட்டு
                  வருகிறது.
                </li>
                <li>
                  திருவள்ளுவர் பண்ப்பாட்டுக் கல்விக் கழகத்தின் சார்பில் ஒவவோர்
                  ஆணடும் திருக்குறள் தேர்வு நடத்தி சான்றிதழ் வழங்கப்பட்டு
                  வருகிறது.
                </li>
                <li>
                  ஒவவோர் ஆணடும் மாணவிகளுக்கு பாடந் தொடர்பான அறிவை வளர்க்கும்
                  விதத்தில் கல்விச் சுற்றுலா அழைத்துச் செல்லப்படுபடுக்கிறது.
                </li>
                <li>
                  ஒவவோர் ஆண்டின் இரண்டு பருவங்களிலும் தமிழகத்தின் முன்னோடி
                  பல்கலைக் கழகத்தைச் சாரந்த பேராசிரியர்களைக் கொண்டு சிறப்புச்
                  சொற்பொழிவுகள் நடத்தப் படுகின்றன.
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tamil;
