import React from 'react'
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import "./style.css";
import FoodFestival_1 from "../../../assets/images/gallery/2021/foodfestival/immg (1).jpeg";
import FoodFestival_2 from "../../../assets/images/gallery/2021/foodfestival/immg (3).jpeg";
import FoodFestival_3 from "../../../assets/images/gallery/2021/foodfestival/immg (2).jpeg";
import FoodFestival_4 from "../../../assets/images/gallery/2021/foodfestival/immg (4).jpeg";
import FoodFestival_5 from "../../../assets/images/gallery/2021/foodfestival/immg (5).jpeg";
import FoodFestival_6 from "../../../assets/images/gallery/2021/foodfestival/immg (6).jpeg";
import FoodFestival_7 from "../../../assets/images/gallery/2021/foodfestival/immg (7).jpeg";
import FoodFestival_8 from "../../../assets/images/gallery/2021/foodfestival/immg (8).jpeg";
import FoodFestival_9 from "../../../assets/images/gallery/2021/foodfestival/immg (9).jpeg";
import FoodFestival_10 from "../../../assets/images/gallery/2021/foodfestival/immg (10).jpeg";
import FoodFestival_11 from "../../../assets/images/gallery/2021/foodfestival/immg (11).jpeg";
import FoodFestival_12 from "../../../assets/images/gallery/2021/foodfestival/immg (12).jpeg";
import FoodFestival_13 from "../../../assets/images/gallery/2021/foodfestival/immg (13).jpeg";
import FoodFestival_14 from "../../../assets/images/gallery/2021/foodfestival/immg (14).jpeg";
import FoodFestival_15 from "../../../assets/images/gallery/2021/foodfestival/immg (15).jpeg";
import FoodFestival_16 from "../../../assets/images/gallery/2021/foodfestival/immg (16).jpeg";
import FoodFestival_17 from "../../../assets/images/gallery/2021/foodfestival/immg (17).jpeg";
import FoodFestival_18 from "../../../assets/images/gallery/2021/foodfestival/immg (18).jpeg";
import FoodFestival_19 from "../../../assets/images/gallery/2021/foodfestival/immg (19).jpeg";
import FoodFestival_20 from "../../../assets/images/gallery/2021/foodfestival/immg (20).jpeg";
import FoodFestival_21 from "../../../assets/images/gallery/2021/foodfestival/immg (21).jpeg";
import FoodFestival_22 from "../../../assets/images/gallery/2021/foodfestival/immg (22).jpeg";
import FoodFestival_23 from "../../../assets/images/gallery/2021/foodfestival/immg (23).jpeg";
import FoodFestival_24 from "../../../assets/images/gallery/2021/foodfestival/immg (24).jpeg";
import FoodFestival_25 from "../../../assets/images/gallery/2021/foodfestival/immg (25).jpeg";
import FoodFestival_26 from "../../../assets/images/gallery/2021/foodfestival/immg (26).jpeg";
import FoodFestival_27 from "../../../assets/images/gallery/2021/foodfestival/immg (27).jpeg";
import FoodFestival_28 from "../../../assets/images/gallery/2021/foodfestival/immg (28).jpeg";
import FoodFestival_29 from "../../../assets/images/gallery/2021/foodfestival/immg (29).jpeg";
import FoodFestival_30 from "../../../assets/images/gallery/2021/foodfestival/immg (30).jpeg";
import FoodFestival_31 from "../../../assets/images/gallery/2021/foodfestival/immg (31).jpeg";


const Moments2021 = () => {
  const FoodFestival =[
        {
            image:FoodFestival_1,
            altText: "",
            caption: "",
            id:"rangolicontest",
            },
            
            {
                image:FoodFestival_2,
                altText: "",
                caption: "",
                id:"rangolicontest",
                },
     
                {
                    image:FoodFestival_3,
                    altText: "",
                    caption: "",
                    id:"rangolicontest",
                    },
         
                    {
                        image:FoodFestival_4,
                        altText: "",
                        caption: "",
                        id:"rangolicontest",
                        },
             
                        {
                            image:FoodFestival_5,
                            altText: "",
                            caption: "",
                            id:"rangolicontest",
                            },
                 
                            {
                                image:FoodFestival_7,
                                altText: "",
                                caption: "",
                                id:"rangolicontest",
                                },
                     
                                {
                                    image:FoodFestival_8,
                                    altText: "",
                                    caption: "",
                                    id:"rangolicontest",
                                    },
                         
                                    {
                                        image:FoodFestival_6,
                                        altText: "",
                                        caption: "",
                                        id:"rangolicontest",
                                        },
                             
                                        {
                                            image:FoodFestival_9,
                                            altText: "",
                                            caption: "",
                                            id:"rangolicontest",
                                            },
                                 

                                            {
                                                image:FoodFestival_10,
                                                altText: "",
                                                caption: "",
                                                id:"rangolicontest",
                                                },
                                     
                                                {
                                                    image:FoodFestival_11,
                                                    altText: "",
                                                    caption: "",
                                                    id:"rangolicontest",
                                                    },
                                         
                                                    {
                                                        image:FoodFestival_12,
                                                        altText: "",
                                                        caption: "",
                                                        id:"rangolicontest",
                                                        },
                                             
                                                        {
                                                            image:FoodFestival_13,
                                                            altText: "",
                                                            caption: "",
                                                            id:"rangolicontest",
                                                            },
                                                 


                                                            {
                                                                image:FoodFestival_14,
                                                                altText: "",
                                                                caption: "",
                                                                id:"rangolicontest",
                                                                },
                                                     
                                                                {
                                                                    image:FoodFestival_15,
                                                                    altText: "",
                                                                    caption: "",
                                                                    id:"rangolicontest",
                                                                    },
                                                         
                                                                    {
                                                                        image:FoodFestival_16,
                                                                        altText: "",
                                                                        caption: "",
                                                                        id:"rangolicontest",
                                                                        },
                                                             
                                                                        {
                                                                            image:FoodFestival_17,
                                                                            altText: "",
                                                                            caption: "",
                                                                            id:"rangolicontest",
                                                                            },
                                                                 
                                                                            {
                                                                                image:FoodFestival_18,
                                                                                altText: "",
                                                                                caption: "",
                                                                                id:"rangolicontest",
                                                                                },
                                                                     
                                                                                {
                                                                                    image:FoodFestival_19,
                                                                                    altText: "",
                                                                                    caption: "",
                                                                                    id:"rangolicontest",
                                                                                    },
                                                                         
                                                                                    {
                                                                                        image:FoodFestival_20,
                                                                                        altText: "",
                                                                                        caption: "",
                                                                                        id:"rangolicontest",
                                                                                        },
                                                                             
                                                                                        {
                                                                                            image:FoodFestival_21,
                                                                                            altText: "",
                                                                                            caption: "",
                                                                                            id:"rangolicontest",
                                                                                            },
                                                                                 
                                                                                            {
                                                                                                image:FoodFestival_22,
                                                                                                altText: "",
                                                                                                caption: "",
                                                                                                id:"rangolicontest",
                                                                                                },
                                                                                     
                                                                                                {
                                                                                                    image:FoodFestival_23,
                                                                                                    altText: "",
                                                                                                    caption: "",
                                                                                                    id:"rangolicontest",
                                                                                                    },
                                                                                         
                                                                                                    {
                                                                                                        image:FoodFestival_24,
                                                                                                        altText: "",
                                                                                                        caption: "",
                                                                                                        id:"rangolicontest",
                                                                                                        },
                                                                                             
                                                                                                        {
                                                                                                            image:FoodFestival_25,
                                                                                                            altText: "",
                                                                                                            caption: "",
                                                                                                            id:"rangolicontest",
                                                                                                            },
                                                                                                 
                                                                                                            {
                                                                                                                image:FoodFestival_26,
                                                                                                                altText: "",
                                                                                                                caption: "",
                                                                                                                id:"rangolicontest",
                                                                                                                },
                                                                                                     
                                                                                                                {
                                                                                                                    image:FoodFestival_27,
                                                                                                                    altText: "",
                                                                                                                    caption: "",
                                                                                                                    id:"rangolicontest",
                                                                                                                    },
                                                                                                         
                                                                                                                    {
                                                                                                                        image:FoodFestival_28,
                                                                                                                        altText: "",
                                                                                                                        caption: "",
                                                                                                                        id:"rangolicontest",
                                                                                                                        },
                                                                                                             
                                                                                                                        {
                                                                                                                            image:FoodFestival_29,
                                                                                                                            altText: "",
                                                                                                                            caption: "",
                                                                                                                            id:"rangolicontest",
                                                                                                                            },
                                                                                                                 

                                                                                                                            {
                                                                                                                                image:FoodFestival_30,
                                                                                                                                altText: "",
                                                                                                                                caption: "",
                                                                                                                                id:"rangolicontest",
                                                                                                                                },
                                                                                                                     
                                                                                                                                {
                                                                                                                                    image:FoodFestival_31,
                                                                                                                                    altText: "",
                                                                                                                                    caption: "",
                                                                                                                                    id:"rangolicontest",
                                                                                                                                    },
                                                                                                                         
                                                                                                                                                                                                                                                               
  ];
   
  return (
   <>
      <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

    <div className="container">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5">JNCW-2021 Moments</h4>
            </div>
      <EventContainer
            data={FoodFestival}
            header="Food Festival 2021"
            id="foodfestival"
            />
         
          
          </div>

            
   </>


  );
};

export default Moments2021;