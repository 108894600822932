import React from 'react'
import BaEnglish from "../../../../assets/images/Department/5.png";
import BaTamil from "../../../../assets/images/Department/6.png";
import BaMaths from "../../../../assets/images/Department/3.png";
import BaPhysics from "../../../../assets/images/Department/7.png";
import Bachemistry from "../../../../assets/images/Department/4.png";
import BscComputerscience from "../../../../assets/images/Department/1.png";
import Bca from "../../../../assets/images/Department/2.png";
import { Helmet } from "react-helmet-async";
import "../styles.scss";
import { Link } from 'react-router-dom';


function  Department() {
  return (
  <>
   <Helmet>
        <title>Department- JNCW</title>
      </Helmet>
     <div className="container">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title bg-white text-center text-primary px-3">DEPARTMENT</h5>
        <h1 className="mb-5">Courses Offered</h1>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div class="card">
            <div class="card-header text-center bg-success text-white">UG Courses</div>
            <ul class="list-group list-group-flush">
              <a class="list-group-item text-center" href="courses-baenglish">B.A. English</a>
              <a class="list-group-item text-center" href="/courses-baTamil">B.A. Tamil</a>
              <a class="list-group-item text-center" href="/courses-bba ">B.B.A. Business Administration</a>
              <a class="list-group-item text-center" href="/courses-commerce ">B.Com. General</a>
              <a class="list-group-item text-center" href="/courses-b.com.ca">B.Com. CA</a>
              <a class="list-group-item text-center" href="/courses-bscmaths ">B.Sc. Mathematics</a>
              <a class="list-group-item text-center" href="/courses-bscphysics ">B.Sc. Physics</a>
              <a class="list-group-item text-center" href="/courses-bscchemistry ">B.Sc. Chemistry</a>
              <a class="list-group-item text-center" href="/courses-bscmicrobiology ">B.Sc. Microbiology</a>
              <a class="list-group-item text-center" href="/courses-bsccomputer ">B.Sc. Computer Science</a>
              <a class="list-group-item text-center" href="/courses-computerapplication ">B.C.A. Computer Application</a>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div class="card">
            <div class="card-header text-center bg-success text-white">PG Courses</div>
            <ul class="list-group list-group-flush">
              <a class="list-group-item text-center " href="courses-baenglish ">M.A. English</a>
              <a class="list-group-item text-center " href="/courses-commerce ">M.Com</a>
              <a class="list-group-item text-center " href="/courses-bscmaths ">M.Sc. Mathematics</a>
              <a class="list-group-item text-center " href="/courses-bsccomputer">M.Sc. Computer Science</a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Department
