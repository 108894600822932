
import React from 'react';
import './faculty.css';
import { Helmet } from "react-helmet-async";

const StaffDetails = () => {
  return (
    <div className="container">
     <Helmet>
        <title>Faculty- JNCW</title>
      </Helmet>
      <section id="about" className="mt-4" data-aos="fade-up" data-aos-duration="2000">
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Staff Details</h4>
                {/* <h4 class="mb-5">JNCW-2024 Moments </h4> */}
            </div>
        <div className="responsive-table-container">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="text-center table table-striped">
                <thead className="bg-thead">
                  <tr className="green-strip text-uppercase">
                    <th>S.NO</th>
                    <th>NAME OF THE FACULTY</th>
                    <th>QUALIFICATION</th>
                    <th>DESIGNATION</th>
                    <th>OTHER ACADEMIC POSITION</th>
                    <th>EXPERIENCE</th>
                  </tr>
                </thead>
            
                <tbody>
                  <tr>
                      <td class="text-center"> 1 </td>
                      <td class="text-center"> ANBUKARASI C </td>
                      <td class="text-center"> MA.,M.Phil.,M.Sc.,LLB., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> ACADEMIC CO-ORDINATOR  </td>
                      <td class="text-center"> 20 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">2 </td>
                      <td class="text-center"> BALACHANDIRAN N </td>
                      <td class="text-center"> M.A.,B.Ed.,M.Phil., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> PRO </td>
                      <td class="text-center">  13 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 3 </td>
                      <td class="text-center">PACKIAMMAL A  </td>
                      <td class="text-center"> M.A.,B.Ed., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 11 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 4</td>
                      <td class="text-center"> SARANYA C </td>
                      <td class="text-center"> M.A.,B.Ed.,M.Phil., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> RRC CO ORDINATOR </td>
                      <td class="text-center"> 7 & 1/2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 5 </td>
                      <td class="text-center"> DHIVYAPRIYA S </td>
                      <td class="text-center"> M.A.,M.Phil., </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 6 </td>
                      <td class="text-center"> ABINAYA S </td>
                      <td class="text-center"> M.A.,B.Ed.,M.Phil., </td>
                      <td class="text-center">  ASST PROF</td>
                      <td class="text-center"> - </td>
                      <td class="text-center">  2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 7 </td>
                      <td class="text-center"> AISHWARYA M </td>
                      <td class="text-center"> M.A.,M.Phil., </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center">  </td>
                      <td class="text-center"> 2 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center">8  </td>
                      <td class="text-center"> MALINI R </td>
                      <td class="text-center">M.A.,M.Phil.,  </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 1 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">9 </td>
                      <td class="text-center"> Dr.R. VAIDEHI  </td>
                      <td class="text-center"> M.A.,B.Ed.,M.Phil.,Ph.D., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> NSS CO-ORDINATOR </td>
                      <td class="text-center">  10 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 10 </td>
                      <td class="text-center"> BACKIYALAKSHMI G </td>
                      <td class="text-center"> M.A.,B.Ed.,M.Phil.,NET </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> PUTHUMAIPEN THITTAM INCHARGE  </td>
                      <td class="text-center">   10 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 11 </td>
                      <td class="text-center"> Dr.B.BALAMANI  </td>
                      <td class="text-center"> M.A.,M.Phil.,M.Ed.,Ph.D.,NET </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 7 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 12</td>
                      <td class="text-center"> Dr.K.KAYALVIZHI </td>
                      <td class="text-center"> M.A.,M.Phil.,Ph.D., </td>
                      <td class="text-center">   ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 4 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 13</td>
                      <td class="text-center"> JAYANTHI P </td>
                      <td class="text-center"> M.A.,B.Ed.,NET </td>
                      <td class="text-center">ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">14 </td>
                      <td class="text-center"> USHA A </td>
                      <td class="text-center"> M.A.,M.Phil.,M.Ed.,SET.,NET </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 5 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 15 </td>
                      <td class="text-center"> GOWRI M </td>
                      <td class="text-center"> M.Sc.,B.Ed.,M.Phil., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 13 & 1/2 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 16 </td>
                      <td class="text-center"> ABIRAMASUNDARI G </td>
                      <td class="text-center"> M.Sc.,B.Ed.,M.Phil.,Ph.D., </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 6 & 1/2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 17</td>
                      <td class="text-center"> BHUVANESWARI S</td>
                      <td class="text-center"> M.Sc.,M.Phil., </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 12 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">18 </td>
                      <td class="text-center"> RAJARAJESWARI M </td>
                      <td class="text-center">  M.Sc.,M.Phil.,</td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> NAAN MUDHALVAN CO-ORDINATOR </td>
                      <td class="text-center"> 14 & 1/2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">19 </td>
                      <td class="text-center">BHAGYALAKSHMI R  </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> 6 YEARS </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> M.Sc.,M.Phil.,M.Ed., </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 20 </td>
                      <td class="text-center"> SUGANTHI M </td>
                      <td class="text-center">M.Sc.,M.Phil.,  </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> -  </td>
                      <td class="text-center"> 9 MONTHS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 21</td>
                      <td class="text-center"> MALATHI P </td>
                      <td class="text-center">  MCA.,M.Phil.,(Ph.D).,</td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> EDP CO-ORDINTOR </td>
                      <td class="text-center"> 7 & 1/2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 22</td>
                      <td class="text-center">ESTHER RAJATHI T  </td>
                      <td class="text-center"> MCA., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 1 YEAR  </td>
                    </tr>
                    <tr>
                      <td class="text-center">23 </td>
                      <td class="text-center"> SARANYA  P </td>
                      <td class="text-center">  MCA.,  </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 3 YEARS 9 MONTHS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 24</td>
                      <td class="text-center"> KARNAN K </td>
                      <td class="text-center">  M.Sc.,B.Ed.,M.Phil.,(Ph.D).,</td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center">  -</td>
                      <td class="text-center">17 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center">25 </td>
                      <td class="text-center"> JAYALAKSHMI S </td>
                      <td class="text-center">  MCA.,B.Ed.,M.Phil.,</td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center">  -</td>
                      <td class="text-center"> 8 YEARS 4 MONTHS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 26</td>
                      <td class="text-center">RAMANATHAN J  </td>
                      <td class="text-center"> M.Sc.,B.Ed.,M.Phil., </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 27 </td>
                      <td class="text-center">SAGAYA ANUSUYA S  </td>
                      <td class="text-center"> M.Sc., </td>
                      <td class="text-center"> ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 28</td>
                      <td class="text-center"> SIVAKUMAR K </td>
                      <td class="text-center"> MBA.,(Ph.D)., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 15 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 29 </td>
                      <td class="text-center"> ELANCHEZHIYAN R </td>
                      <td class="text-center">  MBA.,M.A.,M.Phil.,</td>
                      <td class="text-center">ASST PROF   </td>
                      <td class="text-center">-  </td>
                      <td class="text-center">  15 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 30</td>
                      <td class="text-center">VIJAYALAKSHMI K  </td>
                      <td class="text-center"> MBA.,M.Phil.,Ph.D., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center">7 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center">31 </td>
                      <td class="text-center">  JEEVA R</td>
                      <td class="text-center"> M.COM.,M.Phil.,M.Ed., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center">   SCHOLARSHIP INCHARGE </td>
                       <td class="text-center"> 5 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 32 </td>
                      <td class="text-center"> ANBUSELVI S </td>
                      <td class="text-center"> BE.,MBA., </td>
                      <td class="text-center">  ASST PROF</td>
                      <td class="text-center"> PLACEMENT CO ORDINATOR </td>
                      <td class="text-center"> 2 YEARS 4 MONTHS </td>
                    </tr>
                    <tr>
                      <td class="text-center">33 </td>
                      <td class="text-center"> KAVIDHA A </td>
                      <td class="text-center"> MBA.,M.Phil.,(Ph.D)., </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 15 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 34</td>
                      <td class="text-center"> PERIYASAMY P </td>
                      <td class="text-center">  M.Sc.,B.Ed.,M.Phil.,</td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> EXAM CELL CO ORDINATOR </td>
                      <td class="text-center"> 10 YEARS 4 MONTHS </td>
                    </tr>
                    <tr>
                      <td class="text-center">35 </td>
                      <td class="text-center"> PRINCYMERLINE X </td>
                      <td class="text-center"> M.Sc.,M.Phil., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 5 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">36  </td>
                      <td class="text-center"> ABIRAMI A </td>
                      <td class="text-center"> M.Sc.,B.Ed., </td>
                      <td class="text-center">  ASST PROF  </td>
                      <td class="text-center">  -</td>
                      <td class="text-center"> 9 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">37 </td>
                      <td class="text-center"> GANGADEVI S </td>
                      <td class="text-center"> M.Sc.,M.Phil., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 3 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 38</td>
                      <td class="text-center">  REKHA D</td>
                      <td class="text-center"> M.Sc.,M.Phil., </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center">  5 YEARS</td>
                    </tr>
                    <tr>
                      <td class="text-center"> 39</td>
                      <td class="text-center">ANANDARAJA G  </td>
                      <td class="text-center"> M.Sc.,M.Phil., </td>
                      <td class="text-center">  ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 8 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">40 </td>
                      <td class="text-center"> PAVITHRA A </td>
                      <td class="text-center">  M.Sc.,B.Ed.,</td>
                      <td class="text-center">  ASST PROF  </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 3 & 1/2 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center">41 </td>
                      <td class="text-center">  RAHAMATHUNNISA A </td>
                      <td class="text-center"> M.COM.,MBA.,M.Phil.,NET </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center">  -</td>
                      <td class="text-center"> 17 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 42 </td>
                      <td class="text-center"> DEEPA S </td>
                      <td class="text-center"> M.COM.,M.Phil.,B.Ed., </td>
                      <td class="text-center"> ASST PROF </td>
                      <td class="text-center">  - </td>
                      <td class="text-center">  8 & 1/2 YEARS</td>
                    </tr>
                    <tr>
                      <td class="text-center">43</td>
                      <td class="text-center"> PARANJOTHI P </td>
                      <td class="text-center"> M.COM.,M.Phil.,D.T.Ed., </td>
                      <td class="text-center">  ASST PROF </td>
                      <td class="text-center"> - </td>
                      <td class="text-center">  1 YEAR</td>
                    </tr>
                    <tr>
                      <td class="text-center"> 44 </td>
                      <td class="text-center"> MALATHI N </td>
                      <td class="text-center">  M.Sc., B.Ed., </td>
                      <td class="text-center"> HOD & ASST PROF </td>
                      <td class="text-center"> EXAM CELL CO-ORDINATOR </td>
                      <td class="text-center"> 10 YEARS  </td>
                    </tr>
                    <tr>
                      <td class="text-center"> 45</td>
                      <td class="text-center">BASKARAN K  </td>
                      <td class="text-center"> M.Sc.,B.Ed.,M.Phil., </td>
                      <td class="text-center"> ASST PROF. </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 4 & 1/2 YEARS </td>
                    </tr>
                    <tr>
                      <td class="text-center">46 </td>
                      <td class="text-center"> MURUGAN.R </td>
                      <td class="text-center"> M.Sc.,M.Phil.,NET(Ph.D).,</td>
                      <td class="text-center"> ASST PROF. </td>
                      <td class="text-center"> - </td>
                      <td class="text-center"> 7 YEARS </td>
                    </tr>
                    <tr>
                    <td class="text-center"> 47 </td>
                      <td class="text-center"> VINOTHINI J</td>
                      <td class="text-center">  B.P.Ed.,M.P.Ed.,</td>
                      <td class="text-center"> PHYSICAL DIRECTRESS </td>
                                          <td class="text-center">-  </td>
                      <td class="text-center"> 2 YEARS </td>
                    </tr>
             
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StaffDetails;

