import React from "react";
import "./about.scss";
import ExecutiveDirector_Img from "../../../assets/images/about/Execute director/ExecutiveDirector.jpg";
import { Helmet } from "react-helmet-async";
function  ExecutiveDirector() {
  return (
    
    <div>
 <Helmet>
        <title>About Us- JNCW</title>
      </Helmet>
      <div className="container">
        {/* <h5 className="text-uppercase mt-5 bg-heading"> Our Principal</h5> */}
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Our Executive Director</h4>
                {/* <h4 class="mb-5">JNCW-2024 Moments </h4> */}
            </div>
        <img
          src={ ExecutiveDirector_Img}
          alt="principal of  Jawaharlal Nehru College for Women"
          className=" left-img mb-0 rounded"
          data-aos="fade-in"
          data-aos-duration="2000"
        />
        <div data-aos="fade-left" data-aos-duration="2000">
          <p style={{ color: "#BF40BF", fontSize: "14px", fontWeight: "bold" }}>
            {" "}
            <span style={{ fontSize: "18px", color: "#191970" }}>
            Dr.A.Kanmani Arun MBBS, BDDVL
            </span>
            <br />
            Executive Director
          </p>
        </div>
        <div data-aos="fade-in" data-aos-duration="3000">
          <p>
          A Women can only understand a women's requirement". With a great vision in understanding that Education is an amazing place for women, she always aimed at Women's Inventory for success Empowerment. She helped the team create a unique blend of academic and practical learning modules and inculcate contemporary modern day skills among women from rural background. The customized modules are penetrating yet flexible and can be moulded to fit different needs of students. Under her able guidance, college has formulated an education that fills the gap between existing skill set of a student and the required skill set for corporate.
          </p>
          <p>
          In order to create a sustainable and a tremendous world, women play a key significant role. Women can capable enough to handle the entire task simultaneously with their full power and they can do multitasking work at a time. With so many social challenges along with financial challenges, women were forced to be in limit in the family as well as in society.
          </p>
          <p>
          In which the quality of education is the important tool for women’s empowerment, prosperity, development and welfare. Our full intension is to develop future women in our society with strong enough to handle various situations in their life. So in our college all the faculty members are well trained to develop the girl students by giving valuable life skills training. Well, we are in responsible to give a world class education for our students. All our renewing and renovating programs are connected with academics, extracurricular, sports, NCC and NSS. And we always strive for improving women’s health by creating awareness and escalating their independency in the life.
          </p>
        </div>
      </div>
    </div>
  );
}

export default  ExecutiveDirector;
