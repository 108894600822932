import React from 'react'
import logo from "../../../assets/images/logo/jncwlogo.png";

function Placement() {
  return (
    <> 
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
     <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="section-title bg-white text-center text-primary px-3">Placement</h5>
                {/* <h1 className="mb-5"> Welcome To JNCW  </h1> */}
            </div>
      <h5 className="text-uppercase mt-5 bg-heading">OBJECTIVE</h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            {/* The department of English, Jawaharlal Nehru College for Women was
            established in the year 2011, with BA English and Part II English
            for the students of the intermediate classes. The Department has
            become a PG Department in 2015 with the introduction of the M.A.,
            English Course 2015. */}
          </p>
          <p>
            <div className="page-header ">
                           <ul>
                <li>
                To make realistic employment choices and to identify the goals to achieve.
                </li>
                <li>
                To explore their strength and career choices through individual skill assessments.
                </li>
                <li>
                To learn, practice and explore communication skill.
                </li>
                <li>
                To develop and practice self management skills for the work place.
                </li>
              </ul>
            </div>
          </p>
          {/* <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            <ul>
              <li>
                To transform the students into rational human beings with an
                inquisitive and argumentative bent of mind through literary,
                theoretical, and linguistic teaching.
              </li>
              <li>
                To balance the needs of general education - communication,
                diversity, global perspectives, interdisciplinary studies - with
                those of the major.
              </li>
            </ul>
          </p> */}
          {/* <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                To provide an interdisciplinary approach to knowledge, evident
                in their involvement in programs such as Ethnic Studies, Women's
                Studies, Film Studies, International Studies, and Environmental
                Studies.
              </li>
              <li>
                To provide General Education courses in Communication,
                Humanities and Fine Arts.
              </li>
            </ul>
          </p> */}
          {/* <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                GITANJALI LITERARY ASSOCIATION
              </h5>
              It provides a platform to exhibit the hidden talents of our
              students through the conduction of various club activities such as
              Poetry writing, Poetry recitation, Essay Writing, Literary Parade,
              Literary Quiz, Skit, and Speech Competition.
            </div>
          </p> */}
{/* 
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                IMPORTANT MILESTONE OF THE DEPARTMENT
              </h5>
              The department of English celebrates academic excellent
              withholding of university rank. It is a remarkable record of our
              department. A.REVATHI who studied II MA in the academic year of
              2017-2019 has got 2nd rank in the Thiruvalluvar university exam.
            </div>
          </p> */}
          {/* <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">TUTORIAL SYSTEM</h5>
              We follow the tutorial system as per the guidance of the principal
              and management. Through this system. Students meet their tutors
              and discuss their academic, Extra-Curricular, and co-curricular
              activities. It helps the students to develop their studies and
              Problem Solving capacity.
            </div>
          </p> */}

          {/* <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">
                SCOPE FOR ENGLISH DEPARTMENT
              </h5>
              <ul>
                <li>
                  Wide range of opportunities in the teaching field for Teachers
                  and Lecturers. (Both in India and Abroad)
                </li>
                <li>In Multi-National Companies – HR Managers </li>
                <li>
                  Entertainment field – Anchors, Editors, RJs, Sports Reporters,
                  Cricket Commenters
                </li>
                <li>
                  Journal and News stations – Reporters, News readers, and
                  Editors
                </li>
                <li>
                  Publication jobs – publishers of books, articles, and literary
                  works
                </li>
                <li>
                  As Translators – In many fields. Translation of books and
                  languages Writers – In producing literary works such as Poets,
                  novelists, dramatists, and also creative writers in TV films,
                  videos, and movies.
                </li>
                <li>Lawyers As Scriptwriters and Copywriters</li>
                <li>
                  Institutions for Spoken English and other Coaching
                  Institutions for TOFEL, IELTS. Abundant opportunities and
                  demand for Spoken English and Communication Trainers. TOEFL,
                  IELTS
                </li>
              </ul>
            </div>
          </p> */}
        </div>
      </div>
    </div>
 
     </>
  )
}

export default Placement;