import React from "react";
import "./style.css";

function English() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">DEPARTMENT OF ENGLISH</h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The department of English, Jawaharlal Nehru College for Women was
            established in the year 2011, with BA English and Part II English
            for the students of the intermediate classes. The Department has
            become a PG Department in 2015 with the introduction of the M.A.,
            English Course 2015.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                THE UNIQUENESS OF THE DEPARTMENT
              </h5>
              <ul>
                <li>
                  The programs offered by the department allow the learners to
                  embark on a voyage into the fathomless world of Literature.
                </li>
                <li>
                  The global interest of the learners is catered to by offering
                  courses such as English for the competitive examination,
                  Journalism, Theatre arts, Spoken English, Effective
                  Communication, and Creative Writing, which help the learners
                  use communication skills to branch out into any professional
                  domain.
                </li>
                <li>
                  The department also offers Learners sample opportunities to
                  give flight to their creative imagination, dramatics,
                  seminars, assignments, etc.
                </li>
                <li>
                  The Language Lab helps the students to enhance their
                  Communication skills.
                </li>
              </ul>
            </div>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            <ul>
              <li>
                To transform the students into rational human beings with an
                inquisitive and argumentative bent of mind through literary,
                theoretical, and linguistic teaching.
              </li>
              <li>
                To balance the needs of general education - communication,
                diversity, global perspectives, interdisciplinary studies - with
                those of the major.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                To provide an interdisciplinary approach to knowledge, evident
                in their involvement in programs such as Ethnic Studies, Women's
                Studies, Film Studies, International Studies, and Environmental
                Studies.
              </li>
              <li>
                To provide General Education courses in Communication,
                Humanities and Fine Arts.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                GITANJALI LITERARY ASSOCIATION
              </h5>
              It provides a platform to exhibit the hidden talents of our
              students through the conduction of various club activities such as
              Poetry writing, Poetry recitation, Essay Writing, Literary Parade,
              Literary Quiz, Skit, and Speech Competition.
            </div>
          </p>

          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                IMPORTANT MILESTONE OF THE DEPARTMENT
              </h5>
              The department of English celebrates academic excellent
              withholding of university rank. It is a remarkable record of our
              department. A.REVATHI who studied II MA in the academic year of
              2017-2019 has got 2nd rank in the Thiruvalluvar university exam.
            </div>
          </p>
          <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">TUTORIAL SYSTEM</h5>
              We follow the tutorial system as per the guidance of the principal
              and management. Through this system. Students meet their tutors
              and discuss their academic, Extra-Curricular, and co-curricular
              activities. It helps the students to develop their studies and
              Problem Solving capacity.
            </div>
          </p>

          <p>
            <div className="page-header">
              <h5 className="bg-heading text-uppercase">
                SCOPE FOR ENGLISH DEPARTMENT
              </h5>
              <ul>
                <li>
                  Wide range of opportunities in the teaching field for Teachers
                  and Lecturers. (Both in India and Abroad)
                </li>
                <li>In Multi-National Companies – HR Managers </li>
                <li>
                  Entertainment field – Anchors, Editors, RJs, Sports Reporters,
                  Cricket Commenters
                </li>
                <li>
                  Journal and News stations – Reporters, News readers, and
                  Editors
                </li>
                <li>
                  Publication jobs – publishers of books, articles, and literary
                  works
                </li>
                <li>
                  As Translators – In many fields. Translation of books and
                  languages Writers – In producing literary works such as Poets,
                  novelists, dramatists, and also creative writers in TV films,
                  videos, and movies.
                </li>
                <li>Lawyers As Scriptwriters and Copywriters</li>
                <li>
                  Institutions for Spoken English and other Coaching
                  Institutions for TOFEL, IELTS. Abundant opportunities and
                  demand for Spoken English and Communication Trainers. TOEFL,
                  IELTS
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default English;
