import React from "react";
import "./style.css";

function Physics() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Physics
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The Department of Physics was established in 2014 to provide
            education, to impart knowledge and to promote research and extension
            activities. The department has well equipped laboratories. Our
            department provides basic physics foundation through lectures and
            laboratory courses to the students of the B.Sc programme. This
            program trains future physicists with hands-on experience on pure
            sciences and skill based knowledge.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">
                Scope of B.Sc Physics
              </h5>
            </div>
            <ul>
              {" "}
              <li>
                Physics is the branch of science, which deals with the study of
                nature and natural phenomena. The repetition of day and night,
                cycle of seasons, volcanoes, rainbows, eclipses and the starry
                night sky have always been a source of wonder and subject of
                thought.
              </li>
              <li>
                Understanding science begins with understanding physics. With
                every passing day, physics has brought to us deeper levels of
                understanding of nature.
              </li>
              <li>
                The scope of physics can be understood if one looks at its
                various sub-disciplines such as mechanics, optics, heat and
                thermodynamics, electrodynamics, atomic physics, nuclear
                physics, etc.
              </li>
              <li>
                Mechanics deals with motion of particles and general systems of
                particles. The working of telescopes, colours of thin films is
                the topics dealt in optics.
              </li>
              <li>
                Heat and thermodynamics deals with the pressure - volume changes
                that take place in a gas when its temperature changes, working
                of refrigerator, etc.
              </li>
              <li>
                The phenomena of charged particles and magnetic bodies are dealt
                in electrodynamics.
              </li>
              <li>
                The magnetic field around a current carrying conductor,
                propagation of radio waves etc. are the areas where
                electrodynamics provides an answer.
              </li>
              <li>
                Atomic and nuclear physics deals with the constitution and
                structure of matter, interaction of atoms and nuclei with
                electrons, photons and other elementary particles.
              </li>
              <li>
                Foundation of physics enables us to appreciate and enjoy things
                and happenings around us.
              </li>
              <li>
                The laws of physics help us to understand and comprehend the
                cause-effect relationships in what we observe. This makes a
                complex problem to appear pretty simple.
              </li>
              <li>
                Physics is exciting in many ways. Applied physics is even more
                interesting. Transforming laws and theories into useful
                applications require great ingenuity and persistent effort.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            Our department aspires to provide the best scientific methods in
            teaching the basic principles of physics, both theoretical and
            experimental and puts most of time to keep the level of education,
            scientific research and community service.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            Impart quality education and promote scientific temper. Promoting
            academic excellence with discipline. Blend theoretical knowledge
            with practical skills. To provide a comprehensive undergraduate
            physics degree programme To encourage inter-disciplinary thinking by
            providing students with educational and research opportunities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Physics;
