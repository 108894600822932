import React from 'react'
import library_lab_img1 from "../../../assets/images/libary/lib-1.jpg";
import library_lab_img2 from "../../../assets/images/libary/library-1.jpg";
import library_lab_img3 from "../../../assets/images/libary/library-8.jpg";
import library_lab_img4 from "../../../assets/images/libary/lib-3.jpg";
import { Helmet } from "react-helmet-async";



function library() {
  return (
<>
<Helmet>
        <title>Libary- JNCW</title>
      </Helmet>
<div
        className="container mt-4 text-justify"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
                <h4 class="mb-5"> </h4>
            </div>
             <div className="container">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
   
            <div class="card mt-2">
              <div
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-parent="#accordion"
                href="#collapse1st"
                aria-expanded="false"
                aria-controls="collapse1st"
              >
                <div
                  class="card-header header-green "
                  role="tab"
                  id="headingOne"
                >
                  <h4 className='title'>
               Libary
                  </h4>
                </div>
              </div>
              <div
                id="collapse1st"
                class="card-collapse collapse in show"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <p>
                      Students are given dedicated time to work on each of the available kits and record the outcomes that are analysed by laboratory supervisors and teachers. Improvement and rectification suggestions are shared with the students for better results.

Deep understanding of minute components of machinery is provided along with demonstrations. Thus, the students are facilitated with live learning.

Information and facts written in print media has been the most comfortable and reliable way of gathering knowledge since the inception of humanity. A large number of texts are available for every topic wherein every author exhibits his understanding in his own way.
                      </p>

                      <p>
                      A large central library has been setup housing more than 30,000 filtered books of standard level, hundreds of journals and magazines. The texts are managed in an easily browse-able repository wherein texts are kept department wise and are further categorized according to topics they relate. Students can easy track the location of required books by browsing the online catalogue as well as communicating with the library administration team.

Dedicated reading area has also been provisioned where students can study and make important notes. This prevents students from carrying a lot of books for required topics.Books, Magazines and Journals are issued to students so that they can attain more knowledge that may not be a part of their academic curriculum. Text reading is also suggested by the teachers and librarian after keen exploration of recent trends in industry. The meaningful efforts of teachers and library administration have helped students to find the right direction of reading in relevance to the topics of their choice.
                      </p>
                    </div>
                  
                 <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={library_lab_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> 
                           <img
                        src={library_lab_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  
                          <img
                        src={library_lab_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 <div className="col"> 
                                  <img
                        src={library_lab_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                      </div>  
                  </div>
                </div>
              </div>
            </div>
              </div>
        </div>
      </div>
</>
  )
}

export default library