import React from 'react'
import logo from "../../../assets/images/logo/jncwlogo.png";

function   Alumini() {
  return (
    <> 
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
    <img src={logo} className='spinner-grow' alt="" style={{ maxWidth: '100%', height: 'auto' }} /> 
  </div>
 
     </>
  )
}

export default  Alumini;