import Logo from "../../../assets/images/logo/jncwlogo.png";
import LogoIso from "../../../assets/images/logo/iso-1.png";
import LogoIso2 from "../../../assets/images/logo/iso-2.png";
import "./navHead.css";
export const NavHead = () => {
  return (
      <div className="row text-center w-100">
      <div className="col-2 d-flex align-items-center">
        <a href="/">
          <img
            src={Logo}
            width="150"
            alt="jncw college"
            className=""
            style={{ marginLeft: "8vw", maxWidth: "11vw" }}
          />
        </a>
        <div className="col-2 d-flex align-items-center">
          <a href="/">
            <img
              src={LogoIso}
              width="150"
              alt="jncw college"
              className=""
              style={{ marginLeft: "55vw", maxWidth: "11vw" }}
            />
          </a>
        </div>
        <div className="col-2 d-flex align-items-center">
          <a href="/">
            <img
              src={LogoIso2}
              width="150"
              alt="jncw college"
              className=""
              style={{ marginLeft: "65vw", maxWidth: "11vw" }}
            />
          </a>
        </div>
      </div>
      <div className="col-9 p-3 " style={{ marginLeft: "-5vw" }}>
        <div className="col-12">
          <h2 className="text-component" style={{ fontSize: "1.8vw" }}>
            Jawaharlal Nehru College for Women (Arts & Science)
          </h2>
        </div>
       
        <div className="col-12">
          <h2 style={{ fontSize: "1.2vw" }} className="side-text">
            Affiliated to Annamalai University, TamilNadu -608 002.
          </h2>
        </div>

        <div className="col-12">
          <h2 style={{ fontSize: "1vw" }} className="side-text">
            An ISO 9001:2015 Certified Institute.
          </h2>
        </div>
        <div className="col-12">
          <h2 style={{ fontSize: "1vw" }} className="side-text">
       Part of JSA GROUP OF INSTITUTIONS
          </h2>
        </div>
        <div className="col-12">
          <h2 style={{ fontSize: "1vw" }} className="text-success">
            Pali, Ulundurpet, Kalakurichi District-606 104.
          </h2>
        </div>
        
      </div>
    </div>

  );
};