// NewsPage.js
import React from 'react';
import NewsList from './NewsList';
import "./style.css";

class NewsPage extends React.Component {
  state = {
    news: [
      { title: 'Admission Open ', description: 'Admission Open 2024-2025', date: 'April 1, 2024' },
      { title: 'International Conference ', description: 'International Conference Organized by the Science Departments', date:'April 1,2024' },
      { title: 'Admission Open ', description: 'Admission Open 2024-2025', date: 'April 5, 2024' },
      { title: 'Admission Open ', description: 'Admission Open 2024-2025', date: 'April 16 2024' },
      // Add more news items as needed
    ]
  };

  render() {
    return (
      <div className="news-page">
        <h1>News and Announcements</h1>
        <NewsList news={this.state.news} />
      </div>
    );
  }
}

export default NewsPage;
