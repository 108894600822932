import React from "react";
import "./style.css";

function Commerce() {
  return (
    <div
      className="container  about"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h5 className="text-uppercase mt-5 bg-heading">
        The Department of Commerce
      </h5>
      <div
        className="container row m-0"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="col-12">
          <p>
            The department of commerce was established in the year 2014 to offer
            COMMERCE course for under graduate (B.COM) during the year 2014 and
            post graduate (M.COM) during the year 2019 We have sent 3 batches
            from B.Com with good results. And we having the qualified faculty
            members of our department are five in numbers.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">Scope of B.Com</h5>
            </div>
            <ul>
              {" "}
              <li>
                The Department was established in 2014 to meet the ever- growing
                demand for the B.Com course from women aspirants. This programme
                is an undergraduate degree designed to inculcate business acumen
                in students for three years. The department currently has 162
                students. The course is designed to provide students with a wide
                range of managerial skills and understanding in streams like
                finance, accounting, taxation and management”.
              </li>
              <li>
                The education imparted here aims to nurture self-confidence, a
                commitment to excellence, "out of the box" thinking,
                sensitization to social and environmental needs and a holistic
                outlook on life, along with discipline in the students.
              </li>
              <li>
                To enrich their knowledge, the department arranges for
                workshops,"guest lectures and Industrial visits.
              </li>
              <li>
                The departmental has been constantly metamorphosing itself to
                suite the requirements and expectations of the industries so as
                to ensure 50% placement for our students.
              </li>
            </ul>
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">VISION</h5>
            </div>
            The Department of Commerce is established in 2014 with a vision of
            providing excellence in the fields of Commerce by imparting value
            based education, training and research. Since its inception, the
            primary aim of the department has been to make the commerce students
            respond to changing social realities through the development and
            application of knowledge. It also thrives towards creating a
            people-centered, ecologically sustainable and just society that
            promotes and protects dignity, equality, social justice and human
            rights for all. The motto of the department is to focus on outreach
            programs that would help the students to serve the world well with
            innovations and wisdom across the broad range of humanity's
            challenges in the globalised era.
          </p>
          <p>
            <div className="page-header ">
              <h5 className="bg-heading text-uppercase">MISSION</h5>
            </div>
            <ul>
              <li>
                Empowering students with all the knowledge and guidance that
                they need to become worthy management professionals.
              </li>
              <li>Learning through Doing.</li>
              <li>
                Providing for holistic and value based development of students
                which ultimately enhances their employability.
              </li>
              <li>Developing social consciousness among students.</li>
              <li>
                To carve a niche for ourselves in the specialized field of
                commerce and management.
              </li>
              <li>
                Provide a nurturing and motivating environment to exploit the
                full potential of the students.
              </li>
              <li>
                Grooming youth to become a truly global personality well
                equipped to deal with the modern world and its challenges.
              </li>
              <li>
                Enable holistic development of personality with a humane and
                global outlook.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Commerce;
