import React from "react";
import Secretaty_img from "../../../assets/images/about/chairman/secretary.jpg";
import "./about.scss";
import { Helmet } from "react-helmet-async";

function secretary() {
  return (
    <div className="container">
     <Helmet>
        <title>About Us- JNCW</title>
      </Helmet>
        <div className="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h4 class="section-title bg-white text-center text-primary px-3">Our Secretary </h4>
                {/* <h4 class="mb-5">JNCW-2024 Moments </h4> */}
            </div>
        <img
          src={Secretaty_img}
          alt="secretary of jsa college of agriculture and technology"
          className=" left-img mb-0 rounded"
          data-aos="fade-in"
          data-aos-duration="2000"
        />
        <div data-aos="fade-left" data-aos-duration="2000">
          <p style={{ color: "#BF40BF", fontSize: "14px", fontWeight: "bold" }}>
            {" "}
            <span style={{ fontSize: "18px", color: "#191970" }}>
              Dr.Arun JSA B.E, MBA,PGCPE,Ph.D
            </span>
            <br />
            Secretary &amp; Correspondent of Shri venkateshvara trust
          </p>
        </div>
        <div data-aos="fade-in" data-aos-duration="3000">
          <p>
          It's my immense pleasure to highlight about our college. We feel that education is a lifelong process to nourish the potential of individuals. Our main aim is to increase the quality of education and to give strong foundation for our students. We provide various arrays of courses and extracurricular opportunities for our students. And our faculties are well trained with our key objectives and vision to make the students to get into a well defined future. We have implemented many new methodologies in our institution to develop their academic and non-academic performance. We provide a best infrastructure with equipped laboratories and highly enthused qualified teachers and all amenities like computer Labs with internet facility, Library and Laboratories, Hostels, Transports, etc., We give more priority to our student’s safety and security with secured hostel facilities and sustainable transport.
          </p>
          <p>
          We have many sporting stars shining in our college and we provide more opportunities to the students to develop their skills in various sport activities.
          </p>
          <p>
          His Vision is to ensure our young women graduates have the skills to succeed in their life and make a difference in the society. He always believes that modern society needs to bring sociological and education empowerment among women to address the growth and development of the nation. He ensured that all efforts of the college are focused or providing the best facilities and opportunities to students, So that they are able to learn and grow in a congenial and rewarding environment.

We have many sporting stars shining in our college and we provide more opportunities to the students to develop their skills in various sport activities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default secretary;
