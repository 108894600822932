import React from 'react';
import "./styles.css";
import { Link } from 'react-router-dom';
import Whatsapp from "../../assets/images/whatsapp-icon.png";

const WhatsAppStickyButton = () => {
  const phoneNumber = '+917397784467'; // Replace with your WhatsApp number

  const handleHover = () => {
    // Add any additional logic for hover effects if needed
  };

  return (
    <Link
      to={`https://wa.me/${phoneNumber}`}
      target="_blank"
      className='whatsapp-sticky-btn'
      onMouseEnter={handleHover} 
    >
      <img src={Whatsapp} alt="WhatsApp Icon"  className='img-style' />
    </Link>
    
  );
};

export default WhatsAppStickyButton;
