import React from 'react'
import {Helmet} from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer"; 
import NssCamp1_Img_1 from "../../../assets/images/NSS camp/Img (1).jpg";
import NssCamp1_Img_2 from "../../../assets/images/NSS camp/Img (2).jpg";
import NssCamp1_Img_3 from "../../../assets/images/NSS camp/Img (3).jpg";
import NssCamp1_Img_4 from "../../../assets/images/NSS camp/Img (4).jpg";
import NssCamp1_Img_5 from "../../../assets/images/NSS camp/Img (5).jpg";
import NssCamp1_Img_6 from "../../../assets/images/NSS camp/Img (6).jpg";
import NssCamp1_Img_7 from "../../../assets/images/NSS camp/Img (7).jpg";
import NssCamp1_Img_8 from "../../../assets/images/NSS camp/Img (8).jpg";
import NssCamp1_Img_9 from "../../../assets/images/NSS camp/Img (9).jpg";
import NssCamp1_Img_10 from "../../../assets/images/NSS camp/Img (10).jpg";
import NssCamp1_Img_11 from "../../../assets/images/NSS camp/Img (11).jpg";
import NssCamp1_Img_12 from "../../../assets/images/NSS camp/Img (13).jpg";
import NssCamp1_Img_13 from "../../../assets/images/NSS camp/Img (12).jpg";
import NssCamp1_Img_14 from "../../../assets/images/NSS camp/Img (16).jpg";
import NssCamp1_Img_15 from "../../../assets/images/NSS camp/Img (14).jpg";
import NssCamp1_Img_16 from "../../../assets/images/NSS camp/Img (15).jpg";

import NssCamp2_Img_1 from "../../../assets/images/NSS camp/nss 2020/Img (1).jpg";
import NssCamp2_Img_2 from "../../../assets/images/NSS camp/nss 2020/Img (2).jpg";
import NssCamp2_Img_3 from "../../../assets/images/NSS camp/nss 2020/Img (3).jpg";
import NssCamp2_Img_4 from "../../../assets/images/NSS camp/nss 2020/Img (4).jpg";
import NssCamp2_Img_5 from "../../../assets/images/NSS camp/nss 2020/Img (5).jpg";
import NssCamp2_Img_6 from "../../../assets/images/NSS camp/nss 2020/Img (6).jpg";
import NssCamp2_Img_7 from "../../../assets/images/NSS camp/nss 2020/Img (7).jpg";
import NssCamp2_Img_8 from "../../../assets/images/NSS camp/nss 2020/Img (8).jpg";
import NssCamp2_Img_9 from "../../../assets/images/NSS camp/nss 2020/Img (9).jpg";
import NssCamp2_Img_10 from "../../../assets/images/NSS camp/nss 2020/Img (10).jpg";
import NssCamp2_Img_11 from "../../../assets/images/NSS camp/nss 2020/Img (11).jpg";

import NssCamp3_Img_1 from "../../../assets/images/ncc/img_1.jpeg";
import NssCamp3_Img_2 from "../../../assets/images/ncc/img_2.jpeg";
import NssCamp3_Img_3 from "../../../assets/images/ncc/img_4.jpeg";
import NssCamp3_Img_4 from "../../../assets/images/ncc/img_ (8).jpeg";

import Yrc_Img_1 from "../../../assets/images/NSS camp/Yrc/yrc-1.jpg";
import Yrc_Img_2 from "../../../assets/images/NSS camp/Yrc/yrc-2.jpg";
import Yrc_Img_3 from "../../../assets/images/NSS camp/Yrc/yrc-3.jpg";
import Yrc_Img_4 from "../../../assets/images/NSS camp/Yrc/yrc-4.jpg";
import Yrc_Img_5 from "../../../assets/images/NSS camp/Yrc/yrc-5.jpg";
import Yrc_Img_6 from "../../../assets/images/NSS camp/Yrc/yrc-6.jpg";
import Yrc_Img_7 from "../../../assets/images/NSS camp/Yrc/yrc-7.jpg";
import Yrc_Img_8 from "../../../assets/images/NSS camp/Yrc/yrc-8.jpg";
import Yrc_Img_9 from "../../../assets/images/NSS camp/Yrc/yrc-9.jpg";
import Yrc_Img_10 from "../../../assets/images/NSS camp/Yrc/yrc-10.jpg";
import Yrc_Img_11 from "../../../assets/images/NSS camp/Yrc/yrc-11.jpg";

import  Rrc_Img_1 from "../../../assets/images/NSS camp/Rrc/rrc-1.jpg";
import  Rrc_Img_2 from "../../../assets/images/NSS camp/Rrc/rrc-2.jpg";
import  Rrc_Img_3 from "../../../assets/images/NSS camp/Rrc/rrc-3.jpg";
import  Rrc_Img_4 from "../../../assets/images/NSS camp/Rrc/rrc-4.jpg";
import  Rrc_Img_5 from "../../../assets/images/NSS camp/Rrc/rrc-5.jpg";
import  Rrc_Img_6 from "../../../assets/images/NSS camp/Rrc/rrc-6.jpg";
import  Rrc_Img_7 from "../../../assets/images/NSS camp/Rrc/rrc-7.jpg";
import  Rrc_Img_8 from "../../../assets/images/NSS camp/Rrc/rrc-8.jpg";
import  Rrc_Img_9 from "../../../assets/images/NSS camp/Rrc/rrc-9.jpg";


const NssCamp = () => {

    const NssCamp1 = [
            {
                image:NssCamp1_Img_1,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_3,
                 id:"nsscamp1"
              },
        
              {
                image:NssCamp1_Img_4,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_5,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_6,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_7,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_8,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_9,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_10,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_11,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_12,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_13,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_14,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_15,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_16,
                 id:"nsscamp1"
              },
              {
                image:NssCamp1_Img_2,
                 id:"nsscamp1"
              },
        
        
        
    ];
    const NssCamp2 = [
        {
            image:NssCamp2_Img_1,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_3,
             id:"nsscamp2"
          },
    
          {
            image:NssCamp2_Img_4,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_5,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_6,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_7,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_8,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_9,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_10,
             id:"nsscamp2"
          },
          {
            image:NssCamp2_Img_11,
             id:"nsscamp2"
          },
        
          {
            image:NssCamp2_Img_2,
             id:"nsscamp2"
          },
    
    
    
];
   const  NssCamp3 = [
    {
        image:NssCamp3_Img_1,
         id:"nsscamp3"
      },
      {
        image:NssCamp3_Img_3,
         id:"nsscamp3"
      },
      {
        image:NssCamp3_Img_2,
         id:"nsscamp3"
      },

      {
        image:NssCamp3_Img_4,
         id:"nsscamp3"
      },
   ];
   const Yrc = [
    {
        image:Yrc_Img_1,
         id:"yrc"
      },
      {
        image:Yrc_Img_3,
         id:"yrc"
      },

      {
        image:Yrc_Img_4,
         id:"yrc"
      },
      {
        image:Yrc_Img_5,
         id:"yrc"
      },
      {
        image:Yrc_Img_6,
         id:"yrc"
      },
      {
        image:Yrc_Img_7,
         id:"yrc"
      },
      {
        image:Yrc_Img_8,
         id:"yrc"
      },
      {
        image:Yrc_Img_9,
         id:"yrc"
      },
      {
        image:Yrc_Img_10,
         id:"yrc"
      },
      {
        image:Yrc_Img_11,
         id:"yrc"
      },
    
      {
        image:Yrc_Img_2,
         id:"yrc"
      },



];
   const Rrc = [
    {
        image:Rrc_Img_1,
         id:"rrc"
      },
      {
        image:Rrc_Img_3,
         id:"rrc"
      },

      {
        image:Rrc_Img_4,
         id:"rrc"
      },
      {
        image:Rrc_Img_5,
         id:"rrc"
      },
      {
        image:Rrc_Img_6,
         id:"rrc"
      },
      {
        image:Rrc_Img_7,
         id:"rrc"
      },
      {
        image:Rrc_Img_8,
         id:"rrc"
      },
      {
        image:Rrc_Img_9,
         id:"rrc"
      },
      
      {
        image:Rrc_Img_2,
         id:"rrc"
      },



];
      return (
   <>
     {/* <Helmet>
        <title> JNCW Gallery-2024 </title>
      </Helmet> */}

    <div className="container">
    
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Gallery</h5>
                <h4 class="mb-5"> National Service Scheme (NSS), Youth Red  Cross (YRC) & Red  Ribbon Club  (RRC)   </h4>
            </div>
      <EventContainer
            data={NssCamp3}
            header="NSS  Camp Activity   June 2023"
            id="nsscamp3"
            />
      <EventContainer
            data={NssCamp2}
            header="NSS  Camp Activity  2020"
            id="nsscamp2"
            />
      <EventContainer
            data={NssCamp1}
            header="NSS Activity  2019"
            id="nsscamp1"
            />

<div className="title-text text-center mt-5">
     
        <h4> Youth Red  Cross (YRC) </h4>
      </div>
       
      <EventContainer
            data={Yrc}
            header="Youth Red Cross 2019"
            id="yrc"
            />
<div className="title-text text-center mt-5">
     
        <h4>Red  Ribbon Club  (RRC)</h4>
      </div>
       
      <EventContainer
            data={Rrc}
            header="Red Ribbon Club"
            id="rrc"
            />
             </div>

            
   </>


  );
};

export default NssCamp;