import React from "react";
import { useLocation } from "react-router-dom";
// import ErrorPage from "../errorPage";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";


const Main = (props) => {
  const location = useLocation();
  const pathUrl = location.pathname;
  return (
    
        <>
          <NavBar />
          {props.children}
          <Footer />
        </>
  );
};
export default Main;
