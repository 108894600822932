import React from 'react';
import "./news.css";
import News1 from  "../../../assets/images/News/img_1 (8).png";
import News2 from  "../../../assets/images/News/img_1 (3).png";
import News3 from  "../../../assets/images/News/img_1 (7).png";
import Admission from "../../../assets/images/banner/course baner.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Teachersday from "../../../assets/images/gallery/2024/teachers day/Img (5).jpeg";
import OnyxOpera24 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (1).jpeg";
import OnamCelebrations from "../../../assets/images/gallery/2024/sept/onam festival/Img (1).jpeg";

function NewsUpdated() {  return (
    <>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title bg-white text-center text-primary px-3"> News & Announcement</h5>
        <h1 className="mb-5"> Updates </h1>
      </div>
      <div class="container">
          <div class="row">
              {/* <!-- Blog entries--> */}
              <div class="col-lg-8">
                  {/* <!-- Featured blog post--> */}            
                  <div class="card mb-4">
                      <a href="#!"><img class="card-img-top" src={Admission} alt="..." /></a>
                      <div class="card-body">
                          <div class="small text-muted">Apirl 1 2024</div>
                          <h2 class="card-title">Admissions Open 2024-2025</h2>
                          <p class="card-text">We are excited to announce that admissions for the upcoming academic session are now open at Jawaharlal Nehru College For Women,(Arts & Science) </p>
                          <a class="btn btn-primary" href="#!">Read more →</a>
                      </div>
                  </div>
                  {/* <!-- Nested row for non-featured blog posts--> */}
                  <div class="row">
                      <div class="col-lg-6">
                          {/* <!-- Blog post--> */}

                          <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={OnamCelebrations} alt="Sports Winners Prize Distribution" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">September 16 2024</div>
                                    <h2 className="card-title h4">Onam Celebrations </h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={Teachersday} alt="Sports Event Inaugural" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">September 5 2024</div>
                                    <h2 className="card-title h4">Teacher's Day Celebrations </h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>


                                            
                      </div>
                      <div class="col-lg-6">
                          {/* <!-- Blog post--> */}

                        
                            <div className="card mb-4">
                                <a href="#!">
                                    <img className="card-img-top img-fluid custom-img" src={OnyxOpera24} alt="Sports Winners Prize Distribution" />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">September 11 2024</div>
                                    <h2 className="card-title h4"> Onyx Opera - 24</h2>
                                    <p className="card-text"></p>
                                    <a className="btn btn-primary" href="https://jncw.in/gallery/2024">Read more →</a>
                                </div>
                            </div>

                       </div>
                  </div>
                  {/* <!-- Pagination--> */}
                
              </div>
              {/* <!-- Side widgets--> */}
              <div class="col-lg-4">
              <div class="card mb-4">
                      <div class="card-header" style={{background:"#800000"}}>New Courses </div>
                      <div class="card-body">
                        <ul>
                            <li>B.Sc AI</li>
                            <li>B.Sc Psycholozy</li>
                            <li>B.Sc Nutritions & Diet</li>
                        </ul>
                      </div>
                  </div>
                    
                    </div>  
                  {/* <div class="card mb-4">
                      <div class="card-header">Principal Message</div>
                      <div class="card-body">“Your talent determines what you can do. Your motivation determines how much you’re willing to do. Your attitude determines how well you do it.”
 —Lou Holtz
</div>
                  </div> */}
                  {/* <div class="card mb-4">
                      <div class="card-header">Recent Posts </div>
                    
                      <div className="card-body">
          <div className="row">
              <div className="col-sm-6">
                  <ul className="list-unstyled mb-0">
                      <li style={{ color: "blue" }}><a href="https://www.facebook.com/jncwomen/"><FontAwesomeIcon icon={faFacebookF} />   Facebook</a></li>
                        <li><a href="https://www.youtube.com/@jawaharlalnehrucollegeforwomen"  style={{color:"red"}}><FontAwesomeIcon icon={faYoutube} /> YouTube</a></li>
                  </ul>
              </div>
              <div className="col-sm-6">
                  <ul className="list-unstyled mb-0">
                      <li><a href="https://www.instagram.com/jncw.in_/"  style={{color:"#F56040"}}><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
                  </ul>
              </div>
          </div>
      </div>

                  </div> */}
                 
              </div>
          </div>
   
      <br/>
     
      
    </>
  );
}

export default NewsUpdated;
