import React from 'react'
import Mess_img1 from "../../../assets/images/Infrastrutre/mess/mess-1.jpg";
import Mess_img2 from "../../../assets/images/Infrastrutre/mess/mess-3.jpg";
import Mess_img3 from "../../../assets/images/Infrastrutre/mess/mess-2.jpg";
import Mess_img4 from "../../../assets/images/Infrastrutre/mess/mess-4.jpg";
import { Helmet } from "react-helmet-async";







function mess() {
  return (
  <>
   <Helmet>
        <title>Amenities- JNCW</title>
      </Helmet>
 <div
        className="container mt-4 text-justify"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 class="section-title bg-white text-center text-primary px-3">Amenities</h5>
    
            </div>
             <div className="container">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
   
            <div class="card mt-2">
              <div
                class="collapsed"
                data-bs-toggle="collapse"
                data-bs-parent="#accordion"
                href="#collapse1st"
                aria-expanded="false"
                aria-controls="collapse1st"
              >
                <div
                  class="card-header header-green "
                  role="tab"
                  id="headingOne"
                >
                  <h4 className='title'>
           Mess
                  </h4>
                </div>
              </div>
              <div
                id="collapse1st"
                class="card-collapse collapse in show"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <p>
                      Hostel mess is maintained by skilled caterers who are committed to provide hygienic and tasty meals in conformance to the standards laid by food inspection authorities. A variety of dishes are prepared according to the time table created in adherence to the taste and likings of students. Fresh vegetables are purchased and processed in such a way that students get ample of nutritious ingredients from their diet. Special diets are also prepared occasionally as an add-on to the flavours of mess.
                      </p>

                      <p>
                      Thus, every attempt is made to facilitate student highly calm and comfortable living environment so that they can stay more focused on learning and skill development.
                      </p>
                    </div>
                  
                 <div className="row row-cols-1 row-cols-md-2 g-4">
                 <div className="col">
                  <img
                        src={Mess_img1}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                           <div className="col"> 
                           <img
                        src={Mess_img2}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>  
                          <div className="col">  
                          <img
                        src={Mess_img3}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                                 <div className="col"> 
                                  <img
                        src={Mess_img4}
                        alt="agronomy-lab"
                        class="m-2 rounded w-100"
                        data-aos="fade-in"
                        data-aos-duration="2000"
                      /></div>
                      </div>  
                  </div>
                </div>
              </div>
            </div>
              </div>
        </div>
      </div>




  </>
  )
}

export default mess