// import React from 'react';
import PropTypes from 'prop-types';
import './student.scss';

const StudentCardList = ({ studentData }) => {
  return (
    <div className="container">
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {studentData.map((student, index) => (
        <div key={index} className="col">
          <div className="card-group st-card">
            <img src={student.imageUrl} className="card-img-top" alt={student.name} />
            <div className="card-body">
              <h5 className="card-title">{student.name}</h5>
              <p className="card-subtitle mb-2 text-muted">{student.degree}</p>
              {student.job && (
                <div className="card-text">
                  <h6>{student.job}</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  
  
  );
};

StudentCardList.propTypes = {
  studentData: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      degree: PropTypes.string.isRequired,
      job: PropTypes.string,
    })
  ).isRequired,
};

export default StudentCardList;
